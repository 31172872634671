import { useEffect, useState, useRef, useContext, useCallback } from 'react'
import { Modal } from '../ui_new'
import byteConverter from '../../helpers/byteConverter'
import { NotificationContext, LoaderContext } from '../../context'
import { getFileData } from '../../utils'
import { Checkbox } from '../ui_new'
import { Close } from '../../assets/icons'

const AttachmentsVariableModal = ({ onClose, variable, attachments = [], defaultAttachments = [], onSave, onAddAttachment, value = [] }) => {
  const { setNotification } = useContext(NotificationContext)
  const [allAttachments, setAllAttachments] = useState([
    ...(attachments || []),
    ...(defaultAttachments || [])
  ])
  const [selectedAttachments, setSelectedAttachments] = useState(value)

  useEffect(() => {
    setAllAttachments([
      ...(attachments || []),
      ...(defaultAttachments || [])
    ])
  }, [attachments, defaultAttachments])

  const fileRef = useRef()

  const handleSave = () => {
    onSave(selectedAttachments)
  }

  // On open file window 
  const handleOpenFileWindow = () => {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  // On file change
  const handleFileChange = async (e) => {
    const files = e.target.files
    addAttachmentsHelper(files)
  }

  // Copied from SingleDocumentAttachmensUpload.js
  // Upload files helper
  const allowedTypes = ['application/pdf']
  const addAttachmentsHelper = async (files) => {
    if (!files) return


    const promises = []
    let errors = []
    const attNames = attachments.map(a => a.name)

    const filesArr = Array.from(files)
    for (let i = 0; i < filesArr.length; i++) {
      const file = filesArr[i]
      let components = file.name.split('.')
      components.splice(components.length - 1, 1)
      const name = components.join('.')

      if (attNames.includes(name)) {
        errors.push(`La pièce jointe avec le nom "${name}" existe déjà`)
        continue
      }

      if (!allowedTypes.includes(file.type)) {
        errors.push(`Type de fichier non valide pour la pièce jointe "${name}"`)
        continue
      }

      if (file.size > 50 * 1024 * 1024) {
        errors.push(`Le poids du fichier pour "${name}" est supérieur au poids autorisé (50MB)`)
        continue
      }

      promises.push(getFileData(file))
    }

    if (errors.length > 0) {
      setNotification({ msg: errors.join('.'), type: 'danger' })
    }

    if (promises.length) {
      const data = await Promise.all(promises)
      onAddAttachment(data[0])
    }
  }

  const attachmentChangeHandler = useCallback((i) => {
    const attachment = allAttachments[i]
    const isSelected = Boolean(selectedAttachments.find(a => a.name === attachment.name))
    if (isSelected) {
      setSelectedAttachments(atts => atts.filter(a => a.name !== attachment.name))
    } else {
      setSelectedAttachments(atts => ([...atts, attachment]))
    }
  }, [selectedAttachments, allAttachments])

  return (
    <Modal onClose={onClose}>
      <div>
        <div className="single-document-attachment-variable-modal__head">
          <h2>
            {variable.display_name || variable.variable}
            <button className="btn btn--transparent" onClick={onClose}>
              <Close />
            </button>
          </h2>
        </div>
        <div className="single-document-attachment-variable-modal__body">
          <h4>Sélectionnez un ou plusieurs fichier parmis vos annexes existantes : </h4><br />

          <ul className='files-list'>
            {allAttachments?.map((attachment, index) =>
              <li key={index}>
                <div className="flex row-with-checkbox">
                  <Checkbox
                    checked={Boolean(selectedAttachments.find(a => a.name === attachment.name))}
                    onChange={(e) => attachmentChangeHandler(index)} className='mr-10' label={`${attachment.name} (${byteConverter(attachment.size)})`} />
                </div>
              </li>
            )}
          </ul>
          <br />
          <div className='flex'>
            <button className="btn " onClick={handleSave}>
              Choisir les fichiers sélectionnés
            </button>
            <button className="btn ml-20" onClick={handleOpenFileWindow}>
              Télécharger un nouveau fichier pdf
            </button>
            <input type="file" ref={fileRef} onChange={handleFileChange} hidden accept={allowedTypes.join(",")} multiple={false} />
          </div>
        </div>

      </div>
    </Modal>
  )
}

export default AttachmentsVariableModal