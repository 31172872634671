import React, { useState } from 'react'
import { Modal, Switch, Select, Option } from '../ui_new'

import 'react-phone-input-2/lib/style.css'
// import Switch from '../../UI/Switch';
import { isCoverPageVariable } from '../../utils'

const SingleDocumentRenderSettings = ({ onClose, onSave, onPreviewRefresh, useCustomCover, templateData, previewSettings = { size: 'normal', watermark: false }, linesAdded }) => {
    
  const [settings, setSettings] = useState({
    size: previewSettings.size || "normal",
    watermark: previewSettings.watermark || false,
    useCustomCover: useCustomCover || false,
    linesAdded: linesAdded || false
  })

  const changeSize = (e) => {
    setSettings({ ...settings, size: e })
  }

  const changeWatermark = (e) => {
    setSettings({ ...settings, watermark: e.target.checked })
  }
  
  const changeCustomCover = (e) => {
    setSettings({ ...settings, useCustomCover: e.target.checked })
  }

  const changeLinesAdded = (e) => {
    setSettings({ ...settings, linesAdded: e.target.checked })
  }

  const handleSave = () => {
    onSave(settings)
    onClose()
    // if(onPreviewRefresh) {
    //   onPreviewRefresh(settings)
    // }
  }

  // Check if should show custom cover checkbox
  const shouldShowCustomCoverToggle = () => {
    if (!templateData) return false
    let hasCoverPage = templateData.hasCoverPage
    if (!hasCoverPage) {
      for (let section of templateData.sections) {
        if (section.variable) {
          if (isCoverPageVariable(section.variable)) {
            hasCoverPage = true
            break
          }
        } else if (section.variables) {
          for (let v of section.variables) {
            if (isCoverPageVariable(v.variable)) {
              hasCoverPage = true
              break
            }
          }
        }
        if (hasCoverPage) {
          break
        }
      }
    }
    return hasCoverPage
  }

  return (
    <Modal onClose={onClose} className="modal-v2--small layout-modal">
      <div className="folder-modal-v2">
        <div className="folder-modal-v2__heading">
          <div>
            <h2>Mise en page</h2>
          </div>
          <div className='buttons-row'>
            <button className='btn btn--medium btn--transparent' onClick={onClose}>Annuler</button>
            <button className='btn btn--large' onClick={handleSave}>Enregistrer</button>
          </div>
        </div>
        <div className="folder-modal-v2__content">
          <div className='layout-text'>
            Personnalisez votre document avec les options ci-dessous :
          </div>
          <div className='mb-6'>
            <label className='layout-label mb-2 flex'>Taille de caractère</label>
            <Select selected={settings.size} onChange={changeSize} className={'!h-10'}>
              <Option value="small">Petit</Option>
              <Option value="normal" >Normal</Option>
              <Option value="large">Grand</Option>
            </Select>
          </div>
          <div>
            {shouldShowCustomCoverToggle() && <label className='label flex layout-label !mb-6'>
              <Switch checked={settings.useCustomCover} onChange={changeCustomCover} />
              Utiliser la page de couverture personnalisée</label>}
            <label className='label flex layout-label !mb-6'>
              <Switch checked={settings.watermark} onChange={changeWatermark} />
              Appliquer le filigrane “projet” </label>
            <label className='label flex layout-label !mb-6'>
              <Switch checked={settings.linesAdded} onChange={changeLinesAdded} />
              Ajouter des lignes dans les champs vides</label>
          </div>
          <div className='buttons-row hide-on-lg mb-2'>
            <button className='btn btn--large w-full' onClick={handleSave}>Enregistrer</button>
          </div>
        </div>

      </div>
    </Modal>
  )
}

export default SingleDocumentRenderSettings