import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { dateDisplayFormat } from '../../utils'
import { Alert, Checkbox, CustomTooltip } from '../ui_new'
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper'
import ActionsDropdown from '../UI/ActionsDropdown'
import { Delete, MoreVert } from '@mui/icons-material';
import { Signature, Download, MoveToFolder, Pen, Duplicate, RegisteredMail } from '../../assets/icons';
import { Archive } from '@material-ui/icons'
import { useDocumentsActions, useSingleDocumentActions } from '../../hooks'
import { DocumentsContext, UserContext, DocumentsFoldersContext, LoaderContext, RegisteredMailContext } from '../../context'
import { MoveSelectedItemsToFolderModal } from '../misc'
import SingleDocumentSignVialink from '../../components/misc/SingleDocumentSignVialink'


const DashboardDocumentBox = ({ id, doc, templateExists, folder = null, onSetSelectedDocuments, selectedDocuments, type }) => {
  const [checked, setChecked] = useState(false)
  const [createdAt, setCreatedAt] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const history = useHistory()
  const { downloadDocuments, moveDocumentsToFolder, archiveDocuments } = useDocumentsActions(selectedDocuments, onSetSelectedDocuments);
  const { moveDocumentToDeleteDocuments, getCustomersFromData } = useSingleDocumentActions()
  const { setSendModalDefaultAttachments, setShowRegisteredMailSendModal, createAr24MailRequestFromDocument } = useContext(RegisteredMailContext)
  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false)
  const { docFolders } = useContext(DocumentsFoldersContext)
  const { docsSelectedFilters, currentFilter, templates, getSingleTemplate, getTemplateById } = useContext(DocumentsContext);
  const { setLoading, setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { progress, getPreviewData, duplicateDocument } = useSingleDocumentActions()
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [signModal, setSignModal] = useState({
    isOpen: false,
    document: null,
    template: null,
    contacts: []
  })

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if (checked && selectedDocuments.length === 0) {
      setChecked(false)
    } else if (selectedDocuments.length > 0 && selectedDocuments.find(d => d.id === id)) {
      setChecked(true)
    } else if (selectedDocuments.length > 0 && checked && !selectedDocuments.find(d => d.id === id)) {
      setChecked(false)
    }
  }, [selectedDocuments, checked, id])

  // Set createdAt
  useEffect(() => {
    if (doc?.meta?.created) {
      setCreatedAt(moment(doc.meta.created).format(dateDisplayFormat))
    } else if (doc.createdAt) {
      setCreatedAt(moment(doc.createdAt).format(dateDisplayFormat))
    }
  }, [doc])

  // On document select
  const handleSelectDocument = () => {
    setChecked(!checked)
    if (checked) {
      onSetSelectedDocuments(prev => prev.filter(d => d.id !== id))
    } else {
      onSetSelectedDocuments(prev => [...prev, doc])
    }
  }

  // On go to div click
  const handleGoTo = () => {
    if (type === 'documents') {
      history.push(`/documents/${id}`)
    } else if (type === 'signatures') {
      history.push(`/signatures?detail=${id}`)
    } else if (type === 'registered_mail') {
      history.push('/registered-mail/tracking')
    }
  }

  // On documents download
  const handleDocumentsDownload = async () => {
    await downloadDocuments([doc])
  }

  const handleEditDocument = () => {
    // TODO verify, I can't think of anything else than just opening the document detail
    setShowDropdown(false)
    history.push(`/documents/${id}`)
  }

  const handleRequestSignature = async () => {
    setShowGlobalResponseLoader(true)
    let template = getTemplateById(doc.template)
    if (!template) {
      template = await getSingleTemplate(doc.template, true)
    }
    const contacts = getCustomersFromData(document.values || {}, () => { }) || []
    setSignModal({
      isOpen: true,
      document: doc,
      template: getTemplateById(doc.template),
      contacts: contacts
    })
    setShowGlobalResponseLoader(false)
  }

  const handleOpenRegisteredMailModal = () => {
    const contacts = getCustomersFromData(doc.values, () => { })
    createAr24MailRequestFromDocument({ document: doc, documentValues: doc.values, documentCheckboxValues: doc.checkboxValues, documentName: doc.name, documentContacts: contacts })
  }

  // On open move to folder
  const handleOpenMoveToFolderModal = (e) => {
    e.preventDefault()
    setShowMoveToFolderModal(true)
  }
  // On close move to folder
  const handleCloseMoveToFolderModal = () => {
    setShowMoveToFolderModal(false)
  }

  // On documents move
  const handleDocumentsMove = (moveTo) => {
    moveDocumentsToFolder(moveTo)
    // onSetAllChecked(false)
  }

  // On duplicate
  const handleDuplicate = async () => {

    setShowGlobalResponseLoader(true)
    try {
      let templateObject = templates[doc.template]
      if (!templateObject) {
        templateObject = await getSingleTemplate(doc.template, true)
      }
      const id = await duplicateDocument({
        doc: doc,
        linesAddedTo: [],
        template: templateObject,
      })
      history.push(`/documents/${id}`)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On archive
  const handleArchive = async () => {
    await archiveDocuments([doc])
    setShowDropdown(false)
  }

  // On open delete alert
  const handleOpenDeleteAlert = () => {
    setShowDeleteAlert(true)
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete document
  const handleDeleteDocument = async () => {
    setShowGlobalResponseLoader(true)
    try {
      await moveDocumentToDeleteDocuments({
        ...doc,
        id: id,
      })
      setShowDeleteAlert(false)

    } catch (err) {
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  return (
    <div className={`dashboard-document-box ${type} ${checked ? 'active' : ''}`}>
      {type === 'documents' && <div className={`dashboard-document-box__check ${checked ? 'checked' : ''}`}>
        <Checkbox label="" checked={checked} onChange={handleSelectDocument} />
        <AdminFeatureWrapper rule="any_admin">
          <ActionsDropdown
            trigger={<button className="icon-btn icon-btn--ghost icon-btn--svg-xl more-vert-icon" onClick={() => setShowDropdown(!showDropdown)}><span className="svg-icon"><MoreVert fontSize='medium' /></span></button>}
            hideHeader
            width={120}
            dropdownClass="actions-dropdown--no-padding"
          >
            <ul className="dashboard-column__dropdown">
              <li onClick={handleDocumentsDownload} className="edit"><span className="icon"><Download /></span>Télécharger</li>
              <li onClick={handleEditDocument} className="edit"><span className="icon"><Pen /></span>Editer le document</li>
              <li onClick={handleRequestSignature} className="edit"><span className="icon"><Signature /></span>Signatures</li>
              <li onClick={handleOpenRegisteredMailModal} className="edit"><span className="icon"><RegisteredMail /></span>Envoyer par LRE</li>
              <li onClick={handleOpenMoveToFolderModal} className="edit"><span className="icon"><MoveToFolder /></span>Déplacer</li>
              <li onClick={handleDuplicate} className='duplicate'><span className="icon"><Duplicate /></span>Dupliquer</li>
              <li onClick={handleArchive} className="archive"><span className="icon"><Archive /></span>Archiver</li>
              <li onClick={handleOpenDeleteAlert} className='trash'><span className="icon"><Delete /></span>Supprimer</li>
            </ul>
          </ActionsDropdown>
        </AdminFeatureWrapper>
      </div>}
      <CustomTooltip content={type === 'registered_mail' ? doc.document_name : type === 'documents' ? doc.name : doc.title}
        position={type === 'registered_mail' ? 'left-top' : 'top'}>
        <div className="dashboard-document-box__go-to" onClick={handleGoTo}></div>
      </CustomTooltip>

      <div className="dashboard-document-box__content">
        {(doc.name || doc.title) && <h3>{type === 'documents' ? doc.name : doc.title}</h3>}
        {type === 'registered_mail' &&
          <div>
            <h3>{`${doc.to_firstname} ${doc.to_lastname.toUpperCase()}`}</h3>
            {doc.document_name && <p className="mail-box-label">{doc.document_name}</p>}

          </div>}
        {folder && <div className="folder">{folder.name}</div>}
        <div className="dashboard-document-box__meta">
          <div className="date">{createdAt}</div>
        </div>
      </div>

      {showMoveToFolderModal && (
        <MoveSelectedItemsToFolderModal
          onClose={handleCloseMoveToFolderModal}
          folders={docFolders}
          items={selectedDocuments}
          currentFolder={docsSelectedFilters.folder === 'all' ? null : docFolders.find(f => f.id === docsSelectedFilters.folder)}
          newFolderCreated={false} // remove this, improve
          onNewFolderCreated={() => { }} // remove this, improve
          onMove={handleDocumentsMove}
        />
      )}

      {showDeleteAlert && <Alert
        onClose={handleCloseDeleteAlert}
        text="Êtes-vous sûr de vouloir supprimer ce document ?"
        onSubmit={handleDeleteDocument}
        deleteAlert={true}
      />}

      {(signModal.isOpen && signModal.document && signModal.template) && (
        <SingleDocumentSignVialink
          onClose={() => setSignModal({ isOpen: false, document: null, template: null, contacts: [] })}
          documentName={signModal.document.name}
          docId={signModal.document.id}
          docAttachments={signModal.document.attachments || []}
          docSignatureRecipients={[]}
          singleDoc={signModal.document}
          docContacts={signModal.contacts || []}
          template={signModal.template}
        />
      )}

    </div>
  )
}

export default DashboardDocumentBox