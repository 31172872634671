import { array } from 'prop-types'
import { useContext, useEffect, useState, useCallback } from 'react'
import { set } from 'react-ga'
import { AngleRight, Eye } from '../../assets/icons'
import { UserContext } from '../../context'
import useScrollDirection from '../utils/useScrollDirection'
import { SingleDocumentPreview, SingleDocumentAttachmentsUpload } from './'
import { DoubleArrow } from '@material-ui/icons'
import { KeyboardDoubleArrowLeft, Visibility } from '@mui/icons-material'

const arrayStack = [-Infinity]
const SingleDocumentSidePanel = ({
    onToggle,
    previewData,
    previewLoading,
    onPreviewRefresh,
    tabActive,
    view,
    onSetDocumentAttachments,
    attachments,
    docId,
    previewSettings = { size: 'normal', watermark: false },
    onPreviewSettingsChange,
    hidePreviewInMobile
}) => {
    const { setIsScroll } = useContext(UserContext)

    const handleScroll = useCallback(() => {
        const element = document.getElementById('direction')
        const { scrollTop } = element

        if (arrayStack[arrayStack.length - 1] < scrollTop) {
            arrayStack.push(scrollTop)
            setIsScroll(true)
        } else {
            arrayStack.length = 0
            arrayStack.push(scrollTop)
            setIsScroll(false)
        }
    }, [])


    return (
        <>
            <div className={`single-document-side-narrow-panel`}>
                {view === 'variables' && <>
                    <button className="eye-button" onClick={onToggle}>
                        <Visibility fontSize='medium' />
                    </button>
                    <button className="button button--outline-primary-light rounded-sm fold-btn" onClick={onToggle}>
                        <KeyboardDoubleArrowLeft fontSize={'small'} />
                    </button>
                </>}
            </div>
            <div
                className={`single-document__right single-document-side-panel`}
            >
                {/* <div
                className="single-document-side-panel__toggle"
                onClick={onToggle}
            >
                <AngleRight />
            </div> */}
                <div
                    id="direction"
                    className="single-document-side-panel__body"
                    onScroll={(e) => {
                        handleScroll()
                    }}
                >
                    {view === 'variables' && (
                        <>
                            <SingleDocumentPreview
                                data={previewData}
                                loading={previewLoading}
                                onPreviewRefresh={onPreviewRefresh}
                                previewSettings={previewSettings}
                                onPreviewSettingsChange={onPreviewSettingsChange}
                                onToggle={onToggle}
                                hidePreviewInMobile={hidePreviewInMobile}
                                tabActive={tabActive}
                            />
                        </>
                    )}
                    {view === 'attachments' && (
                        <SingleDocumentAttachmentsUpload
                            onSetDocumentAttachments={onSetDocumentAttachments}
                            attachments={attachments}
                            docId={docId}
                            onToggle={onToggle}
                        />
                    )}
                </div>
            </div>
        </>

    )
}

export default SingleDocumentSidePanel
