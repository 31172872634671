import { useEffect, useState, useRef, useContext } from 'react'
import { Modal, CustomTooltip, Alert, Input } from '../ui_new'
import byteConverter from '../../helpers/byteConverter'
import { Sort, Pen, Delete, ChevronLeft, Sync, Weight, Close, Drag, DragArrows } from '../../assets/icons'
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import Select from 'react-select'
import { EditAttachmentModal } from './'
import { NotificationContext, LoaderContext } from '../../context'
import { getFileData } from '../../utils'
import { useAttachmentActions } from '../../hooks'
import { AttachmentsContext } from '../../context/attachments/attachmentsState';
import { Check } from '@material-ui/icons';
import { Rule } from '@mui/icons-material';

const AttachmentsModal = ({ onClose, docId }) => {
  const { setNotification } = useContext(NotificationContext)
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [draggedOverItem, setDraggedOverItem] = useState();
  const currentlyDraggedAttachment = useRef(0);
  const draggedOverAttachment = useRef(0);
  const [currentAttachment, setCurrentAttachment] = useState();
  const [condition, setCondition] = useState({ active: false, attachment: null });
  const [edit, setEdit] = useState({ showModal: false, attachment: null });
  const [showWeightAlert, setShowWeightAlert] = useState(false);
  const [showCompressedSizeAlert, setShowCompressedSizeAlert] = useState(false);
  const { setShowGlobalResponseLoader } = useContext(LoaderContext);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [orderedAttachments, setOrderedAttachments] = useState([]);

  const { defaultAttachments, defaultAttachmentsOrder, updateDefaultAttachmentsOrder, createMultipleDefaultAttachments, updateDefaultAttachment, deleteDefaultAttachment, compressAttachment } = useContext(AttachmentsContext)

  const fileRef = useRef()

  // useEffect(() => {
  //   // const rootOrder = defaultAttachmentsOrder || []
  //   // let orderedFoldersCopy = rootOrder.map(id => rootFolders.find(folder => folder.id === id));
  //   // filter out undefined folders & add folders that are not in the order
  //   rootFolders.forEach(folder => {
  //     if (!orderedFoldersCopy.includes(folder)) {
  //       orderedFoldersCopy.push(folder);
  //     }
  //   });
  //   orderedFoldersCopy = orderedFoldersCopy.filter(folder => folder);
  //   setOrderedFolders(orderedFoldersCopy);
  // }, [defaultAttachments, foldersOrderMap]);

  useEffect(() => {
    const attArray = defaultAttachmentsOrder.map(id => defaultAttachments[id] ? { ...defaultAttachments[id], id } : null).filter(att => att)

    for (let key in defaultAttachments) {
      if(!attArray.find(att => att.id === key)) {
        attArray.push({
          ...defaultAttachments[key],
          id: key
        })
      }
    }
    setOrderedAttachments([...attArray])
  }, [defaultAttachments, defaultAttachmentsOrder])

  const onCancelCondition = () => {
    setCondition({ active: false, attachment: null })
  }

  const handleSaveConditions = async () => {
    let areConditionsValid = true
    for (let c of conditions) {
      if (!c.variable || !c.value) {
        areConditionsValid = false
        break
      }
    }
    if (!areConditionsValid) {
      return setNotification({ msg: 'Veuillez remplir toutes les conditions', type: 'danger' })
    }
    setShowGlobalResponseLoader(true)
    await updateDefaultAttachment(condition.attachment.id, { conditions })
    setCondition({ active: false, attachment: null })
    setShowGlobalResponseLoader(false)
  }

  const addCondition = () => {
    setConditions(prev => [...prev, { variable: '', value: '' }]);
  }

  const deleteCondition = (index) => {
    let newConditions = conditions.filter((item, i) => index !== i);
    setConditions(newConditions)
  }

  const reorder = () => {
    const orderedAttachmentsClone = [...orderedAttachments];
    const movedFolder = orderedAttachmentsClone.splice(currentlyDraggedAttachment.current, 1)[0];
    orderedAttachmentsClone.splice(draggedOverAttachment.current, 0, movedFolder);

    const newFullOrder = orderedAttachmentsClone.map(folder => folder.id)
    updateDefaultAttachmentsOrder(newFullOrder)
    setDraggedOverItem();
  }

  const handleCloseEditModal = () => {
    setEdit({ showModal: false, attachment: null });
  }

  // On edit 
  const handleEdit = async (name) => {
    const attachmentsNames = orderedAttachments.filter(a => a.id === edit.attachment.id).map(a => a.name)
    if (attachmentsNames.includes(name)) {
      return setNotification({ msg: 'La pièce jointe portant ce nom existe déjà', type: 'danger' })
    }

    setShowGlobalResponseLoader(true)
    await updateDefaultAttachment(edit.attachment.id, { name })
    setShowGlobalResponseLoader(false)
    handleCloseEditModal()
  }

  // On open file window 
  const handleOpenFileWindow = () => {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  // On file change
  const handleFileChange = async (e) => {
    const files = e.target.files
    addAttachmentsHelper(files)
  }

  // Copied from SingleDocumentAttachmensUpload.js
  // Upload files helper
  const addAttachmentsHelper = async (files) => {
    if (!files) return

    const allowedTypes = ['application/pdf']

    const promises = []
    let errors = []
    const attNames = orderedAttachments.map(a => a.name)

    const filesArr = Array.from(files)
    for (let i = 0; i < filesArr.length; i++) {
      const file = filesArr[i]
      let components = file.name.split('.')
      components.splice(components.length - 1, 1)
      const name = components.join('.')

      if (attNames.includes(name)) {
        errors.push(`La pièce jointe avec le nom "${name}" existe déjà`)
        continue
      }

      if (!allowedTypes.includes(file.type)) {
        errors.push(`Type de fichier non valide pour la pièce jointe "${name}"`)
        continue
      }

      if (file.size > 50 * 1024 * 1024) {
        errors.push(`Le poids du fichier pour "${name}" est supérieur au poids autorisé (50MB)`)
        continue
      }

      promises.push(getFileData(file))
    }

    if (errors.length > 0) {
      setNotification({ msg: errors.join('.'), type: 'danger' })
    }

    setShowGlobalResponseLoader(true)

    if (promises.length) {
      const data = await Promise.all(promises)
      // for(let i = 0; i < data.length; i++) {
      //   await createDefaultAttachment(data[i])
      // }
      await createMultipleDefaultAttachments(data)
        
      // onSetDocumentAttachments(prev => [...prev, ...data])
      // setAttachments(prev => [...prev, ...data])
    }
    setShowGlobalResponseLoader(false)
  }

  const changeCondition = (property) => (e) => {
    const newConditions = conditions.map((c, i) => {
      if (i === +e.target.dataset.index) {
        return { ...c, [property]: e.target.value }
      }
      return c;

    })
    setConditions(newConditions)
  }


  // On weight alert open
  const handleOpenWeightAlert = (attachment) => {
    setCurrentAttachment(attachment)
    setShowWeightAlert(true)
  }

  // On weight alert close
  const handleCloseWeightAlert = () => {
    setShowWeightAlert(false)
  }

  // On weight
  const handleWeight = async () => {
    setShowGlobalResponseLoader(true)
    setShowWeightAlert(false)
    try {
      await compressAttachment(currentAttachment)
      // const updatedAttachments = await weightAttachment();
      // // set compressed size
      // setShowCompressedSizeAlert(250000)
      // onSetDocumentAttachments(updatedAttachments)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On weight alert close
  const handleCloseCompressedSizeAlert = () => {
    setShowCompressedSizeAlert()
  }

  // On delete alert open
  const handleOpenDeleteAlert = (attachment) => {
    setCurrentAttachment(attachment)
    setShowDeleteAlert(true);
  }

  // On delete alert close
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete
  const handleDelete = async () => {
    setShowGlobalResponseLoader(true)
    setShowDeleteAlert(false)
    try {
      await deleteDefaultAttachment(currentAttachment.id)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  return (
    <Modal onClose={onClose}>
      <div className='single-document-attachments'>
        {!condition.active && <>
          <div className="single-document-attachments__head attachment-model-heading">
            <h2 className='flex justify-between w-full'>
              Fichiers
              <div className='flex'>
                <button className="btn" onClick={handleOpenFileWindow}>
                  Ajouter un fichier
                </button>
                <input type="file" ref={fileRef} accept='application/pdf' onChange={handleFileChange} multiple hidden />
                <button className="btn btn--transparent " onClick={onClose}><Close /></button>
              </div>
            </h2>            
          </div>
          <ul className='files-list pl-5 pr-5 pb-5'>
            {orderedAttachments?.map((attachment, index) =>
              <li key={index} className={`mb-2 flex justify-between ${index === draggedOverItem ? 'dragged-over-item' : ''} ${currentlyDraggedAttachment.current > draggedOverAttachment.current ? 'top' : ''} ${currentlyDraggedAttachment.current < draggedOverAttachment.current ? 'bottom' : ''}`}>
                <div>
                  <p>{attachment.name} ({byteConverter(attachment.size)})</p>
                </div>
                <div className="flex gap-5">
                  <CustomTooltip content="Renommer">
                    <button className="icon-btn icon-btn--transparent" type="button" onClick={() => setEdit({ showModal: true, attachment: attachment })}><Pen /></button>
                  </CustomTooltip>
                  <CustomTooltip content="Conditions">
                    <button className="icon-btn icon-btn--transparent" type="button" onClick={() => {
                      setCondition({ active: true, attachment: attachment })
                      setConditions(attachment.conditions || [{ variable: '', value: '' }])
                    }}><Rule /></button>
                  </CustomTooltip>
                  <CustomTooltip content="Déplacer">
                    <button className="icon-btn icon-btn--transparent" type="button"
                      draggable
                      onDragStart={() => { currentlyDraggedAttachment.current = index; }}
                      onDragEnter={() => { draggedOverAttachment.current = index; }}
                      onDragEnd={reorder}
                      onDragOver={(e) => { e.preventDefault(); setDraggedOverItem(index) }}
                    ><DragArrows /></button>
                  </CustomTooltip>
                  <CustomTooltip content="Supprimer">
                    <button className="icon-btn icon-btn--transparent btn--danger" type="button" onClick={() => handleOpenDeleteAlert(attachment)}><Delete /></button>
                  </CustomTooltip>
                  {/* <CustomTooltip content="Compresse">
                    <button className="icon-btn icon-btn--transparent" type="button" onClick={() => handleOpenWeightAlert(attachment)}><Weight /></button>
                  </CustomTooltip> */}
                </div>
              </li>
            )}
          </ul>
        </>}
        {condition.active && <>
          <div className="template-preview-modal__preview p-5 single-document-attachments">
            <div className="single-document-attachments__head flex">
              <button className="btn btn--transparent" onClick={() => setCondition({ showModal: false, attachment: null })}>
                <span className={`return-icon`}><ChevronLeft /></span>
                Retour
              </button>
              <div className="footer-buttons">
                <Button text="Annuler" onButtonClick={onCancelCondition} outlinePrimary className="btn btn--medium btn--transparent" />
                <Button
                  text={!loading ? "Enregistrer" : <Loader mini normalWhite />}
                  onButtonClick={handleSaveConditions}
                  className={'button height-40'}
                />
              </div>
            </div>

            <div className='folder-modal-v2__content'>

              <p className='file-name'>{condition?.attachment?.name} ({byteConverter(condition?.attachment?.size)})</p>

              {conditions?.map((condition, index) =>
                <div className='conditions-row' key={index}>
                  {/* <Select options={options} className='select' /> */}
                  <input type='text' placeholder="Nom de variable" className='input input-v2__field mb-6' value={condition.variable} data-index={index} onChange={changeCondition('variable')} />
                  <span>valeur égale à</span>
                  <input type='text' className='input input-v2__field mb-6' value={condition.value} data-index={index} onChange={changeCondition('value')} />
                  {conditions.length > 1 && <CustomTooltip content="Supprimer">
                    <button className="icon-btn icon-btn--transparent" type="button" onClick={() => deleteCondition(index)}><Delete /></button>
                  </CustomTooltip>}
                </div>
              )}

              <button className="btn ml-auto" onClick={addCondition}>
                + Ajouter une condition
              </button>
              <br />

            </div>
          </div>

        </>}

        {edit.showModal && (
          <EditAttachmentModal
            onClose={handleCloseEditModal}
            attachment={edit.attachment}
            onEdit={handleEdit}
          />
        )}

        {showDeleteAlert && (
          <Alert
            onClose={handleCloseDeleteAlert}
            text={`${currentAttachment.name} (${byteConverter(currentAttachment?.size)})`}
            bodyText={`Etes-vous certains de vouloir supprimer ce fichier ?`}
            deleteAlert={true}
            onSubmit={handleDelete}
          />
        )}


        {showWeightAlert && (
          <Alert
            onClose={handleCloseWeightAlert}
            text={`${currentAttachment?.name} (${byteConverter(currentAttachment?.size)})`}
            bodyText={`Reduire le poids du fichier pdf`}
            deleteAlert={true}
            onSubmit={handleWeight}
          />
        )}

        {showCompressedSizeAlert && (
          <Alert
            onClose={handleCloseCompressedSizeAlert}
            text={`${currentAttachment.name} (${byteConverter(currentAttachment?.size)})`}
            bodyText={`A présent ${byteConverter(showCompressedSizeAlert)}`}
            deleteAlert={true}
            noButton
          />
        )}
      </div>
    </Modal>
  )
}

export default AttachmentsModal