import { useContext, useState, useRef, useEffect } from 'react'
import { ClickAwayListener } from '@material-ui/core'

import { CaretRight, EllipsisH, FolderPlus, Pen, MoveToFolder } from '../../assets/icons'
import { areFolderActionsAuthorized, folderHasSubfolders } from '../../utils'
import { DocumentsFoldersContext, DocumentsContext, FolderContext, UserContext } from '../../context'
import { useFoldersActions, useSingleDocumentActions, useTemplatesActions } from '../../hooks'
import { Alert, CustomTooltip } from '../ui_new'
import { CreateEditFolderModal, FoldersPopup } from '.'
import { Delete, Folder, Source, CreateNewFolder, MoreVert } from '@mui/icons-material';

const FolderListItem = ({ folder, onFilter, activeFolder, view = 'documents', reorderDragStart, reorderDragEnter, reorderDragEnd, reorderDragOver, reorderDraggable = false, level }) => {
  const { moveFolder, deleteFolder, checkIfFolderCanBeDeleted } = useFoldersActions()
  const { moveDocToFolder } = useSingleDocumentActions()
  const { moveTemplateToFolder } = useTemplatesActions()
  const { docFolders, docFoldersLoading } = useContext(DocumentsFoldersContext)
  const { documents, templates, standardTemplates } = useContext(DocumentsContext)
  const { folders, foldersLoading, standardTemplatesFolders, standardTemplatesFoldersLoading, setCurrentlyActiveFolder, foldersOrderMap, updateFoldersOrder } = useContext(FolderContext)
  const { userClaims } = useContext(UserContext)
  const hasSubFolders = folderHasSubfolders(view === 'documents' ? docFolders : view === 'templates' ? folders : view === 'standard-templates' ? standardTemplatesFolders : [], folder)

  const [arrowActive, setArrowActive] = useState(false)
  const [makeDraggable, setMakeDraggable] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  const [showEditFolderModal, setShowEditFolderModal] = useState(false)
  const [showFoldersPopup, setShowFoldersPopup] = useState(false)
  const [popupElPosition, setPopupElPosition] = useState(null)
  const popupEl = useRef()

  // On drag start
  const handleDragStart = (e) => {
    const div = document.createElement('div')
    div.id = 'draggable-folder-el'
    div.className = 'draggable-ghost-el-v2'
    div.innerText = `Déplacer le dossier ${folder.name}`
    document.getElementById('root').appendChild(div)
    e.dataTransfer.setData('folder', JSON.stringify(folder))
    e.dataTransfer.setDragImage(div, 0, 0)
  }

  // On drag over
  const handleDragOver = (e) => {
    e.preventDefault()
    // const folderBox = e.target.closest('.folder-list-item__main')
    // if(!folderBox.classList.contains('active')) {
    //   folderBox.classList.add('active')
    // }
    // if(hasSubFolders && !arrowActive) {
    //   setArrowActive(true)
    // }
  }

  // On drag leave
  const handleDragLeave = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.folder-list-item__main')
    if (folderBox.classList.contains('active')) {
      folderBox.classList.remove('active')
    }
  }

  // On drag end
  const handleDragEnd = (e) => {
    if (document.getElementById('draggable-folder-el')) {
      document.getElementById('draggable-folder-el').remove()
    }
  }

  // On drop
  const handleDrop = async (e) => {
    const folderBox = e.target.closest('.folder-list-item__main')
    if (folderBox && folderBox.classList.contains('active')) {
      folderBox.classList.remove('active')
    }

    // document folder data
    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null

    if (data) {
      moveFolder({
        data,
        folder,
        folders:
          view === 'documents' ? docFolders :
            view === 'templates' ? folders :
              view === 'standard-templates' ? standardTemplatesFolders : [],
        view
      })
      return
    }

    // template data
    const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null

    if (templateData) {
      moveTemplateToFolder({ folder, template: templateData, view })
      return
    }

    // document data
    const documentData = e.dataTransfer.getData('document') ? JSON.parse(e.dataTransfer.getData('document')) : null

    if (documentData) {
      moveDocToFolder(documentData, folder)
    }
  }

  // On arrow click
  const handleArrowClick = () => {
    setArrowActive(!arrowActive)
  }

  // 3 dots mouse enter
  const handleMouseEnter = () => {
    setMakeDraggable(true)
  }

  // 3 dots mouse leave
  const handleMouseLeave = () => {
    setMakeDraggable(false)
  }

  // On filter
  const handleFilter = () => {
    onFilter(folder.id);
    // I used this for generating breadcrumb
    setCurrentlyActiveFolder(folder);
    // open the subfolders
    handleArrowClick();
  }

  // On icon right click
  const handleIconRightClick = (e) => {
    setPopupElPosition(e.currentTarget.getBoundingClientRect())
    setShowDropdown(!showDropdown)
  }

  // On click away
  const handleClickAway = () => {
    setShowDropdown(false)
    setShowFoldersPopup(false)
  }

  // On create new folder click
  const handleCreateNewFolderClick = (e) => {
    e.preventDefault()
    setShowCreateFolderModal(true)
  }

  // On close create folder modal
  const handleCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false)
  }

  // On edit click
  const handleEditFolderClick = (e) => {
    e.preventDefault()
    setShowEditFolderModal(true)
  }

  // On close edit folder modal
  const handleCloseEditFolderModal = () => {
    setShowEditFolderModal(false)
  }

  // On move folder click
  const handleMoveFolderClick = (e) => {
    e.preventDefault()
    setShowFoldersPopup(true)
  }

  // On close folders popup
  const handleCloseFoldersPopup = () => {
    setShowFoldersPopup(false)
  }

  // On delete folder click
  const handleDeleteFolderClick = (e) => {
    e.preventDefault()
    if (checkIfFolderCanBeDeleted(folder, view)) {
      setShowDeleteAlert(true)
    }
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete 
  const handleDelete = async () => {
    setShowDeleteAlert(false)
    await deleteFolder(folder, view)
    if (activeFolder === folder.id) {
      onFilter(folder.parentFolder || 'all')
      return
    }
  }

  // drag and drop to reorder folders
  const [orderedFolders, setOrderedFolders] = useState([]);
  const [draggedOverItem, setDraggedOverItem] = useState();
  const [parent, setParent] = useState('');

  useEffect(() => {
    const subFolders =
      view === 'documents' ? docFolders.filter(f => f.parentFolder === folder.id) :
        view === 'templates' ? folders.filter(f => f.parentFolder === folder.id) :
          view === 'standard-templates' ? standardTemplatesFolders.filter(f => f.parentFolder === folder.id) : []

    const folderOrder = foldersOrderMap?.[view]?.[folder.id] || [];
    let orderedFoldersCopy = folderOrder.map(id => subFolders.find(folder => folder.id === id));
    // filter out undefined folders & add folders that are not in the order
    subFolders.forEach(folder => {
      if (!orderedFoldersCopy.includes(folder)) {
        orderedFoldersCopy.push(folder);
      }
    });
    orderedFoldersCopy = orderedFoldersCopy.filter(folder => folder);
    setOrderedFolders(orderedFoldersCopy);
  }, [foldersOrderMap, folder.id, docFolders, folders, standardTemplatesFolders, view]);

  const dragFolder = useRef(0);
  const draggedOverFolder = useRef(0);

  function reorder() {
    const orderedFoldersClone = [...orderedFolders];
    const movedFolder = orderedFoldersClone.splice(dragFolder.current, 1)[0];
    orderedFoldersClone.splice(draggedOverFolder.current, 0, movedFolder);

    const newFullOrder = { ...(foldersOrderMap?.templates || {}) }
    newFullOrder[folder.id] = orderedFoldersClone.map(folder => folder.id)
    updateFoldersOrder(view, newFullOrder)

    setOrderedFolders(orderedFoldersClone);
    setDraggedOverItem();
    setParent();
  }

  const totalAmount = (folderId) => {
    let data = view === 'documents' ? documents :
      view === 'templates' ? templates :
        view === 'standard-templates' ? standardTemplates : [];
    let x = Object.keys(data).reduce((filteredItems, key) => {
      if (data[key].folderId?.includes(folderId)) {
        filteredItems[key] = data[key];
      }
      return filteredItems;
    }, {});
    return Object.entries(x);
  }

  return (
    <div className="folder-list-item">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          draggable={reorderDraggable}
          onDragStart={reorderDragStart}
          onDragEnter={reorderDragEnter}
          onDragEnd={reorderDragEnd}
          onDragOver={reorderDragOver}>
          <div className={`folder-list-item__main ${activeFolder === folder.id ? 'active' : ''}`}>
            {/* {hasSubFolders && <span className={arrowActive ? "icon-arrow active" : "icon-arrow"} onClick={handleArrowClick}>
              <CaretRight />
            </span>} */}
            <CustomTooltip content={folder.name}>
              <p onClick={handleFilter}>
                <div
                  className={reorderDraggable ? 'grabbable' : ''}
                >
                  {level === 'first' ? <Folder /> : <Source />}
                </div>
                <span className="text"
                  draggable={makeDraggable}
                  onDragStart={handleDragStart}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDragEnd={handleDragEnd}
                  onDrop={handleDrop}
                >{folder.name}
                </span>
                {level !== 'first' && view === 'templates' && <span className="number">
                  {totalAmount(folder.id).length}
                </span>}
              </p>
            </CustomTooltip>
            {areFolderActionsAuthorized(view, userClaims) && (
              <>
                <span
                  className="icon-right cursor-pointer"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleIconRightClick}

                  draggable={makeDraggable}
                  onDragStart={handleDragStart}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDragEnd={handleDragEnd}
                  onDrop={handleDrop}
                >
                  <MoreVert />
                </span>
                {showDropdown && <div className="dropdown" style={{ left: popupElPosition.left, top: popupElPosition.top }}>
                  <a href="/" onClick={handleCreateNewFolderClick} className="create">
                    <span className="icon"><CreateNewFolder />
                    </span>
                    Nouveau dossier
                  </a>
                  <a href="/" onClick={handleEditFolderClick}>
                    <span className="icon"><Pen />
                    </span>
                    Renommer
                  </a>
                  <a href="/" onClick={handleMoveFolderClick} className="move">
                    <span className="icon"><MoveToFolder />
                    </span>
                    Déplacer
                  </a>
                  <a href="/" onClick={handleDeleteFolderClick} className="delete">
                    <span className="icon"><Delete />
                    </span>
                    Supprimer
                  </a>
                </div>}
              </>
            )}
          </div>
          {showFoldersPopup && (
            <FoldersPopup
              ref={popupEl}
              onCancel={handleCloseFoldersPopup}
              folders={view === 'documents' ? docFolders : view === 'templates' ? folders : view === 'standard-templates' ? standardTemplatesFolders : []}
              foldersLoading={view === 'documents' ? docFoldersLoading : view === 'templates' ? foldersLoading : view === 'standard-templates' ? standardTemplatesFoldersLoading : false}
              loading={false}
              data={view === 'documents' ? documents : view === 'templates' ? templates : view === 'standard-templates' ? standardTemplates : []}
              resource={folder}
              position={popupElPosition}
              view={view}
            />
          )}
        </div>
      </ClickAwayListener>
      <div className="folder-list-item__body">
        {orderedFolders?.length > 0 && arrowActive && (
          <ul className="sub-folders" data-parent={orderedFolders?.[0]?.parentFolder}>
            {orderedFolders?.map((f, idx) => {
              return <li key={`folder_${folder.id}_${idx}`}
                className={`flex ${idx === draggedOverItem && 'dragged-over-item'}
                 ${(parent === orderedFolders[draggedOverFolder.current]?.parentFolder && dragFolder.current > draggedOverFolder.current) && 'top'} 
                 ${(parent === orderedFolders[draggedOverFolder.current]?.parentFolder && dragFolder.current < draggedOverFolder.current) && 'bottom'}`}>
                <FolderListItem
                  key={idx}
                  folder={f}
                  activeFolder={activeFolder}
                  onFilter={onFilter}
                  view={view}
                  reorderDragStart={() => { dragFolder.current = idx; setParent(f.parentFolder) }}
                  reorderDragEnter={() => { draggedOverFolder.current = idx; }}
                  reorderDragEnd={reorder}
                  reorderDragOver={(e) => { setDraggedOverItem(idx) }}
                  reorderDraggable={reorderDraggable}
                />
              </li>
            })}
          </ul>
        )}
      </div>

      {showCreateFolderModal && (
        <CreateEditFolderModal
          onClose={handleCloseCreateFolderModal}
          current={folder}
          view={view}
        />
      )}

      {showEditFolderModal && (
        <CreateEditFolderModal
          onClose={handleCloseEditFolderModal}
          current={folder}
          mode="edit"
          view={view}
        />
      )}

      {showDeleteAlert && (
        <Alert
          onClose={handleCloseDeleteAlert}
          onSubmit={handleDelete}
          deleteAlert={true}
          text="Êtes-vous sûr de vouloir supprimer ce dossier ?"
        />
      )}

    </div>
  )
}

export default FolderListItem