import React, { Fragment, useState, useEffect, useContext } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FolderIcon from '@material-ui/icons/Folder';

import Modal from '../UI/Modal';
import IconButton from '../UI/IconButton';
import Button from '../UI/Button';

import { getAllParentFolders, getFirstLevelSubfolders } from '../../utils';

const MoveToFolderModal = ({ onClose, onMove, folders, onCreateNewFolder, currentFolder, newFolderCreated, onNewFolderCreated, loading }) => {
  const [activeFolder, setActiveFolder] = useState([...folders].find(f => f.id === currentFolder.parentFolder));
  const [subfolders, setSubfolders] = useState(getFirstLevelSubfolders(folders, activeFolder));
  const [parentFolders, setParentFolders] = useState(getAllParentFolders(folders, currentFolder).reverse());

  useEffect(() => {
    if(newFolderCreated) {
      setSubfolders(getFirstLevelSubfolders(folders, activeFolder));
      onNewFolderCreated(false);
    }
  }, [newFolderCreated, activeFolder, folders, onNewFolderCreated]);

  const itemClickHandler = (folder) => {
    setActiveFolder(folder);
    if(folder) {
      setParentFolders([...getAllParentFolders(folders, folder).reverse(), folder]);
    }else {
      setParentFolders([]);
    }
    setSubfolders(getFirstLevelSubfolders(folders, folder));
  }

  let breadcrumbsContent = null;

  if(parentFolders.length > 0) {
    breadcrumbsContent = <Fragment>
      <li className="breadcrumbs__item" onClick={() => itemClickHandler(null)}><p>Tous</p></li>
      {parentFolders.map((f, i, arr) => {
        if(i < arr.length - 1) {
          return <li className="breadcrumbs__item" key={i} onClick={() => itemClickHandler(f)}>
            <ChevronRightIcon />
            <p>{f.name}</p>
          </li>
        }else {
          return <li className="breadcrumbs__item active" key={i}>
            <ChevronRightIcon />
            {f.name}
          </li>
        }
      })}
    </Fragment>;
  }else {
    breadcrumbsContent = <li className="breadcrumbs__item active">Tous</li>
  }

  return(
    <Modal onClose={onClose}>
      <div className="move-to-folder">
        <div className="move-to-folder__head">
          <h4>Déplacer <strong>{currentFolder.name}</strong> vers...</h4>
          {/* <IconButton icon={<CloseIcon />} onButtonClick={onClose} dark medium round /> */}
        </div>
        <div className="move-to-folder__body">
          <div className="move-to-folder__body_top">
            <ul className="breadcrumbs">
              {breadcrumbsContent}
            </ul>
            <IconButton icon={<CreateNewFolderIcon />} onButtonClick={(e) => onCreateNewFolder(e, activeFolder)} medium />
          </div>
          <div className="move-to-folder__body_main">
            {[...subfolders].filter(f => f.id !== currentFolder.id).map((f, i) => (
              <div className="folder-inner" key={i} onClick={() => itemClickHandler(f)}><FolderIcon /> {f.name}</div>
            ))}
            {[...subfolders].filter(f => f.id !== currentFolder.id).length === 0 && <div className="no-folders">
              <h3>Pas de dossiers</h3>
              <p>Créer un dossier</p>  
            </div>}
          </div>
        </div>
        <div className="move-to-folder__foot">
          <Button text="Annuler" onButtonClick={onClose} />
          <Button 
            text={loading ? "Déplacement..." : "Déplacer ici"} 
            onButtonClick={() => onMove(currentFolder, activeFolder)} 
            primary 
            disabled={loading}
          />
        </div>
      </div>
    </Modal>
  );
}

export default MoveToFolderModal;