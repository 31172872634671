import { Fragment, cloneElement, useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'

// Content
const TooltipContent = ({ tooltipClass, content, position, tooltipPosition, duration, fixed, style, spaceSm }) => {
  const tooltipEl = useRef()
  const targetEl = document.getElementById('root')

  useEffect(() => {
    const el = tooltipEl.current

    if(el) {
      el.style.transitionDuration = duration + 'ms'
      if(fixed) {
        el.style.position = 'fixed'
      }

      const left = Math.min(Math.max(10, (position.left - (el.clientWidth / 2))), window.innerWidth - el.clientWidth - 10)

      const contentWidth = el.clientWidth

      setTimeout(() => {
        if(tooltipPosition === 'top') {
          el.style.top = `${position.top}px`
          el.style.left = `${left}px`
          el.style.transform = `translate(0, calc(-100% ${spaceSm ? '-12px' : '-35px'})) scale(1)`
        }
        else if(tooltipPosition === 'bottom') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left}px`
          el.style.transform = `translate(-50%, ${spaceSm ? '12px' : '15px'}) scale(1)`
        }
        else if(tooltipPosition === 'left') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left - el.clientWidth}px`
          el.style.transform = `translate(${spaceSm ? '-12px' : '-15px'}, -50%) scale(1)`
        }
        else if(tooltipPosition === 'right') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left}px`
          el.style.transform = `translate(${spaceSm ? '12px' : '15px'}, -50%) scale(1)`
        }
        else if(tooltipPosition === 'top-left') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left}px`
          el.style.transform = `translate(0, calc(-100% - ${spaceSm ? '12px' : '15px'})) scale(1)`
        }
        else if(tooltipPosition === 'top-right') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left}px`
          el.style.transform = `translate(-100%, calc(-100% - ${spaceSm ? '12px' : '15px'})) scale(1)`
        }
        else if(tooltipPosition === 'bottom-left') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left}px`
          el.style.transform = `translate(0, ${spaceSm ? '12px' : '15px'}) scale(1)`
        }
        else if(tooltipPosition === 'bottom-right') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left}px`
          el.style.transform = `translate(-100%, ${spaceSm ? '12px' : '15px'}) scale(1)`
        }
        else if(tooltipPosition === 'left-top') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left - el.clientWidth}px`
          el.style.transform = `translate(${spaceSm ? '-12px' : '-15px'}, 0) scale(1)`
        }
        else if(tooltipPosition === 'left-bottom') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left - el.clientWidth}px`
          el.style.transform = `translate(${spaceSm ? '-12px' : '-15px'}, -100%) scale(1)`
        }
        else if(tooltipPosition === 'right-top') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left}px`
          el.style.transform = `translate(${spaceSm ? '12px' : '15px'}, 0) scale(1)`
        }
        else if(tooltipPosition === 'right-bottom') {
          el.style.top = `${position.top}px`
          el.style.left = `${position.left}px`
          el.style.transform = `translate(${spaceSm ? '12px' : '15px'}, -100%) scale(1)`
        }

        // adjust width
        if(tooltipPosition === 'top' || tooltipPosition === 'bottom') {
          if(left + contentWidth > window.innerWidth - (spaceSm ? 17 : 40)) {
            el.style.maxWidth = `${window.innerWidth - left - (spaceSm ? 22 : 45)}px`
          }
        }

        el.style.opacity = '1'
      }, 20);
    }
  }, [])

  const output = <div className={tooltipClass} ref={tooltipEl} style={style}>{content}</div>

  return targetEl ? ReactDOM.createPortal(output, targetEl) : output
}

// Tooltip
const CustomTooltip = ({ children, position = 'top', content, animationDuration = 500, fixed, hideTooltip = false, className, style, spaceSm }) => {
  const [elPosition, setElPosition] = useState({ top: 0, left: 0 })
  const [show, setShow] = useState(false)
  let tooltipClass = `custom-tooltip-v2 ${className}`

  const getPosition = (e) => {
    const pos = e.currentTarget.getBoundingClientRect()

    if(position === 'top') {
      let top = fixed ? pos.top : pos.top + window.scrollY
      setElPosition({ top, left: pos.left + (pos.width / 2) + window.scrollX })
    }
    else if(position === 'bottom') {
      setElPosition({ top: pos.bottom + window.scrollY, left: pos.left + (pos.width / 2) + window.scrollX })
    }
    else if(position === 'left') {
      let top = fixed ? pos.top + (pos.height / 2) : pos.top + (pos.height / 2) + window.scrollY
      setElPosition({ top, left: pos.left + window.scrollX })
    }
    else if(position === 'right') {
      setElPosition({ top: pos.top + (pos.height / 2) + window.scrollY, left: pos.left + pos.width + window.scrollX })
    }
    else if(position === 'top-left') {
      let top = fixed ? pos.top : pos.top + window.scrollY
      setElPosition({ top, left: pos.left + window.scrollX })
    }
    else if(position === 'top-right') {
      let top = fixed ? pos.top : pos.top + window.scrollY;
      setElPosition({ top, left: pos.left + pos.width + window.scrollX })
    }
    else if(position === 'bottom-left') {
      setElPosition({ top: pos.top + pos.height + window.scrollY, left: pos.left + window.scrollX })
    }
    else if(position === 'bottom-right') {
      setElPosition({ top: pos.top + pos.height + window.scrollY, left: pos.left + pos.width + window.scrollX })
    }
    else if(position === 'left-top') {
      setElPosition({ top: pos.top + window.scrollY, left: pos.left + window.scrollX })
    }
    else if(position === 'left-bottom') {
      setElPosition({ top: pos.bottom + window.scrollY, left: pos.left + window.scrollX })
    }
    else if(position === 'right-top') {
      setElPosition({ top: pos.top + window.scrollY, left: pos.left + pos.width + window.scrollX })
    }
    else if(position === 'right-bottom') {
      setElPosition({ top: pos.bottom + window.scrollY, left: pos.left + pos.width + window.scrollX })
    }

    setShow(true)
  }

  if(position === 'top') {
    tooltipClass += ' custom-tooltip-v2--top'
  }
  else if(position === 'bottom') {
    tooltipClass += ' custom-tooltip-v2--bottom'
  }
  else if(position === 'left') {
    tooltipClass += ' custom-tooltip-v2--left'
  }
  else if(position === 'right') {
    tooltipClass += ' custom-tooltip-v2--right'
  }
  else if(position === 'top-left') {
    tooltipClass += ' custom-tooltip-v2--top-left'
  }
  else if(position === 'top-right') {
    tooltipClass += ' custom-tooltip-v2--top-right'
  }
  else if(position === 'bottom-left') {
    tooltipClass += ' custom-tooltip-v2--bottom-left'
  }
  else if(position === 'bottom-right') {
    tooltipClass += ' custom-tooltip-v2--bottom-right'
  }
  else if(position === 'left-top') {
    tooltipClass += ' custom-tooltip-v2--left-top'
  }
  else if(position === 'left-bottom') {
    tooltipClass += ' custom-tooltip-v2--left-bottom'
  }
  else if(position === 'right-top') {
    tooltipClass += ' custom-tooltip-v2--right-top'
  }
  else if(position === 'right-bottom') {
    tooltipClass += ' custom-tooltip-v2--right-bottom'
  }

  return(
    <Fragment>
      {!hideTooltip && show && <TooltipContent tooltipClass={tooltipClass} position={elPosition} content={content} tooltipPosition={position} duration={animationDuration} fixed={fixed} style={style} spaceSm={spaceSm} />}
      {cloneElement(children, {...children.props, onMouseEnter: getPosition, onMouseLeave: () => setShow(false)})}
    </Fragment>
  )
}

export default CustomTooltip