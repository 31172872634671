import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Radio from '../../UI/Radio';
import Button from '../../UI/Button';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment'
import { dateValueFormat, areSectionConditionsMet } from '../../../utils';
import { CONTACT_ID_VARIABLES } from '../../../constants'

const SynthesisModal = ({ onClose, data, getVarKeysFromContent, documentValues, onSetDocumentValues, onSave }) => {
  const [activeFilter, setActiveFilter] = useState('all');
  const [variableSections, setVariableSections] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [sectionsCreated, setSectionsCreated] = useState(false);

  useEffect(() => {
    if(!data) {
      return;
    }
    if(!sectionsCreated) {
      let docValues = {...documentValues}
      let updateDocValues = false
      const sections = [...data.sections];
      const sectionsHeadings = sections.filter(s => s.type.startsWith('heading'));
      const sectionsUpdated = [];
      if(sectionsHeadings.length === 0) {
        sectionsUpdated.push(sections);
      }else if(sections.indexOf(sectionsHeadings[0]) > 0) {
        sectionsUpdated.push(sections.slice(0, sections.indexOf(sectionsHeadings[0])));
      }
      sectionsHeadings.forEach((s, i, allArr) => {
        const sectionIndex = sections.indexOf(s);
        const arr = sections.indexOf(allArr[i+1]) !== -1 ? sections.slice(sectionIndex, sections.indexOf(allArr[i+1])) : sections.slice(sectionIndex);
        arr[0].sectionIndex = `section-${sectionIndex}`;
        sectionsUpdated.push(arr);
      });
      const sectionsArr = [];
      
      sectionsUpdated.forEach((s) => {
        const vars = [];
        let obj = { title: '', vars: [], content: s.content };
        if(s.repeatable_section_id) {
          obj.repeatable_section_id = s.repeatable_section_id;
        }
  
        let idx = 0;
        for(let secField in s) {
          const f = s[secField];
          // console.log(f.repeatable_section_id)
          // if(f.repeatable_section_id) {
          //   obj.repeatable_section_id = f.repeatable_section_id;
          // }
          if(idx === 0 && f.type.startsWith('heading')) {
            const fields = f.content.match(/\{d\.([^}]*)\}/g);
            const title = fields ? fields.length > 0 ? f.content.replace(fields[0], '') : f.content : f.content;
            obj.title = title;
            obj.index = f.sectionIndex;
          }
          if(f.variables || f.variable){
            if(f.variable) {
              if(!f.variable.includes('cover_page')) {
                if(f.repeatable_section_id) {
                  if(documentValues[f.repeatable_section_id]) {
                    documentValues[f.repeatable_section_id].forEach((_, index) => {
                      let splittedVar = f.variable.split('_')
                      let lastEl = splittedVar[splittedVar.length - 1] 
                      if(lastEl !== 'id') {
                        if(f.conditions) {
                          obj.vars.push({...f, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: index})
                        }else if(f.condition) {
                          obj.vars.push({...f, conditions: [f.condition], belongs_to: f.condition.variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: index })
                        }else {
                          obj.vars.push({...f, repeatable_section_id: f.repeatable_section_id, repeatable_index: index});
                        }
                        vars.push(f.variable);
                      }
                    })
                  }else {
                    docValues[f.repeatable_section_id] = [{}]
                    updateDocValues = true
                    let splittedVar = f.variable.split('_')
                    let lastEl = splittedVar[splittedVar.length - 1] 
                    if(lastEl !== 'id') {
                      if(f.conditions) {
                        obj.vars.push({...f, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0})
                      }else if(f.condition) {
                        obj.vars.push({...f, conditions: [f.condition], belongs_to: f.condition.variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0 })
                      }else {
                        obj.vars.push({...f, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0});
                      }
                      vars.push(f.variable);
                    }
                  }
                }else {
                  if(f.conditions) {
                    obj.vars.push({...f, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable})
                  }else if(f.condition) {
                    obj.vars.push({...f, conditions: [f.condition], belongs_to: f.condition.variable })
                  }else {
                    obj.vars.push(f);
                  }
                  if(!vars.includes(f.variable)) {
                    vars.push(f.variable);
                  }
                }
              }
            }else {
              if(f.repeatable_section_id) {
                if(documentValues[f.repeatable_section_id]) {
                  documentValues[f.repeatable_section_id].forEach((_, index) => {
                    f.variables.forEach((v) => {
                      let isCoverPageVar = false
                      if(v.variable && v.variable.includes('cover_page')) isCoverPageVar = true
                      if(!isCoverPageVar) {
                        let splittedVar = v.variable.split('_')
                        let lastEl = splittedVar[splittedVar.length - 1] 
                        if(lastEl !== 'id') {
                          vars.push(v.variable);
                          if(obj.vars.indexOf(f) === -1) {
                            if(f.conditions) {
                              obj.vars.push({...v, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: index });
                            }else if(f.condition) {
                              obj.vars.push({...v, conditions: [f.condition], belongs_to: f.condition.variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: index })
                            }else {
                              obj.vars.push({...v, repeatable_section_id: f.repeatable_section_id, repeatable_index: index});
                            }
                          }
                        }
                      }
                    });
                  })
                }else {
                  docValues[f.repeatable_section_id] = [{}]
                  updateDocValues = true
                  f.variables.forEach((v) => {
                    let isCoverPageVar = false
                    if(v.variable && v.variable.includes('cover_page')) isCoverPageVar = true
                    if(!isCoverPageVar) {
                      let splittedVar = v.variable.split('_')
                      let lastEl = splittedVar[splittedVar.length - 1] 
                      if(lastEl !== 'id') {
                        vars.push(v.variable);
                        docValues[f.repeatable_section_id][0] = { ...docValues[f.repeatable_section_id][0], [v.variable]: '' }
                        if(obj.vars.indexOf(f) === -1) {
                          if(f.conditions) {
                            obj.vars.push({...v, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0 });
                          }else if(f.condition) {
                            obj.vars.push({...v, conditions: [f.condition], belongs_to: f.condition.variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0 })
                          }else {
                            obj.vars.push({...v, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0 });
                          }
                        }
                      }
                    }
                  });
                }
              }else {
                f.variables.forEach((v) => {
                  let isCoverPageVar = false
                  if(v.variable && v.variable.includes('cover_page')) isCoverPageVar = true
                  if(!isCoverPageVar) {
                    if(!vars.includes(v.variable)) {
                      vars.push(v.variable);
                    }
                    if(obj.vars.indexOf(f) === -1) {
                      if(f.conditions) {
                        obj.vars.push({...v, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable });
                      }else if(f.condition) {
                        obj.vars.push({...v, conditions: [f.condition], belongs_to: f.condition.variable })
                      }else {
                        obj.vars.push(v);
                      }
                    }
                  }
                });
              }
            }
          }
          idx++;
        }
        // console.log(obj)
        if(obj.vars.length > 0) {
          sectionsArr.push(obj);
        }
      });
      // console.log(sectionsArr)
      if(updateDocValues) {
        onSetDocumentValues(docValues)
      }
      setVariableSections(sectionsArr);
      setFilteredSections(sectionsArr);
      setSectionsCreated(true);
    }
    // eslint-disable-next-line
  }, [data, documentValues]);

  const submitHandler = (e) => {
    e.preventDefault();
    onSave(true);
  }

  const onValueChange = (handle, value, repeatableSectionId, repeatableIndex) => {
    let dv = {...documentValues};
    if(repeatableSectionId) {
      dv[repeatableSectionId][repeatableIndex][handle] = value
    } else {
      dv[handle] = value;
    }
    onSetDocumentValues(dv);
  }

  const variableKeysInSection = (sec) => {
    let varKeys = []
    for(let i in sec.variables) {
      if(CONTACT_ID_VARIABLES.includes(sec.variables[i].variable)) {
        continue
      }
      varKeys.push(sec.variables[i].variable)
    }

    if(sec.items) {
      for(let i in sec.items) {
        for(let v in sec.items[i].variables) {
          if(CONTACT_ID_VARIABLES.includes(sec.items[i].variables[v].variable)) {
            continue
          }
          varKeys.push(sec.items[i].variables[v].variable)
        }
      }
    }
    return varKeys
  }

  const renderTextInput = (variable, repeatableSectionId, repeatableIndex, type = "text", label, locked) => {
    let disabled = locked
    if(repeatableSectionId) {
      const splittedVar = variable.split('_')
      if(splittedVar && splittedVar[splittedVar.length - 1] === 'id') {
        disabled = true
      }
    }
    let value = repeatableSectionId ? documentValues[repeatableSectionId][repeatableIndex][variable] : documentValues[variable]
    return ( 
      <div className="input-wrapper">
        <Input small value={value || ''} type={type} editMode onChange={(e) => onValueChange(variable, e.target.value, repeatableSectionId, repeatableIndex) } className={value ? 'not-empty' : ''} label={label} disabled={disabled} /> 
      </div>
    )
  }

  const renderTextArea = (variable, label, repeatableSectionId, repeatableIndex, locked) => {
    let value = repeatableSectionId ? documentValues[repeatableSectionId][repeatableIndex][variable] : documentValues[variable]
    return ( 
      <div className="textarea-wrapper">
        <p className="label">{label}</p>
        <textarea disabled={locked} value={value || ''} onChange={(e) => onValueChange(variable, e.target.value, repeatableSectionId, repeatableIndex) } className={!!value ? 'not-empty' : ''}></textarea>
      </div>
    )
  }

  const renderSelectInput = (variable, options, label, repeatableSectionId, repeatableIndex, locked) => {
    let value = repeatableSectionId ? documentValues[repeatableSectionId][repeatableIndex][variable] : documentValues[variable]
    return (
      <div className="input-wrapper">
        <div className={value ? "select_input_wrapper not-empty" : "select_input_wrapper"}>
          <p className="label">{label}</p>
          <select disabled={locked} name="status" id="status" value={value} onChange={e => onValueChange(variable, e.target.value, repeatableSectionId, repeatableIndex)}>
            <option disabled selected value> Sélectionnez une option </option>
            { options.map((option, optionIndex) => {
              return (
                <option disabled={locked} key={`option_${optionIndex}`} value={option.value}>{ option.label }</option>
              )
            }) }
          </select>
        </div>
      </div>
    )
  }

  const renderDateInput = (variable, label, repeatableSectionId, repeatableIndex, locked) => {
    let value = repeatableSectionId ? documentValues[repeatableSectionId][repeatableIndex][variable] : documentValues[variable]
    return (
      <div className="input-wrapper">
        <div className={value ? 'date_picker not-empty' : 'date_picker'}>
          <p className="label">{label}</p>
          <DatePicker
            disabled={locked}
            selected={!value ? '' : new Date(moment(value, dateValueFormat).valueOf())}
            onChange={date => date ? onValueChange(variable, moment(date.valueOf()).format(dateValueFormat), repeatableSectionId, repeatableIndex) : ''}
            peekNextMonth
            dateFormat={'EEE dd/MM/yyyy'}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="fr"
          />
        </div>
      </div>
    )
  }

  const filterItemClickHandler = (e, value) => {
    e.preventDefault();
    setActiveFilter(value);
    let filteredSectionArr = [];
    let varSectionsCopy = [...variableSections];
    if(value === 'all') {
      filteredSectionArr = varSectionsCopy;
    }else if(value === 'completed') {
      varSectionsCopy.forEach((s) => {
        let vars = [];
        let section = {...s};
        section.vars.forEach((v) => {
          if(v.repeatable_section_id && documentValues[v.repeatable_section_id]) {
            if(documentValues[v.repeatable_section_id][v.repeatable_index][v.variable]) {
              vars.push(v);
            }
          }else {
            if(documentValues[v.variable]) {
              vars.push(v);
            }
          }
        });
        if(vars.length > 0) {
          section.vars = vars;
          filteredSectionArr.push(section);
        }
      });
    }else if(value === 'empty'){
      varSectionsCopy.forEach((s) => {
        let vars = [];
        let section = {...s};
        section.vars.forEach((v) => {
          if(v.type !== 'question') {
            if(v.repeatable_section_id && documentValues[v.repeatable_section_id]) {
              if(!documentValues[v.repeatable_section_id][v.repeatable_index][v.variable]) {
                vars.push(v);
              }
            }else {
              if(!documentValues[v.variable]) {
                vars.push(v);
              }
            }
          }else if(v.type === 'question') {
            if(documentValues[v.variable]) {
              vars.push({...v, hide: true});
            }else {
              vars.push(v);
            }
          }
        });
        if(vars.length > 0) {
          section.vars = vars;
          filteredSectionArr.push(section);
        }
      });
    }
    setFilteredSections(filteredSectionArr);
  }
  
  const renderInput = (v, arr, repeatableSectionId, repeatableIndex) => {
    let input
    if(v.type === 'string'  && areSectionConditionsMet(v, documentValues)) {
      input = renderTextInput(v.variable, v.repeatable_section_id, v.repeatable_index, 'text', v.tooltip, v.locked)
    } else if(v.type === 'number' && areSectionConditionsMet(v, documentValues)) {
      input = renderTextInput(v.variable, v.repeatable_section_id, v.repeatable_index, 'number', v.tooltip, v.locked) // change to number
    } else if(v.type === 'textarea' && areSectionConditionsMet(v, documentValues)) {
      input = renderTextArea(v.variable, v.tooltip, v.repeatable_section_id, v.repeatable_index, v.locked) 
    }else if(v.type === 'select' && areSectionConditionsMet(v, documentValues)) {
      input = renderSelectInput(v.variable, v.options, v.tooltip, v.repeatable_section_id, v.repeatable_index, v.locked)
    } else if(v.type === 'date' && areSectionConditionsMet(v, documentValues)) {
      input = renderDateInput(v.variable, v.tooltip, v.repeatable_section_id, v.repeatable_index, v.locked)
    }else if(v.type === 'question' && documentValues[v.variable] && v.options && areSectionConditionsMet(v, documentValues)) {
      const answer = v.options.find(a => a.value === documentValues[v.variable])
      const children = arr.filter(item => item.belongs_to === v.variable)

      let childInput = []
      children.forEach(child => {
        if(child) {
          childInput.push(renderInput(child, arr, v.repeatable_section_id, v.repeatable_index))
        }
      });
      input = <div className="question-container">
        <div className={v.hide ? "question-wrapper hide" : "question-wrapper"}>
          <p>{v.question} <span>{answer && answer.label}</span></p>
        </div>
        {childInput.length > 0 && 
          <div className="inputs-wrapper">
            {childInput.map((ch, index) => ch && <Fragment key={index}>{ch}</Fragment>)}
          </div>
        }
      </div>
    }
    return input
  }

  return(
    <Modal onClose={onClose} className="modal--v2">
      <div className="modal-inner">
        <h2>{data?.name || ''}</h2>
        <div className="modal-inner__head">
          <ul>
            <li className="active no-cursor">Synthèse</li>
          </ul>
        </div>
        <div className="modal-inner__body">
          <div className="synthesis">
            <ul className="synthesis__filters">
              <li>
                <a href="/#" className={activeFilter === 'all' ? 'active' : ''} onClick={(e) => filterItemClickHandler(e, 'all')}>
                  Tous les champs
                </a>
              </li>
              <li>
                <a href="/#" className={activeFilter === 'empty' ? 'active' : ''} onClick={(e) => filterItemClickHandler(e, 'empty')}>
                  Champs vides
                </a>
              </li>
              <li>
                <a href="/#" className={activeFilter === 'completed' ? 'active' : ''} onClick={(e) => filterItemClickHandler(e, 'completed')}>
                  Champs saisis
                </a>
              </li>
            </ul>
            <form className="form" onSubmit={submitHandler}>
              {filteredSections.map((section, idx) => {
                return (
                  <div className="synthesis__section" key={idx}>
                    {section.title && <h3>{section.title}</h3>}
                    <div className="synthesis__section_fields">
                      <div className="inputs-wrapper">
                        {section.vars.map((v, idx, arr) => {
                          if(v.belongs_to) return null
                          if(section.repeatable_section_id) {
                            return documentValues[section.repeatable_section_id] && documentValues[section.repeatable_section_id].map((repetition, rep_i) => {
                              return renderInput(v, arr, section.repeatable_section_id, rep_i)
                            })
                          }else {
                            return renderInput(v, arr)
                          }
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* {Boolean(data.sections) && data.sections.map(s => {
                  if(s.type === 'text' || s.type === 'bullet_list') {
                    const keys = variableKeysInSection(s)
                    if(keys.length === 0) {
                      return null
                    }
                    if(s.repeatable_section_id) {
                      return documentValues[s.repeatable_section_id] && documentValues[s.repeatable_section_id].map((repetition, rep_i) => {
                        return variableInputsForSection(s, keys, s.repeatable_section_id, rep_i)
                      })
                    } else {
                      return variableInputsForSection(s, keys)
                    }
                  }
                  return null;
              })} */}

              <div className="form__actions">
                <Button 
                  text="Annuler" 
                  onButtonClick={onClose}
                  medium
                  transparent
                />
                <Button 
                  text="Sauvegarder"
                  type="submit"
                  primary
                  medium
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}

SynthesisModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default SynthesisModal;