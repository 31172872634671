import { useState, useContext } from 'react'

import { Delete, Close, Copy } from '../../assets/icons'
import { DocumentsContext, NotificationContext, LoaderContext } from '../../context'
import { Alert, Checkbox } from '../ui_new'
import { MoreHoriz } from '@material-ui/icons'
import { Check, MoreVert } from '@mui/icons-material'

const AttachmentsActions = ({ selectedAttachments, onSetSelectedAttachments, attachments, onSetDocumentAttachments, onSetAllChecked, docId }) => {
  const { updateDocument, setCopiedAttachments, setAttachmentsFrom } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [displayMore, setDisplayMore] = useState(false)

  // On attachments copy
  const handleCopyAttachments = (e) => {
    e.preventDefault()
    setCopiedAttachments(selectedAttachments)
    setAttachmentsFrom(docId)
    setNotification({ msg: `Les annexes ont bien été copiés(${selectedAttachments.length})`, type: 'info' })
    onSetSelectedAttachments([])
    onSetAllChecked(false)
  }

  // On attachments delete
  const handleAttachmentsDelete = async (e) => {
    e.preventDefault()
    setShowGlobalResponseLoader(true)
    try {
      const ids = selectedAttachments.map(a => a.id)
      const updatedAttachments = attachments.filter(a => !ids.includes(a.id))
      await updateDocument({ attachments: updatedAttachments }, { id: docId, attachments: updatedAttachments })
      onSetDocumentAttachments(updatedAttachments)
      onSetAllChecked(false)
      onSetSelectedAttachments([])
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On actions close
  const handleCloseActions = () => {
    onSetSelectedAttachments([])
    onSetAllChecked(false)
  }

  // On open delete alert
  const handleOpenDeleteAlert = (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On actions more
  const handleMoreActions = () => {
    setDisplayMore(true);
    // const element = document.querySelector('.footer-v2');
    // if (element) {
    //   element.style.zIndex = '-1';
    // }
  }
  //
  const hideMoreActions = () => {
    setDisplayMore(false);
    // const element = document.querySelector('.footer-v2');
    // if (element) {
    //   element.style.zIndex = 'initial';
    // }
  }

  return (
    <div className="dashboard-actions dashboard-actions--attachments">
      <div className="dashboard-actions__selected">
        <div className={`dashboard-document-box__check checked relative`}>
          <Checkbox label="" checked={true} />
        </div>
        <p>{selectedAttachments.length} Élément(s) sélectionné(s)</p>
      </div>
      <div className={`dashboard-actions__buttons_overlay ${displayMore ? 'active' : ''}`} onClick={hideMoreActions}></div>
      <div className={`dashboard-actions__buttons ${displayMore ? 'active' : ''}`}>
        <a href="/" onClick={handleCopyAttachments} className="icon-duplicate"><Copy /> Copier</a>
        <a href="/" onClick={handleOpenDeleteAlert} className="icon-delete"><Delete /> Supprimer</a>
      </div>
      <div className="dashboard-actions__more" onClick={handleMoreActions}>
        <MoreVert />
      </div>
      <div className="dashboard-actions__close" onClick={handleCloseActions}>
        <Close /> <span className="text">Fermer</span>
      </div>

      {showDeleteAlert && <Alert
        onClose={handleCloseDeleteAlert}
        text="Êtes-vous sûr de vouloir supprimer les pièces jointes sélectionnées ?"
        onSubmit={handleAttachmentsDelete}
        deleteAlert
      />}
    </div>
  )
}

export default AttachmentsActions