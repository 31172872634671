const Weight = (props) => {
    return (
        <span className="svg-icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                {...props}
            >
                <path d="M 8 1 C 6.9013528 1 6 1.9013528 6 3 C 6 3.3654971 6.1076578 3.7040972 6.28125 4 L 3.5 4 A 0.50005 0.50005 0 0 0 3.0117188 4.390625 L 1.0117188 13.390625 A 0.50005 0.50005 0 0 0 1.5 14 L 14.5 14 A 0.50005 0.50005 0 0 0 14.988281 13.390625 L 12.988281 4.390625 A 0.50005 0.50005 0 0 0 12.5 4 L 9.71875 4 C 9.8923422 3.7040972 10 3.3654971 10 3 C 10 1.9013528 9.0986472 1 8 1 z M 8 2 C 8.5582071 2 9 2.4417929 9 3 C 9 3.5582071 8.5582071 4 8 4 C 7.4417929 4 7 3.5582071 7 3 C 7 2.4417929 7.4417929 2 8 2 z M 3.9003906 5 L 8 5 L 12.099609 5 L 13.876953 13 L 2.1230469 13 L 3.9003906 5 z" />
            </svg>
        </span>
    )
}

export default Weight