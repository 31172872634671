import { useContext, useState, useEffect, useCallback } from 'react'
import { CSVLink } from 'react-csv'
import moment from 'moment'

import { MainLayout } from '../layouts'
import { Signature, SlidersH } from '../assets/icons'
import {
  SignaturesContentSidebar,
  SignaturesTable,
  SignaturesStatusPanel,
  SignaturesActions,
  SignaturePreviewModal,
  SignaturesNotAvailable,
  SignatureButtonsModal,
  RegisteredMailSendModal
} from '../components/misc'
import { ResourceNotFound, Modal } from '../components/ui_new'
import { DocumentsContext, LoaderContext, NotificationContext, RegisteredMailContext, SignatureContext, UserContext } from '../context'
import { sortArrayOfObjects, getSorting, saveSortingToLS } from '../utils'
import { fetch_signatures } from '../services/signatures'
import useVialinkActions from '../hooks/useVialinkActions'
import SingleDocumentSignVialink from '../components/misc/SingleDocumentSignVialink'
import { getSignaturePreview } from '../services/functions'
import { SIGNATURE_STATUS_TRANLSATIONS } from '../constants'

const Signatures = () => {
  const { setSignaturesSelectedFilters, signaturesSelectedFilters, signatures, signaturesFetched, fetchMoreSignatures } = useContext(SignatureContext)
  const { siteConfigsLoaded, canUseSignatures, isNotSQHorCAIpartner, urlStore, agency, user, partner } = useContext(UserContext)
  const { documents, templates } = useContext(DocumentsContext)
  // const { setShowRegisteredMailSendModal } = useContext(RegisteredMailContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { getRecipientsStatus, refreshStatus, downloadSignature } = useVialinkActions()
  const [selectedSignatures, setSelectedSignatures] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [activeSignatures, setActiveSignatures] = useState([])
  const [filteredSignatures, setFilteredSignatures] = useState([])
  const [activeSort, setActiveSort] = useState({ 'name': 'desc' , activeItem: 'name'})
  const [activeOrder, setActiveOrder] = useState('desc')
  const [ready, setReady] = useState(false)
  const [showStatusPanel, setShowStatusPanel] = useState(false)
  const [activeSignature, setActiveSignature] = useState(null)
  const [singleStatusLoading, setSingleStatusLoading] = useState(false)
  const [showPreviewDocModal, setShowPreviewDocModal] = useState(false)
  const [iframeSrc, setIframeSrc] = useState('')
  const [previewLoading, setPreviewLoading] = useState(false)
  const [previewError, setPreviewError] = useState('')
  const [showSignaturesNotAvailableModal, setShowSignaturesNotAvailableModal] = useState(true)
  const [showSignatureButtonsModal, setShowSignatureButtonsModal] = useState(false)
  const [signatureToUseInButtonsModal, setSignatureToUseInButtonsModal] = useState(null)
  const [csvData, setCsvData] = useState([])
  const [showDetailId, setShowDetailId] = useState('')
  const [currentSignature, setCurrentSignature] = useState(null)

  // set status from search string
  useEffect(() => {
    let query = window.location.search
    if (!query) {
      return
    }
    if (query[0] === '?') {
      query = query.substring(1)
    }
    let components = query.split('&')
    let statusFound
    for (let comp of components) {
      let keyValue = comp.split('=')
      if (keyValue.length < 2) {
        continue
      }
      if (keyValue[0] === 'status') {
        let filters = signaturesSelectedFilters
        filters.status = keyValue[1]
        setSignaturesSelectedFilters(filters)
        statusFound = true
      } else if (keyValue[0] === 'detail') {
        setShowDetailId(keyValue[1])
      }
    }
    if (!statusFound) {
      let filters = signaturesSelectedFilters
      filters.status = 'all'
      setSignaturesSelectedFilters(filters)
    }
  }, [])

  // Set signatures - active/filtered
  useEffect(() => {
    const setupSignatures = () => {
      // let sort = 'title'
      // let order = 'desc'
      // const sortLS = getSorting()
      // if (sortLS && sortLS.signatures) {
      //   sort = sortLS.signatures.value
      //   order = sortLS.signatures.order
      // }
      // setActiveSort(sort)
      // setActiveOrder(order)
      let modifiedArr = []
      for (let i = 0; i < signatures.length; i++) {
        let recipients = signatures[i].recipients.map(r => r.email)
        const recipientsNames = signatures[i].recipients.map(r => `${(r.firstName || '')} ${(r.lastName || '')}`)
        modifiedArr.push({
          ...signatures[i],
          sort_recipients: recipients,
          sort_recipients_num: recipients.length,
          sort_recipient_names: recipientsNames.map(r => r.trim().toLowerCase()).join(', '),
          sort_status: SIGNATURE_STATUS_TRANLSATIONS[signatures[i].status]
        })
      }
      let arr = sortArrayOfObjects(modifiedArr, activeSort.activeItem, activeOrder)
      setActiveSignatures(arr)
      setFilteredSignatures(arr)
      setReady(true)
    }

    if (signaturesFetched && siteConfigsLoaded && canUseSignatures) {
      setupSignatures()
    }
  }, [signatures, signaturesFetched, siteConfigsLoaded, canUseSignatures, activeSort, activeOrder])

  useEffect(() => {
    if (!showDetailId) {
      return
    }
    if (!(signaturesFetched && siteConfigsLoaded && canUseSignatures)) {
      return
    }
    const signatureToShow = signatures.find(s => s.id === showDetailId)
    if (!signatureToShow) {
      return
    }
    setShowDetailId('')
  }, [signatures, signaturesFetched, siteConfigsLoaded, canUseSignatures, showDetailId])

  // On filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeSignatures]

    for (let key in signaturesSelectedFilters) {
      if (key === 'status' && signaturesSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(s => s.status === signaturesSelectedFilters[key])
      }
      if (key === 'date_after' && signaturesSelectedFilters[key] !== '') {
        filtered = filtered.filter(s => s.meta.created > signaturesSelectedFilters[key])
      }
      if (key === 'date_before' && signaturesSelectedFilters[key] !== '') {
        filtered = filtered.filter(s => s.meta.created < signaturesSelectedFilters[key])
      }
      if (key === 'search' && signaturesSelectedFilters[key] !== '') {
        filtered = filtered.filter(s => s.title.toLowerCase().includes(signaturesSelectedFilters[key].trim().toLowerCase()))
      }
      if (key === 'owner' && signaturesSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(d => `${d.owner}` === `${signaturesSelectedFilters[key]}`)
      }
      if (key === 'author' && signaturesSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(d => d.author?.id === signaturesSelectedFilters[key])
      }
    }

    setFilteredSignatures(filtered)
  }, [activeSignatures, signaturesSelectedFilters])

  // Watch for filters change
  useEffect(() => {
    handleFilter()
  }, [signaturesSelectedFilters, handleFilter])

  // Handle sort
  const handleSort = (sort_by, order) => {
    setActiveSort((prev) => ({ ...prev, [sort_by]: order, activeItem: sort_by }))
    setActiveOrder(order)
    saveSortingToLS(sort_by, order, 'signatures')
  }

  // On open status panel
  const handleOpenStatusPanel = async (signature) => {
    setShowStatusPanel(true)
    getRecipientsStatus({ signature, setActiveSignature, setSingleStatusLoading })
  }

  // On close status panel
  const handleCloseStatusPanel = () => {
    setShowStatusPanel(false)
  }

  const handleOpenDetail = async (signature) => {

    setGlobalResponseLoaderText("")
    const newStatus = await refreshStatus({ signature, setRefreshing: setShowGlobalResponseLoader })
    console.log("handle open detail new status", newStatus)
    if(newStatus && newStatus !== "IN_PROGRESS") {
      setNotification({ msg: "La signature n'est plus modifiable", type: "warning" })
      return
    }
    const document = documents[signature.doc_id]
    let template
    if(document) {
      template = templates[document.template]
    }
    setCurrentSignature({...signature, document: document || { name: signature.title + `${signature.title ? ' ' : ''}[Document supprimé]`}, template: template})
  }

  const handleCloseDetail = () => {
    setCurrentSignature(null)
  }

  // On signature title click
  const handleSignatureTitleClick = async (signature) => {
    setShowPreviewDocModal(true)
    setPreviewLoading(true)
    let previewData
    let previewUrl
    previewData = await getSignaturePreview(signature)
    if(previewData.document_url) {
      previewUrl = previewData.document_url
    } else if(previewData.document) {
      previewData = previewData.document
    }
    if(previewData.error) {
      setPreviewError(previewData.error.message)
      setPreviewLoading(false)
      setShowPreviewDocModal(false)
      return
    }
    if(previewData) {
      setIframeSrc(`data:application/pdf;base64,${previewData}`)
    } else if(previewUrl) {
      setIframeSrc(previewUrl)
    }
    setPreviewLoading(false)
  }
  
  const handleSignatureDownload = async (signature) => {
    setGlobalResponseLoaderText("Téléchargement en cours...")
    setShowGlobalResponseLoader(true)

    await downloadSignature(signature)

    setGlobalResponseLoaderText('')
    setShowGlobalResponseLoader(false)
  }

  // On close preview modal
  const handleClosePreviewModal = () => {
    setShowPreviewDocModal(false)
  }

  // On close signatures not available modal
  const handleCloseSignaturesNotAvailableModal = () => {
    setShowSignaturesNotAvailableModal(false)
  }

  // On close signature buttons modal
  const handleCloseSignatureButtonsModal = () => {
    setShowSignatureButtonsModal(false)
    setSignatureToUseInButtonsModal(null)
  }

  // Export signatures
  const handleExportSignatures = async () => {
    const data = await fetch_signatures()
    if (!data) return
    if (data && !Array.isArray(data)) return
    let arr = [
      [
        "Manufacturer id",
        "Date",
        "Sent by",
        "Status",
        "Title",
        "Recipients"
      ]
    ]
    for (let i = 0; i < data.length; i++) {
      let signature = data[i]
      let recipients = []
      signature.recipients.forEach(r => recipients.push(r.email))
      arr.push([
        signature.owner,
        moment(signature.createdAt).format('DD/MM/YYYY'),
        signature.sentBy,
        signature.status,
        signature.title,
        recipients.join(' - ')
      ])
    }
    setCsvData(arr)
  }

  const handleRefreshStatuses = async () => {

    const promises = []
    // refresh status for signatures with SIGNING_PENDING, IN_PROGRESS or SENT status
    for (let signature of signatures) {
      if (signature.status === 'SIGNING_PENDING' || signature.status === 'IN_PROGRESS' || signature.status === 'SENT') {
        promises.push(refreshStatus({ signature, setRefreshing: () => {}}))
      }
    }
    if(promises.length === 0) {
      setNotification({
        type: 'info',
        msg: 'Aucune signature à mettre à jour. Toutes les signatures sont déjà signées ou annulées.'
      })
      return
    }
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText("Mise à jour des statuts en cours...")
    await Promise.all(promises)
    setGlobalResponseLoaderText('')
    setShowGlobalResponseLoader(false)
  }

  if (siteConfigsLoaded && !canUseSignatures) {
    return (
      <MainLayout className="page-signatures" pageTitle="Signatures" hideTitleOnDesktop={true} fullHeader={true} titleIcon={<Signature />} showSearch={true} searchIn="signatures">
        <ResourceNotFound title="Signatures non disponibles" />
        {showSignaturesNotAvailableModal && (
          <Modal onClose={handleCloseSignaturesNotAvailableModal}>
            <SignaturesNotAvailable store={isNotSQHorCAIpartner ? urlStore : null} />
          </Modal>
        )}
      </MainLayout>
    )
  }

  return (
    <MainLayout className="page-signatures" pageTitle="Signatures" hideTitleOnDesktop={true} fullHeader={true} titleIcon={<Signature />} showSearch={true} searchIn="signatures">
      <div className={`signatures-content ${showStatusPanel ? 'panel-opened' : ''}`}>
        <SignaturesContentSidebar
          onSetSelectedFilters={setSignaturesSelectedFilters}
          selectedFilters={signaturesSelectedFilters}
          onSort={handleSort}
          activeSort={activeSort}
          activeOrder={activeOrder}
          onRefreshStatuses={handleRefreshStatuses}
          />
        <div className="signatures-content__main">
          {/* {selectedSignatures.length > 0 && (
            <div className="signatures-content__main_actions">
              <SignaturesActions 
                selectedSignatures={selectedSignatures} 
                onSetSelectedSignatures={setSelectedSignatures} 
                onSetAllChecked={setAllChecked} 
              />
            </div>
          )} */}
          {/* <div className="signatures-content__main_mobile">
            <div className="filters-item">
              <p><SlidersH /> Filtres</p>
            </div>
          </div> */}

          {/* USE THIS ONLY WHENY EXPORTING SIGNATURES FOR AC3 - not for production, staging or development, only for exporting */}
          {/* <button onClick={handleExportSignatures}>get signatures</button>
          <CSVLink data={csvData}>export signatures</CSVLink> */}

          {!ready && (
            <div className="loader-wrapper">
              <div className="loader-v2"></div>
            </div>
          )}

          {ready && (
            <SignaturesTable
              // selectedSignatures={selectedSignatures}
              // onSetSelectedSignatures={setSelectedSignatures}
              // allChecked={allChecked}
              // onSetAllChecked={setAllChecked}
              filteredSignatures={filteredSignatures}
              
              onOpenStatusPanel={handleOpenStatusPanel}
              onOpenSignatureDetail={handleOpenDetail}
              onTitleClick={handleSignatureTitleClick}
              onDownload={handleSignatureDownload}
            />
          )}
          {ready && <div className='load-more-wrapper'>
            <button className="btn btn-primary" onClick={fetchMoreSignatures}>Charger plus</button>
          </div>}

        </div>
        <SignaturesStatusPanel onClose={handleCloseStatusPanel} loading={singleStatusLoading} signature={activeSignature} />

        {Boolean(currentSignature) && (
          <SingleDocumentSignVialink
            onClose={handleCloseDetail}
            documentName={currentSignature.document.name}
            docId={currentSignature.document.id}
            docAttachments={currentSignature.document.attachments}
            docSignatureRecipients={[]}
            singleDoc={{
              ...currentSignature.document,
              name: currentSignature.document.name,
              values: currentSignature.document.values,
              custom_cover: currentSignature.document.custom_cover,
              id: currentSignature.document.id,
            }}
            // docContacts={docContacts}
            template={currentSignature.template}
            signatureObject={currentSignature}
          />
        )}
      </div>

      {showPreviewDocModal && (
        <SignaturePreviewModal 
          onClose={handleClosePreviewModal}
          previewError={previewError}
          previewLoading={previewLoading}
          iframeSrc={iframeSrc}
        />
      )}

      {/* {showSignatureButtonsModal && (
        <SignatureButtonsModal 
          onClose={handleCloseSignatureButtonsModal}
          signature={signatureToUseInButtonsModal}
          onPreview={handlePreviewFromButtonsModal}
        />
      )} */}
    </MainLayout>
  )
}

export default Signatures 