import { useState } from 'react'

import { History, Eye } from '../../assets/icons'

const HistoryBox = ({ time, progress, numberOfEntries, onRestoreClick, inputsData, type = 'document', description }) => {
  const [showBody, setShowBody] = useState(false)

  let content = (
    <div className="history-box-v2">
      <header>
        <div className="history-box-v2__time">{time}</div>
        <div className="history-box-v2__progress">
          <p className="main">Progression: {(progress * 100).toFixed(0)}%</p>
          <div className="progress-bar"><div style={{width: `${progress * 100}%`}}></div></div>
          <p className="number-of-entries">{numberOfEntries} données saisies</p>
        </div>
        <div className="history-box-v2__actions">
          <button className="btn btn--with-icon" onClick={() => setShowBody(!showBody)}><Eye /> Aperçu</button>
          <button className="btn btn--with-icon" onClick={onRestoreClick}><History /> Restaurer</button>
        </div>
      </header>
      {showBody && 
        <div className="history-box-v2__body">
          {inputsData.map((item, index) => {
            return (
              <div className="group" key={index}>
                <p className="label">{item.label}</p>
                <p className="input">{Array.isArray(item.input) ? null : item.input}</p>
              </div>
            )
          })}
        </div>
      }
    </div>
  )

  if(type === 'template') {
    content = (
      <div className="history-box-v2 history-box-v2--template">
        <header>
          <div className="history-box-v2__time">{time}</div>
          <div className="history-box-v2__progress">
            <p className="main">{description}</p>
          </div>
        </header>
      </div>
    )
  }

  return content
}

export default HistoryBox