import { areSectionConditionsMet } from '../../utils'
import { SingleDocumentVariablesSectionContent } from './'

const SingleDocumentVariablesSection = ({
    type,
    content,
    items,
    variable,
    variables,
    data_type,
    question,
    options,
    tooltip,
    condition,
    conditions,
    values,
    onValuesChange,
    index,
    varIndex,
    head,
    row,
    repeatableRowId,
    rows,
    onAgencyModalOpen,
    onNotariesModalOpen,
    linesAdded,
    documentCheckboxValues,
    onCheckboxValueChange,
    locked,
    rowsLocked,

    isContentEditable,
    onSectionContentChanged,
    onSetDocumentAttachments,
    documentAttachments,
    defaultAttachments,

    repeatableIds,
    repetitionIndices,
    sectionIdx
}) => {
    let classes = 'variables-v2 opened'

    const shouldRender = areSectionConditionsMet({ condition, conditions, repeatableIds, repetitionIndices }, values)

    return shouldRender && (
        <div
            className={classes}
            data-index={`section-${index}`}
            style={{ whiteSpace: 'pre-line' }}
        >
            <div className="variables-v2__body">
                    <SingleDocumentVariablesSectionContent
                        values={values}
                        onValuesChange={onValuesChange}
                        documentCheckboxValues={documentCheckboxValues}
                        onCheckboxValueChange={onCheckboxValueChange}
                        type={type}
                        content={content}
                        bulletListItems={items}
                        question={question}
                        variable={variable}
                        variables={variables}
                        data_type={data_type}
                        options={options}
                        condition={condition}
                        conditions={conditions}
                        tooltip={tooltip}
                        index={index}
                        varIndex={varIndex}
                        repeatableIds={repeatableIds}
                        repetitionIndices={repetitionIndices}
                        head={head}
                        row={row}
                        repeatableRowId={repeatableRowId}
                        rows={rows}
                        onAgencyModalOpen={onAgencyModalOpen}
                        onNotariesModalOpen={onNotariesModalOpen}
                        linesAdded={linesAdded}
                        locked={locked}
                        rowsLocked={rowsLocked}
                        isContentEditable={isContentEditable}
                        onSectionContentChanged={onSectionContentChanged}
                        onSetDocumentAttachments={onSetDocumentAttachments}
                        sectionIdx={sectionIdx}

                        documentAttachments={documentAttachments}
                        defaultAttachments={defaultAttachments}

                    />
                {/* )} */}
            </div>
        </div>
    )
}

export default SingleDocumentVariablesSection
