import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs'
import { isIOS } from 'react-device-detect'
import { saveAs } from 'file-saver'
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument } from 'pdf-lib'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import PublishIcon from '@material-ui/icons/Publish';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InfoIcon from '@material-ui/icons/InfoOutlined'
import CachedIcon from '@material-ui/icons/CachedOutlined'
import { ClickAwayListener } from '@material-ui/core'
import { Notes } from '@material-ui/icons'

import Button from '../UI/Button';
import Input from '../UI/Input';
import IconButton from '../UI/IconButton';
import DocumentDetailSidePanelSmall from '../sections/document-detail/DocumentDetailSidePanelSmall';
import DocumentDetailSidePanel from '../sections/document-detail/DocumentDetailSidePanel';
import DocumentDetailVariables from '../sections/document-detail/DocumentDetailVariables';
// import DocumentDetailSPVariables from '../sections/document-detail/DocumentDetailSPVariables';
import DocumentDetailSPVariablesCopy from '../sections/document-detail/DocumentDetailSPVariablesCopy';
import DocumentDetailAppendices from '../sections/document-detail/DocumentDetailAppendices';
import DocumentDetailSPAppendices from '../sections/document-detail/DocumentDetailSpAppendices';
import SendByEmailModal from '../sections/document-detail/SendByEmailModal';
import SynthesisModal from '../sections/document-detail/SynthesisModal';
import HistoryModal from '../sections/document-detail/HistoryModal';
import TemplateHistoryModal from '../sections/document-detail/TemplateHistoryModal';
import Modal from '../UI/Modal';
import { LoaderContext } from '../../context/loader/loaderState';
import LineLoader from '../UI/LineLoader';
import { generate_document } from '../../services/lawstudioApi';
import { DocumentsContext } from '../../context/documents/documentsState'
import { SignatureContext } from '../../context/signatures/signaturesState';
import { update_document, create_document, fetch_document_history, upload_file, fetch_document_data, push_to_immocloud } from '../../services/firestore';
import DocumentDetailSkeleton from '../sections/document-detail/DocumentDetailSkeleton';
import SignDocumentModal from '../sections/document-detail/SignDocumentModal';
import Alert from '../UI/Alert';
import CustomSelect from '../UI/CustomSelect';
import Tooltip from '../UI/Tooltip';
import MoreActions from '../sections/document-detail/MoreActions';
import CustomPopover from '../UI/CustomPopover';
import CustomTooltip from '../UI/CustomTooltip';
import Loader from '../UI/Loader';
import { UserContext } from '../../context/user/userState'
import { areSectionConditionsMet, convertToTemplateObjWithUniqueVarIndexes, signatureAvailable, EVENT_TYPES, isCoverPageVariable, base64toBlob, coverPageConfig, isMlsPartner, insertFormulaResults, getTemplateFormulas } from '../../utils';
import { CONTACT_DATA_KEYS, DOCUMENT_LIFESPAN_MILLISECONDS, DOC_CONTACTS_KEYS, TEXT_LINES, TEXTAREA_LINES, DATE_LINES, NUMBER_LINES } from '../../constants';
import config from '../../config.json'
import { log_event } from '../../services/analytics';
import axios from 'axios';
import immoDocsLogo from '../../assets/images/immodocs-logo.png'
import ResponseLoader from '../UI/ResponseLoader'
import InjectModal from '../sections/document-detail/InjectModal'
import AgencyModal from '../sections/document-detail/AgencyModal'
import NotariesModal from '../sections/document-detail/NotariesModal'
import AddEditNotary from '../sections/document-detail/AddEditNotary'

const excludedProgressVars = [
  ...CONTACT_DATA_KEYS.mandat_seller_associated, 
  ...CONTACT_DATA_KEYS.compromis_buyer_associated, 
  ...CONTACT_DATA_KEYS.compromis_seller_associated
]

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let savedTimeout = null;
let scrollToItemTimeout = null;

const DocumentDetail = ({ token }) => {
  const statuses = [
    'ready',
    'draft',
  ]
  const statusLabels = { // todo localize
    'ready': "Complété",
    'draft': "Brouillon",
    'in-progress': "Cérémonie en cours",
    'closed': "Cérémonie clôturée",
    'expired': "Cérémonie expirée",
    'cancelled': "Cérémonie annulée"
  }
  const history = useHistory();
  const { documents, templates, fetchDocuments, setActiveVarsSection, activeVarsSection, documentsLoaded, templatesLoaded, getTemplateById, getSingleTemplate } = useContext(DocumentsContext)
  const [sidePanelOpened, setSidePanelOpened] = useState(true);
  const [view, setView] = useState('variables');
  const [showModal, setShowModal] = useState(false);
  const [showSynthesisModal, setShowSynthesisModal] = useState(false);
  const [historyModal, setHistoryModal] = useState({
    isOpen: false,
    isLoading: false,
    history: {}
  });
  const [templateHistoryModal, setTemplateHistoryModal] = useState({
    isOpen: false,
    isLoading: true,
    history: []
  });
  const [documentName, setDocumentName] = useState('');
  const [documentObject, setDocumentObject] = useState(null)
  const [documentId, setDocumentId] = useState('')
  const [templateId, setTemplateId] = useState('')
  const [templateObject, setTemplateObject] = useState(null)
  const [documentValues, setDocumentValues] = useState({})
  const [documentStatus, setDocumentStatus] = useState('draft')
  const [documentAttachments, setDocumentAttachments] = useState([])
  const { setLoading, loading } = useContext(LoaderContext);
  const [variablesSections, setVariablesSections] = useState([]);
  const [varsUpdated, setVarsUpdated] = useState(false);
  const [totalProgress, setTotalProgress] = useState(0);
  const [previewDocumentModal, setPreviewDocumentModal] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');
  const bodyWrapEl = useRef();
  const [downloadPopoverAnchor, setDownloadPopoverAnchor] = useState(null)
  const [showSignDocModal, setShowSignDocModal] = useState(false);
  const [showDocDeleteAlert, setShowDocDeleteAlert] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [statusesOptions, setStatusesOptions] = useState(statuses.map((s) => ({ value: s, label: statusLabels[s], active: s === 'draft' })));
  const [showDownloadPopover, setShowDownloadPopover] = useState(false);
  const { signatures } = useContext(SignatureContext);
  const scrollingIndicator = useRef();
  const [activeVariable, setActiveVariable] = useState('');
  const { user, agency, setContacts, immocloudConfig, partner, siteConfigsLoaded, showSignatures } = useContext(UserContext)
  const [scrollingPosition, setScrollingPosition] = useState(0);
  const [pdfPreviewData, setPdfPreviewData] = useState(null);
  const [prevScrollPosition, setPrevScrollPosition] = useState(window.scrollY);
  const [docPages, setDocPages] = useState([]);
  const [variableEls, setVariableEls] = useState(null);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [updateVariableEls, setUpdateVariableEls] = useState(false);
  const [templateObjectWithUniqueVarIndexes, setTemplateObjectWithUniqueVarIndexes] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [scrollingToItem, setScrollingToItem] = useState(false);
  const [documentContacts, setDocumentContacts] = useState([])
  const [useCustomCover, setUseCustomCover] = useState(false)
  const [showDownloadDocxPopup, setShowDownloadDocxPopup] = useState(false)
  const [templateLoaded, setTemplateLoaded] = useState(false)
  const [loadingPreview, setLoadingPreview] = useState(false)
  const [showInjectModal, setShowInjectModal] = useState(false)
  const [showAgencyModal, setShowAgencyModal] = useState(false)
  const [showNotariesModal, setShowNotariesModal] = useState(false)
  const [showAddEditNotaryModal, setShowAddEditNotaryModal] = useState(false)
  const [notaryMode, setNotaryMode] = useState('add')
  const [selectedNotary, setSelectedNotary] = useState(null)
  const [linesAdded, setLinesAdded] = useState(false)
  const [linesAddedTo, setLinesAddedTo] = useState([])
  const [documentCheckboxValues, setDocumentCheckboxValues] = useState({})

  // useEffect(() => {
  //   console.log("values")
  //   console.log(JSON.stringify(documentValues, null, 2))
  // }, [documentValues])

  // Cleanup
  useEffect(() => {

    return () => {
      if(savedTimeout) {
        clearTimeout(savedTimeout);
      }
      if(scrollToItemTimeout) {
        clearTimeout(scrollToItemTimeout);
      }
    }
  }, []);

  // Add scroll event
  useEffect(() => {
    window.addEventListener('scroll', detectElementScrollPosition);
    if(bodyWrapEl.current && !variableEls) {
      const docVariableEls = bodyWrapEl.current.querySelectorAll('.doc-variable-el');
      setVariableEls(docVariableEls);
    }

    return () => window.removeEventListener('scroll', detectElementScrollPosition);
    // eslint-disable-next-line
  }, [bodyWrapEl.current, variableEls]);

  // Update variable elements when condition is met / when multiselect head is clicked
  useEffect(() => {
    async function updateEls() {
      const docVariableEls = await bodyWrapEl.current.querySelectorAll('.doc-variable-el');
      setVariableEls(docVariableEls);
      setUpdateVariableEls(false);
    }
    if(updateVariableEls && bodyWrapEl.current) {
      updateEls();
    }
  }, [updateVariableEls]);

  // Set window.scrollY to scrollingPosition
  const detectElementScrollPosition = (e) => {
    if(!bodyWrapEl.current) {
      return
    }
    const scrollYPos = window.scrollY;
    setScrollingPosition(scrollYPos);
  }

  useEffect(() => {
    // TODO - Improve scrolling
    // if(variableEls) {
    //   for(let i = 0; i < variableEls.length; i++) {
    //     const dataId = variableEls[i].getAttribute('data-id');
    //     const bounding = variableEls[i].getBoundingClientRect();

    //     // if on same position from top continue to another element
    //     if(i > 0 && (bounding.top > variableEls[i - 1].getBoundingClientRect().top - 5 && bounding.top < variableEls[i - 1].getBoundingClientRect().top + 5)) continue;

    //     if (scrollingPosition >= bounding.top + window.pageYOffset - 100 && scrollingPosition <= bounding.top + window.pageYOffset + bounding.height - 100) {
    //       if(activeVariable !== dataId) {
    //         setActiveVariable(dataId);
    //         break;
    //       }
    //     }
    //   }
    // }

    if(scrollingPosition > 100) {
      setShowBackToTop(true);
    }else {
      setShowBackToTop(false);
    }

    const pageHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    scrollingIndicator.current.style.width = `${(scrollingPosition / pageHeight) * 100}%`;

    // eslint-disable-next-line
  }, [scrollingPosition]);

  const setupDocument = async () => {
    // get document id from path
    let path = history.location.pathname.substring(1, history.location.pathname.length)

    let components = path.split('/')
    if(components.length === 1 || components[0] === 'template' || components[components.length - 1] === '') {
      if(Object.keys(templates).length === 0) {
        return
      }
      if(templateObject) {
        // template already set up
        let templateValues = history.location.state?.tmplValues
        if(templateValues) {
          // do not return if template values are set
        }else {
          return
        }
      }

      let templateId
      let templateValues
      if(components[0] === 'template') {
        // opened using template id
        templateId = components[1]
        templateValues = history.location.state?.tmplValues
      } else {
        // no id
        // get template id
        if(!history.location.state) {
          // no template id, go back I guess
          return
        }
        templateId = history.location.state.templateId
      }
      if(!templateId) {
        // no template id, go back I guess
        return
      }
      let template = templates[templateId]
      if(!template) {
        // templates not loaded
        alert("Template with specified templateId doesn't exist")
        history.push('/templates')
        return
      }

      if(!template.sections) {
        template = await getSingleTemplate(templateId, true)
      }

      let prefillValues
      const queryStringVariables = qs.parse(history.location.search, { ignoreQueryPrefix: true })
      if(queryStringVariables.data_id) {
        // fetch data object
        let data = await fetch_document_data(queryStringVariables.data_id)
        if(!data.error) {
          extractCustomerData(data)
          prefillValues = data
        } else {
          // todo display error
        }
      } else {
        prefillValues = processedQueryVariables(template, queryStringVariables)
      }
      prefillValuesWithAdminData(prefillValues)

      setDocumentName(template.name)
      setTemplateId(templateId)
      // setTemplateObject(template)
      template = templateWithUpdatedEmptyLinesVariables(template)
      setTemplateObject(template)
      setDocumentObject({})
      setDocumentAttachments([])
      if(templateValues) {
        setDocumentValues({...prefillValues, ...templateValues})
      }else {
        setDocumentValues(prefillValues)
      }
      const copyOfTemplate = convertToTemplateObjWithUniqueVarIndexes({...template});
      setTemplateObjectWithUniqueVarIndexes(copyOfTemplate);
      setTemplateLoaded(true)
      setTemplateHistoryModal({ ...templateHistoryModal, history: template.versionNotes || [] })

    } else {
      if(Object.keys(documents).length === 0) {
        return
      }
      if(Object.keys(templates).length === 0) {
        return
      }
      // document id in path, display its data
      let docId = components[components.length - 1]
      let docObject = documents[docId]
      if(!docObject) {
        // documents not loaded
        return
      }
      let template = templates[docObject.template]
      if(!template) {
        // templates not loaded
        alert("Template with specified templateId doesn't exist")
        history.push('/templates')
        return
      }

      if(!template.sections) {
        template = await getSingleTemplate(docObject.template, true)
      }

      const queryStringVariables = qs.parse(history.location.search, { ignoreQueryPrefix: true })

      let prefillValues
      if(queryStringVariables.data_id) {
        // fetch data object
        let data = await fetch_document_data(queryStringVariables.data_id)
        console.log('doc data**', data)

        if(!data.error) {
          prefillValues = data
        } else {
          // todo display error
        }
      } else {
        prefillValues = processedQueryVariables(template, queryStringVariables)
      }
      prefillValuesWithAdminData(prefillValues)
      let existingDocumentValuesWithPrefillData = joinValues(docObject.values || {}, prefillValues)
      extractCustomerData(existingDocumentValuesWithPrefillData)
      setDocumentId(docId)
      setDocumentObject(docObject)
      setDocumentValues(existingDocumentValuesWithPrefillData)
      setDocumentCheckboxValues(docObject.checkboxValues || {})
      setDocumentName(docObject.name || '')
      setTemplateId(docObject.template)
      // setTemplateObject(template)
      template = templateWithUpdatedEmptyLinesVariables(template)
      setTemplateObject(template)
      setDocumentStatus(docObject.status || 'draft')
      setDocumentAttachments(docObject.attachments || [])
      setUseCustomCover(docObject.custom_cover || false)
      if(docObject.status !== 'draft') {
        setStatusesOptions(statuses.map((s) => ({ value: s, label: statusLabels[s], active: s === docObject.status })));
      }

      const copyOfTemplate = convertToTemplateObjWithUniqueVarIndexes({...template});
      setTemplateObjectWithUniqueVarIndexes(copyOfTemplate);
      getCustomersFromData(docObject.values)
      setTemplateHistoryModal({ ...templateHistoryModal, history: template.versionNotes || [] })
    }
  }

  const templateWithUpdatedEmptyLinesVariables = (t) => {
    // console.log('update template', JSON.stringify(t, null, 2))
    // let temp = {...t}
    let temp = JSON.parse(JSON.stringify(t))
    let linesIndex = 0
    for(let i in temp.sections) {
      // let section = template.sections[i]
      let { section, index } = sectionWithUpdatedEmptyLinesVariables(temp.sections[i], linesIndex)
      temp.sections[i] = { ...section}
      linesIndex = index
    }
    // console.log(temp)
    return temp
  }

  const sectionWithUpdatedEmptyLinesVariables = (sec, index) => {
    // console.log('update section', sec, 'with index', index)
    if(sec.items) {
      for(let i in sec.items) {
        let { section: item, index: lineIndex } = sectionWithUpdatedEmptyLinesVariables(sec.items[i], index)
        sec.items[i] = item
        index = lineIndex
      }
    } else if(sec.variables) {
      for(let i in sec.variables) {
        if(sec.variables[i].type === 'empty_lines') {
          sec.content = sec.content.replace(`{d.${sec.variables[i].variable}}`, `{d.${sec.variables[i].variable}_${index}}`)
          // console.log('updating empty lines', `${sec.variables[i].variable}`)
          sec.variables[i].variable = `${sec.variables[i].variable}_${index}`
          // console.log(`${sec.variables[i].variable}_${index}`)
          // console.log(index)
          index++
        }
      }
    } else if(sec.variable && sec.data_type === 'empty_lines') {
      // console.log('updating empty lines',  `${sec.variable}`)
      sec.variable = `${sec.variable}_${index}`
      // console.log(`${sec.variable}_${index}`)
      // console.log(index)
      index++
    }
    return { section: sec, index }
  }

  useEffect(() => { 
    setupDocument()

    // eslint-disable-next-line
  }, [documents, templates]);

  useEffect(() => {
    if(history.location.state && history.location.state.refreshTemplate) {
      setDocumentId('')
      setupDocument()
    }

    // eslint-disable-next-line
  }, [history.location])

  const processedQueryVariables = (tempData, values) => {
    // let variables = allVariables(tempData)
    let processedValues = {}
    for(let key in values) {
      if(key === 'token') {
        continue
      }
      processedValues[key] = values[key]
      // let processed = false;
      // for(let i in variables) {
      //   if(variables[i].variable === key && variables[i].type === 'date') {
      //     console.log('process date', key, values[key])
      //     processedValues[key] = moment(values[key], 'DD/MM/YYYY').format('DD/MM/YYYY')       
      //     processed = true;
      //     break
      //   }
      // }
      // if(!processed) {
      // }
    }
    return processedValues
  }

  const dataKeyArrayForType = (type) => {
    return CONTACT_DATA_KEYS[type]
  }

  const extractCustomerData = (data) => {
    let types = Object.keys(CONTACT_DATA_KEYS)
    let contacts = {}
    for(let t in types) {
      let type = types[t]
      contacts[type] = []
      let sourceKeys = dataKeyArrayForType(type)
      for(let key in data) {
        let isCustomerKey = false
        let keyWithoutIndex
        let components = key.split('_')
        let customerIndex = components[components.length - 1]
        if(isNaN(customerIndex)) {
          keyWithoutIndex = key
        } else {
          components.pop()
          keyWithoutIndex = components.join('_')
          customerIndex--
        }

        for(let i in sourceKeys) {
          if(keyWithoutIndex === sourceKeys[i]) {
            isCustomerKey = true
            break
          }
        }
        if(isCustomerKey) {
          if(!contacts[type][customerIndex]) {
            contacts[type][customerIndex] = {}
          }
          contacts[type][customerIndex][keyWithoutIndex] = data[key]
        }
      }
    }
    setContacts(contacts)
  }
  
  const getCustomersFromData = (data) => {
    if(!data) return 
    let contacts_keys = []
    let dataClone = {...data}
    for(let key in dataClone) {
      const keyArr = key.split('_')
      let keyClone 
      if(isNaN(keyArr[keyArr.length - 1])){
        keyClone = key
      }else {
        keyArr.pop()
        keyClone = keyArr.join('_')
      }
      if(DOC_CONTACTS_KEYS.includes(keyClone)) {
        contacts_keys.push(key)
      }
    }
    let contacts = []
    contacts_keys.forEach((key, idx) => {
      const keyArr = key.split('_')
      let keyWithoutPropName = getKeyWithoutPropName(keyArr)
      let keyName = getKeyName(key)
      if(idx === 0) {
        contacts[0] = {
          [keyName]: dataClone[key],
          belongs_to: keyWithoutPropName
        }
      }else {
        let objFound = contacts.find(c => c.belongs_to === keyWithoutPropName)
        if(contacts.find(c => c.belongs_to === keyWithoutPropName)) {
          objFound[keyName] = dataClone[key]
        }else {
          contacts.push({ [keyName]: dataClone[key], belongs_to: keyWithoutPropName })
        }
      }
    })
    setDocumentContacts(contacts)
  }

  const getKeyName = (key) => {
    let names = ['firstname', 'lastname', 'telephone', 'email_address']
    let keyName = ''
    for(let i = 0; i < names.length; i++) {
      if(key.includes(names[i])) {
        keyName = names[i]
        break
      }
    }
    // if(key.includes('firstname')) keyName = 'firstname'
    // else if(key.includes('lastname')) keyName = 'lastname'
    // else if(key.includes('telephone')) keyName = 'telephone'
    // else if(key.includes('email_address')) keyName = 'email_address'
    return keyName
  }

  const getKeyWithoutPropName = (arr) => {
    let names = ['firstname', 'lastname', 'telephone', 'email']
    let filtered = []
    for(let i = 0; i < names.length; i++) {
      if(arr.includes(names[i])) {
        if(names[i] === 'email') {
          filtered = arr.filter(item => item !== 'email' && item !== 'address')
        }else {
          filtered = arr.filter(item => item !== names[i])
        }
        break
      }
    }
    // if(arr.includes('firstname')) filtered = arr.filter(item => item !== 'firstname')
    // else if(arr.includes('lastname')) filtered = arr.filter(item => item !== 'lastname')
    // else if(arr.includes('telephone')) filtered = arr.filter(item => item !== 'telephone')
    // else if(arr.includes('email')) filtered = arr.filter(item => item !== 'email' && item !== 'address')
    return filtered.length > 1 ? filtered.join('_') : filtered.join('')
  }

  const prefillValuesWithAdminData = (values) => {
    if(!user || !user.manufacturer) {
      return
    }

    const variablesToPrefill = [
      { variable_name: 'adr_caisse', value: user.manufacturer.adr_caisse || '' },
      { variable_name: 'adr_caisse_gestion', value: user.manufacturer.adr_caisse_gestion || '' },
      { variable_name: 'adr_caisse_syndic', value: user.manufacturer.adr_caisse_syndic || '' },
      { variable_name: 'adr_prefecture_carte_pro', value: user.manufacturer.adr_prefecture_carte_pro || '' },
      { variable_name: 'adr_prefecture_carte_pro_gestion', value: user.manufacturer.adr_prefecture_carte_pro_gestion || '' },
      { variable_name: 'adr_prefecture_carte_pro_syndic', value: user.manufacturer.adr_prefecture_carte_pro_syndic || '' },
      { variable_name: 'adresse', value: user.manufacturer.adresse || '' },
      { variable_name: 'adresse_siege', value: user.manufacturer.adresse_siege || '' },
      { variable_name: 'banque_sequestre', value: user.manufacturer.banque_sequestre || '' },
      { variable_name: 'caisse', value: user.manufacturer.caisse || '' },
      { variable_name: 'caisse_gestion', value: user.manufacturer.caisse_gestion || '' },
      { variable_name: 'caisse_syndic', value: user.manufacturer.caisse_syndic || '' },
      { variable_name: 'capital', value: user.manufacturer.capital || '' },
      { variable_name: 'city', value: user.manufacturer.city || '' },
      { variable_name: 'code_ape', value: user.manufacturer.code_ape || '' },
      { variable_name: 'compte_sequestre', value: user.manufacturer.compte_sequestre || '' },
      { variable_name: 'cp_prefecture_carte_pro', value: user.manufacturer.cp_prefecture_carte_pro || '' },
      { variable_name: 'cp_prefecture_carte_pro_gestion', value: user.manufacturer.cp_prefecture_carte_pro_gestion || '' },
      { variable_name: 'cp_prefecture_carte_pro_syndic', value: user.manufacturer.cp_prefecture_carte_pro_syndic || '' },
      { variable_name: 'cp_siege', value: user.manufacturer.cp_siege || '' },
      { variable_name: 'date_de_creation', value: user.manufacturer.date_de_creation || '' },
      { variable_name: 'date_de_creation_gestion', value: user.manufacturer.date_de_creation_gestion || '' },
      { variable_name: 'date_de_creation_syndic', value: user.manufacturer.date_de_creation_syndic || '' },
      { variable_name: 'delivree_par_prefecture', value: user.manufacturer.delivree_par_prefecture || '' },
      { variable_name: 'delivree_par_prefecture_gestion', value: user.manufacturer.delivree_par_prefecture_gestion || '' },
      { variable_name: 'delivree_par_prefecture_syndic', value: user.manufacturer.delivree_par_prefecture_syndic || '' },
      { variable_name: 'detenteur_carte_pro', value: user.manufacturer.detenteur_carte_pro || '' },
      { variable_name: 'detenteur_carte_pro_gestion', value: user.manufacturer.detenteur_carte_pro_gestion || '' },
      { variable_name: 'detenteur_carte_pro_syndic', value: user.manufacturer.detenteur_carte_pro_syndic || '' },
      { variable_name: 'detention_fonds', value: user.manufacturer.detention_fonds || '' },
      { variable_name: 'dpo', value: user.manufacturer.dpo || '' },
      { variable_name: 'email', value: user.manufacturer.email || '' },
      { variable_name: 'email_location', value: user.manufacturer.email_location || '' },
      { variable_name: 'fax', value: user.manufacturer.fax || '' },
      { variable_name: 'filiale', value: user.manufacturer.filiale || '' },
      { variable_name: 'forme', value: user.manufacturer.forme || '' },
      { variable_name: 'holding', value: user.manufacturer.holding || '' },
      { variable_name: 'horaires', value: user.manufacturer.horaires || '' },
      { variable_name: 'jours_horaires', value: user.manufacturer.jours_horaires || '' },
      { variable_name: 'last_modified', value: user.manufacturer.last_modified || '' },
      { variable_name: 'latitude', value: user.manufacturer.latitude || 0 },
      { variable_name: 'legal_mention', value: user.manufacturer.legal_mention || '' },
      { variable_name: 'longitude', value: user.manufacturer.longitude || 0 },
      { variable_name: 'manufacturers_contact_email', value: user.manufacturer.manufacturers_contact_email || '' },
      { variable_name: 'manufacturers_contact_firstname', value: user.manufacturer.manufacturers_contact_firstname || '' },
      { variable_name: 'manufacturers_contact_name', value: user.manufacturer.manufacturers_contact_name || '' },
      { variable_name: 'manufacturers_contact_telephone', value: user.manufacturer.manufacturers_contact_telephone || '' },
      { variable_name: 'manufacturers_description', value: user.manufacturer.manufacturers_description || '' },
      { variable_name: 'manufacturers_fonction', value: user.manufacturer.manufacturers_fonction || '' },
      { variable_name: 'manufacturers_gender', value: user.manufacturer.manufacturers_gender || '' },
      { variable_name: 'manufacturers_image', value: user.manufacturer.manufacturers_image || '' },
      { variable_name: 'manufacturers_logo', value: user.manufacturer.manufacturers_logo || '' },
      { variable_name: 'manufacturers_name', value: user.manufacturer.manufacturers_name || '' },
      { variable_name: 'manufacturers_url', value: user.manufacturer.manufacturers_url || '' },
      { variable_name: 'manufacturers_url_googleplus', value: user.manufacturer.manufacturers_url_googleplus || '' },
      { variable_name: 'manufacturers_url_instagram', value: user.manufacturer.manufacturers_url_instagram || '' },
      { variable_name: 'manufacturers_url_like', value: user.manufacturer.manufacturers_url_like || '' },
      { variable_name: 'manufacturers_url_linkedin', value: user.manufacturer.manufacturers_url_linkedin || '' },
      { variable_name: 'manufacturers_url_pinterest', value: user.manufacturer.manufacturers_url_pinterest || '' },
      { variable_name: 'manufacturers_url_twitter', value: user.manufacturer.manufacturers_url_twitter || '' },
      { variable_name: 'manufacturers_url_youtube', value: user.manufacturer.manufacturers_url_youtube || '' },
      { variable_name: 'mediateur_adresse', value: user.manufacturer.mediateur_adresse || '' },
      { variable_name: 'mediateur_date_obtention_label', value: user.manufacturer.mediateur_date_obtention_label || '' },
      { variable_name: 'mediateur_nom', value: user.manufacturer.mediateur_nom || '' },
      { variable_name: 'mediateur_site_internet', value: user.manufacturer.mediateur_site_internet || '' },
      { variable_name: 'mention_carte_pro', value: user.manufacturer.mention_carte_pro || '' },
      { variable_name: 'mention_carte_pro_gestion', value: user.manufacturer.mention_carte_pro_gestion || '' },
      { variable_name: 'mention_carte_pro_syndic', value: user.manufacturer.mention_carte_pro_syndic || '' },
      { variable_name: 'montant_caisse', value: user.manufacturer.montant_caisse || '' },
      { variable_name: 'montant_caisse_gestion', value: user.manufacturer.montant_caisse_gestion || '' },
      { variable_name: 'montant_caisse_syndic', value: user.manufacturer.montant_caisse_syndic || '' },
      { variable_name: 'no_caisse', value: user.manufacturer.no_caisse || '' },
      { variable_name: 'no_caisse_gestion', value: user.manufacturer.no_caisse_gestion || '' },
      { variable_name: 'no_caisse_syndic', value: user.manufacturer.no_caisse_syndic || '' },
      { variable_name: 'no_carte_pro', value: user.manufacturer.no_carte_pro || '' },
      { variable_name: 'no_carte_pro_gestion', value: user.manufacturer.no_carte_pro_gestion || '' },
      { variable_name: 'no_carte_pro_syndic', value: user.manufacturer.no_carte_pro_syndic || '' },
      { variable_name: 'no_rc', value: user.manufacturer.no_rc || '' },
      { variable_name: 'nom_caisse_regionale', value: user.manufacturer.nom_caisse_regionale || '' },
      { variable_name: 'numero_orias', value: user.manufacturer.numero_orias || '' },
      { variable_name: 'postal_code', value: user.manufacturer.postal_code || '' },
      { variable_name: 'rcp', value: user.manufacturer.rcp || '' },
      { variable_name: 'rcp_adresse', value: user.manufacturer.rcp_adresse || '' },
      { variable_name: 'rcp_organisme', value: user.manufacturer.rcp_organisme || '' },
      { variable_name: 'rcs', value: user.manufacturer.rcs || '' },
      { variable_name: 'reseau', value: user.manufacturer.reseau || '' },
      { variable_name: 'secteur', value: user.manufacturer.secteur || '' },
      { variable_name: 'statut_name', value: user.manufacturer.statut_name || '' },
      { variable_name: 'telephone', value: user.manufacturer.telephone || '' },
      { variable_name: 'tva_intra', value: user.manufacturer.tva_intra || '' },
      { variable_name: 'type_mandataire', value: user.manufacturer.type_mandataire || '' },
      { variable_name: 'ville_prefecture_carte_pro', value: user.manufacturer.ville_prefecture_carte_pro || '' },
      { variable_name: 'ville_prefecture_carte_pro_gestion', value: user.manufacturer.ville_prefecture_carte_pro_gestion || '' },
      { variable_name: 'ville_prefecture_carte_pro_syndic', value: user.manufacturer.ville_prefecture_carte_pro_syndic || '' },
      { variable_name: 'ville_rcs', value: user.manufacturer.ville_rcs || '' },
      { variable_name: 'ville_siege', value: user.manufacturer.ville_siege || '' }
    ]

    // values.manufacturers_name = user.manufacturer.name || ''
    // values.manufacturers_contact_firstname = user.firstname || ''
    // values.manufacturers_contact_name = user.lastname || ''
    // values.adresse = user.manufacturer.address || ''
    // values.postal_code = user.manufacturer.postal_code || ''
    // values.city = user.manufacturer.city || ''
    // values.telephone = user.manufacturer.phone || ''
    // values.email = user.manufacturer.email || ''
    // values.manufacturers_description = user.manufacturer.description || ''
    // values.admin_fonction = user.fonctions?.length > 0 ? user.fonctions[0].name || '' : ''

    for(let v of variablesToPrefill) {
      values[v.variable_name] = v.value
    }
  }

  const joinValues = (custom, prefill) => {
    let values = {...custom}
    for(let key in prefill) {
      if(!values[key]) {
        values[key] = prefill[key]
      }
    }
    return values
  }

  // useEffect(() => {
  //   if(templateObject && templateObject.sections?.length > 0) {
  //     const sectionsArr = [];
  //     templateObject.sections.map(section => {
  //       const vars = getAllVariablesFromSection(section);
  //       return sectionsArr.push({ id: section.id, name: section.name, progress: 0, variables: vars })
  //     });
  //     setVariablesSections(sectionsArr);
  //   }
  //   // eslint-disable-next-line
  // }, [templateObject]);

  // Update progress when component is mounted
  useEffect(() => {
    // if(templateObject && Object.keys(documentValues).length > 0 && variablesSections.length > 0 && !varsUpdated) {
    //   const varsSections = [...variablesSections];
    //   if(templateObject.template === 'new') {
    //     const arr = [];
    //     [...varsSections].forEach(sec => {
    //       const updatedSectionVars = sec.variables.map(obj => {
    //         if(obj.fields.length > 0) {
    //           const fields = [...obj.fields].map(field => {
    //             return field.name in documentValues ? { ...field, completed: documentValues[field.name] !== '' } : {...field};
    //           });
    //           let completed;
    //           if(documentValues[obj.name] === 'non') {
    //             completed = 100;
    //           }else if(documentValues[obj.name] === '') {
    //             completed = 0;
    //           }else {
    //             const num = fields.reduce((acc, val) => {
    //               if(val.completed) {
    //                 return acc + 100;
    //               }else {
    //                 return acc + 0;
    //               }
    //             }, 0);
    //             completed = Math.floor(((100 + num) / ((obj.fields.length + 1) * 100)) * 100);
    //           }
    //           return obj.name in documentValues ? { ...obj, completed, fields } : {...obj};
    //         }else {
    //           return obj.name in documentValues ? { ...obj, completed: documentValues[obj.name] !== '' ? 100 : 0 } : {...obj};
    //         }
    //       });
    //       sec.variables = updatedSectionVars;
    //       arr.push(sec);
    //     });
    //     const updatedVarsSections = [...arr].map((sec) => {
    //       const completedValues = sec.variables.reduce((accumulator, currentValue) => accumulator + currentValue.completed, 0);
    //       const progress = Math.floor((completedValues / (sec.variables.length * 100)) * 100);
    //       return {...sec, progress}
    //     });
    //     setVariablesSections(updatedVarsSections);
    //     setVarsUpdated(true);
    //     const total = updatedVarsSections.reduce((accumulator, currentValue) => accumulator + currentValue.progress, 0);
    //     setTotalProgress(Math.floor((total / (updatedVarsSections.length * 100)) * 100));
    //   }
    // }
    if(templateObject && Object.keys(documentValues).length > 0) {
      // console.log(progress())
      setTotalProgress(progress())
    }
  }, [documentValues, templateObject]);

  const shareClickHandler = async () => {
    if(!documentId) {
      alert("Le document doit être sauvegardé avant d'être partagé")
      return
    }
    setLoading(true)
    if(!documentObject.documentUrl) {
      await fetchDocumentPreview('share')
    }
    setLoading(false)
    setShowModal(true);
    if(linesAdded) {
      let docValues = clearLinesFromValues()
      setDocumentValues(docValues)
    }
  }

  const synthesisClickHandler = () => {
    let docValues = clearLinesFromValues()
    setDocumentValues(docValues)
    setShowSynthesisModal(true);
  }

  const historyClickHandler = async () => {
    if(linesAdded) {
      let docValues = clearLinesFromValues()
      setDocumentValues(docValues)
    }
    setHistoryModal({
      isOpen: true,
      isLoading: true,
      history: {}
    })
    let history = await fetch_document_history(documentId)
    setHistoryModal({
      isOpen: true,
      isLoading: false,
      history: history
    })
  }

  const hideHistoryModal = () => {
    setHistoryModal({
      isOpen: false,
      isLoading: false,
      history: {}
    })
  }

  const showTemplateHistoryModal = (e) => {
    e.preventDefault()
    setTemplateHistoryModal({
      ...templateHistoryModal,
      isOpen: true
    })
    if(linesAdded) {
      let docValues = clearLinesFromValues()
      setDocumentValues(docValues)
    }
  }

  const hideTemplateHistoryModal = () => {
    setTemplateHistoryModal({
      ...templateHistoryModal,
      isOpen: false,
      isLoading: true,
    })
  }

  const restoreVersion = (version) => {
    setDocumentValues(version.values)
    setDocumentName(version.name)
    hideHistoryModal()
  }

  const documentNameChangeHandler = e => {
    setDocumentName(e.target.value);
  }

  const getBase64FromUrl = async (url) => {
    // const data = await fetch(url);
    const response = await axios.get(url, { responseType: 'arraybuffer' })
    const base64 = Buffer.from(response.data, 'binary').toString('base64')
    return base64
  }

  const fetchDocumentData = async (extension) => {
    const images = documentObject?.images || {}
    const options = {}
    let cpConfig = coverPageConfig(useCustomCover, agency, user, documentName, useCustomCover, partner)
    let values = {...documentValues, ...cpConfig.coverPageVariables}
    const documentFormulas = getTemplateFormulas(templateObject)
    values = insertFormulaResults(values, documentFormulas)
    let doc = await generate_document(templateObject, values, extension, {...images, ...cpConfig.coverPageImages} , {...options, ...cpConfig.coverPageOptions, checkboxValues: documentCheckboxValues })
    if(doc.error) {
      return null
    }
    return doc.data
  }

  const coverChangeHandler = (e) => {
    setUseCustomCover(e.target.checked)
  }

  const mimeTypeForExtension = (extension) => {
    switch(extension) {
      case 'pdf':
        return 'application/pdf'
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      default:
        return ''
    }
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    const arr = [];
    for(let i = 1; i < numPages + 1; i++) {
      arr.push(<Page pageNumber={i} width={870} renderTextLayer={true} loading="" key={i} />);
    }
    setDocPages(arr);
  }


  const eventTypeForAction = (action) => {
    switch(action) {
      case 'preview':
        return EVENT_TYPES.DOCUMENT_PREVIEW
      case 'download':
        return EVENT_TYPES.DOCUMENT_DOWNLOAD
      case 'push_binary':
        return EVENT_TYPES.IMMOCLOUD_UPLOAD
      case 'share':
        return EVENT_TYPES.DOCUMENT_SHARE
      default: 
        return ''
    }
  }

  const fetchDocumentPreview = async (action, extension = 'pdf') => {
    setLoadingPreview(true)
    let events = [eventTypeForAction(action)]
    if(action === 'download') {
      events.push(extension === 'docx' ? EVENT_TYPES.DOCUMENT_DOWNLOAD_DOCX : EVENT_TYPES.DOCUMENT_DOWNLOAD_PDF)
    }
    log_event(events, partner)
    let documentData = await fetchDocumentData(extension)
    setLoadingPreview(false)
    // console.log('document data', documentData)
    if(!documentData) {
      // todo display error?
      return
    }
    // download document
    if(action === 'download') {
      if(isIOS && extension === 'pdf') {
        const blob = base64toBlob(documentData)
        const a = document.createElement('a')
        a.onclick = saveAs(blob, `${documentName || 'document'}.pdf`)
      }else {
        const a = document.createElement("a")
        a.href = `data:${mimeTypeForExtension(extension)};base64,${documentData}` 
        a.download = `${documentName || 'document'}.${extension}`
        a.click()
      }
    } else if(action === 'preview') {
      let base64Data = `data:application/pdf;base64,${documentData}`
      if(partner === 'jurisur' && config.environment === 'development') {
        const pdfDoc = await PDFDocument.load(`data:application/pdf;base64,${documentData}`)
  
        const pages = pdfDoc.getPages()
  
        for(let i = 0; i < pages.length; i++) {
          const page = pages[i]
          const { width, height } = page.getSize()
          const pngUrl = immoDocsLogo
    
          const pngImage = await pdfDoc.embedPng(pngUrl)
    
          page.drawImage(pngImage, {
            x: width / 2 - 50,
            y: height / 2 - 50,
            width: pngImage.width,
            height: pngImage.height,
            opacity: 0.5,
          })
        }
  
        const pdfBytes = await pdfDoc.saveAsBase64()
        base64Data = `data:application/pdf;base64,${pdfBytes}`
      }
      setPdfPreviewData(base64Data);
      setIframeSrc(base64Data);
      // setPdfPreviewData(`data:application/pdf;base64,${documentData}`);
      // setIframeSrc(`data:application/pdf;base64,${documentData}`);
      setPreviewDocumentModal(true);
    } else if(action === 'push_binary') {
      return documentData
    }
    // store the latest generated document in storage. Todo check if values changed
    if(documentId) {
      let uploadResult = await upload_file(documentData, `${documentId}`, extension, mimeTypeForExtension(extension), 'documents')
      if(!uploadResult.url) {
        return
      }
      let documentUpdates = {}   
      documentUpdates[`documentUrls.${extension}`] = uploadResult.url
      await update_document(documentId, documentUpdates, documentAttachments)
    }
  }

  const openDownloadPopover = (event) => {
    setDownloadPopoverAnchor(event.currentTarget);
  };
  
  const closeDownloadPopover = () => {
    setDownloadPopoverAnchor(null);
  };

  // Get field names from type="text" content field
  const getVarKeysFromContent = (text) => {
    let source = text
    let fieldRegex = /\{d\.([^}]*)\}/g
    let fields = text.match(fieldRegex)
    let items = []
    for(let i in fields) {
      let components = source.split(fields[i])
      let handle = fields[i]
      items.push(handle.substring(3, handle.length - 1))
      source = components[1]
    }
    if(source) {
      items.push(source)
    }
    return items
  }

  const activeVariableKeys = () => {
    let templateCopy = {...templateObject}
    let sections = [...templateCopy.sections]
    // console.log(sections)
    if(templateCopy.footer) {
      sections.push({...templateCopy.footer, type: templateCopy.footer.type || 'text'})
    }
    return extractVariablesFromSections(sections, true)
  }

  const allVariables = (tempData, full = true, includeFooter = false) => {
    if(!tempData) {
      return null
    }
    let sections = tempData.sections
    if(includeFooter && tempData.footer) {
      sections.push({...tempData.footer, type: tempData.footer.type || 'text'})
    }
    return extractVariablesFromSections(sections, false, full)
  }

  const extractVariablesFromSections = (sections, active = true, full = false) => {
    let variables = []
    for(let i in sections) {
      let section = sections[i]
      if(active && (section.condition || section.conditions) && !areSectionConditionsMet(section, documentValues)) {
        continue
      }
      if(section.sections) {
        let vars = extractVariablesFromSections(section.sections)
        for(let i in vars) {
          variables.push(vars[i])
        }
      }
      if(section.type === 'question') {
        if(isCoverPageVariable(section.variable)) {
          continue
        }
        if(full) {
          variables.push({ variable: section.variable, type: section.data_type, options: section.options })
        } else {
          variables.push(section.variable)
        }
      } if(section.type === 'text' || section.type === 'heading1' || section.type === 'heading2' || section.type === 'heading3') { // todo make more general
        for(let vi in section.variables) {  
          if(isCoverPageVariable(section.variables[vi].variable)) {
            continue
          }
          if(full) {
            // variables.push(section.variables[vi])
            if(section.repeatable_section_id && documentValues[section.repeatable_section_id]) {
              documentValues[section.repeatable_section_id].forEach((item, index) => {
                let splittedVar = section.variables[vi].variable.split('_')
                let lastEl = splittedVar[splittedVar.length - 1] 
                if(lastEl !== 'id') {
                  variables.push({...section.variables[vi], index, belongs_to: section.repeatable_section_id})
                }
              })
            }else {
              if(section.repeatable_section_id) {
                variables.push({...section.variables[vi], belongs_to: section.repeatable_section_id, index: 0 })
              }else {
                variables.push(section.variables[vi])
              }
            }
          } else {
            if(section.repeatable_section_id && documentValues[section.repeatable_section_id]) {
              documentValues[section.repeatable_section_id].forEach((item, index) => {
                let splittedVar = section.variables[vi].variable.split('_')
                let lastEl = splittedVar[splittedVar.length - 1] 
                if(lastEl !== 'id') {
                  variables.push({variable: section.variables[vi].variable, index, belongs_to: section.repeatable_section_id})
                }
              })
            }else {
              if(section.repeatable_section_id) {
                variables.push({...section.variables[vi].variable, belongs_to: section.repeatable_section_id, index: 0})
              }else {
                variables.push(section.variables[vi].variable)
              }
            }
          }
        }
      } else if(section.type === 'bullet_list') {
        for(let bi in section.items) {
          for(let vi in section.items[bi].variables) {
            if(isCoverPageVariable(section.items[bi].variables[vi].variable)) {
              continue
            }
            if(full) {
              variables.push(section.items[bi].variables[vi])
            } else {
              variables.push(section.items[bi].variables[vi].variable)
            }
          }
        }
      }
    }
    let uniqueVariables = [...new Set(variables)];
    return uniqueVariables
  }

  const extractVariableTypesFromSections = (sections) => {
    let variablesWithTypes = extractVariablesWithTypesFromSections(sections)
    let types = {}
    for(let i in variablesWithTypes) {
      types[variablesWithTypes[i].variable] = variablesWithTypes[i].type
    }
    return types
  }

  const extractVariablesWithTypesFromSections = (sections) => {
    let variablesWithTypes = []
    for(let i in sections) {
      let section = sections[i]
      if(!areSectionConditionsMet(section, documentValues)) {
        continue
      }
      if(section.sections) {
        let vars = extractVariablesWithTypesFromSections(section.sections)
        for(let i in vars) {
          variablesWithTypes.push(vars[i])
        }
      }
      if(section.type === 'question') {
        if(isCoverPageVariable(section.variable)) {
          continue
        }
        variablesWithTypes.push({variable: section.variable, type: section.data_type})
      } if(section.type === 'text' || section.type === 'heading1' || section.type === 'heading2' || section.type === 'heading3') { // todo make more general
        for(let vi in section.variables) {
          if(isCoverPageVariable(section.variables[vi].variable)) {
            continue
          }
          variablesWithTypes.push({variable: section.variables[vi].variable, type: section.variables[vi].type})
        }
      }
    }
    let uniqueVariables = [...new Set(variablesWithTypes)];
    return uniqueVariables
  }

  const progress = () => {
    let p = 0;
    if(!templateObject.sections) {
      return p
    }
    let variableKeys = activeVariableKeys()

    let filterOutAssociatedContactsVariables = []
    for(let i=0; i < variableKeys.length; i++) {
      let varKey = variableKeys[i]
      if(typeof varKey === 'string') {
        if(!excludedProgressVars.includes(varKey)) {
          filterOutAssociatedContactsVariables.push(varKey)
        }
      }else {
        if(!excludedProgressVars.includes(varKey.variable)) {
          filterOutAssociatedContactsVariables.push(varKey)
        }
      }
    }
    
    if(filterOutAssociatedContactsVariables) {
      let totalVariables = variableKeys.length

      for(let i in filterOutAssociatedContactsVariables) {
        if(typeof filterOutAssociatedContactsVariables[i] === 'string') {
          if(!!documentValues[filterOutAssociatedContactsVariables[i]] && !linesAddedTo.find(v => v.variable === filterOutAssociatedContactsVariables[i])) {
            p += 1 / filterOutAssociatedContactsVariables.length
          }
        }else {
          if(documentValues[filterOutAssociatedContactsVariables[i].belongs_to] && !!documentValues[filterOutAssociatedContactsVariables[i].belongs_to][filterOutAssociatedContactsVariables[i].index][filterOutAssociatedContactsVariables[i].variable] && !linesAddedTo.find(v => v.variable === filterOutAssociatedContactsVariables[i].variable)) {
            p += 1 / filterOutAssociatedContactsVariables.length
          }
        }
      }
    }
    return p
  }

  const saveDocument = async () => {
    let docValues = clearLinesFromValues()
    
    setLoading(true)
    setSaving(true)
    let documentUpdates = { values: docValues, checkboxValues: documentCheckboxValues, name: documentName, status: isMlsPartner(partner) ? 'ready' : documentStatus, template: templateId, progress: progress(), folderId: [], custom_cover: useCustomCover }
    if(documentId) {
      await update_document(documentId, documentUpdates, documentAttachments)
      setSaved(true)
      savedTimeout = setTimeout(() => setSaved(false), 2000)
    } else {
      let response = await create_document(documentUpdates, documentAttachments)
      if(response.success) {
        history.replace(`/document-detail/${response.documentId}`)
      }
    }
    setLoading(false)
    setSaving(false)
    setDocumentValues(docValues)
    await fetchDocuments()
    setShowSynthesisModal(false)
  }

  const documentValuesChangeHandler = (variable, value) => {
    if(typeof variable === 'object' && Array.isArray(variable)) {
      let dv = {...documentValues}
      for(let i in variable) {
        dv[variable[i]] = value[i]
      }
      setDocumentValues({...dv})
    } else {
      setDocumentValues({...documentValues, [variable]: value })      
    }
  }

  const documentCheckboxChangeHandler = (id, value) => {
    setDocumentCheckboxValues({
      ...documentCheckboxValues,
      [id]: value
    })
  }

  const timeElapsed = (date) => {
    // todo localize
    let now = Date.now()
    let diff = now - date
    let val = diff / 1000
    if(val < 60) {
      return `${val.toFixed(0)} sec`
    }
    val /= 60
    if(val < 60) {
      return `${val.toFixed(0)} minutes`
    }
    val /= 60
    if(val < 24) {
      return `${val.toFixed(0)} heures`
    }
    val /= 24
    return `${val.toFixed(0)} jour${val === 1 ? '' : 's'}`
  }

  const statusOptions = () => {
    return statuses.map((status, statusIndex) => {
      return (
        <option key={`status_option_${statusIndex}`} value={status}>{ statusLabels[status] }</option>
      )
    })
  }

  const onAddAttachment = (att) => {
    let attachments = documentAttachments
    attachments.push(att)
    setDocumentAttachments([...attachments])
  }

  const onDeleteAttachment = (attIndex) => {
    let attachments = documentAttachments
    attachments.splice(attIndex, 1)
    setDocumentAttachments([...attachments])
  }

  const onDeleteAttachmentsByName = (names) => {
    const attachments = [...documentAttachments];
    const filteredAttachments = attachments.filter(a => !names.includes(`${a.name}.${a.format}`));
    setDocumentAttachments(filteredAttachments);
  }

  const variableItemClickHandler = async (id) => {
    const element = bodyWrapEl.current.querySelector(`[data-id="${id}"]`);
    if(element) {
      const input = element.querySelector('input') || element.querySelector('textarea');
      if(input) {
        input.focus();
      }
      setScrollingToItem(true);
      window.scrollTo({top: element.getBoundingClientRect().top + window.pageYOffset - 100, behavior: "smooth"});
      scrollToItemTimeout = setTimeout(() => setScrollingToItem(false), 1000);
    }
  }
  
  const sectionClickHandler = (id) => {
    const element = bodyWrapEl.current.querySelector(`[data-index="${id}"]`);
    if(element) {
      setScrollingToItem(true);
      window.scrollTo({top: element.getBoundingClientRect().top + window.pageYOffset - 100, behavior: "smooth"});
      scrollToItemTimeout = setTimeout(() => setScrollingToItem(false), 1000);
    }
  }

  const editAttachmentHandler = async (docId, attachmentName, index) => {
    const docAttachments = [...documentAttachments];
    // console.log(docAttachments, index);
    const att = {...[...docAttachments][index]};
    att.name = attachmentName;
    docAttachments[index] = att;
    if(docId) {
      await update_document(docId, { attachments: docAttachments }, docAttachments);
    }
    setDocumentAttachments(docAttachments);
  }

  // Duplicate document
  const duplicateDocumentHandler = async (e) => {
    e.preventDefault();
    setLoading(true)
    let doc = {...documentObject}
    let docCopy = {
      name: `Copie du ${doc.name}`,
      progress: doc.progress,
      status: doc.status,
      template: doc.template,
      values: doc.values
    }
    await create_document(docCopy)
    await fetchDocuments()
    setLoading(false)
    history.push('/documents');
  }

  // Delete document
  const deleteDocumentHandler = async (e) => {
    e.preventDefault();
    setDeleting(true);
    setLoading(true);

    await update_document(documentObject.id, { 
      deleted: true, 
      archived: false
    }, []);
  
    await fetchDocuments();

    setShowDocDeleteAlert(false);
    setDeleting(false);
    setLoading(false);
    history.push('/documents');
  }

  const onAttachmentShareUpdate = (index, share) => {
    let da = documentAttachments
    da[index].share = share
    setDocumentAttachments([...da]);
  }

  const onAttachmentsShareSave = async () => {
    if(documentId) {
      update_document(documentId, { attachments: documentAttachments }, documentAttachments);
    }
  }

  const statusChangeHandler = (status) => {
    setDocumentStatus(status.value);
    setStatusesOptions(prev => {
      const statuses = [...prev];
      const newStatuses = statuses.map((s) => s.value === status.value ? { ...s, active: true } : { ...s, active: false });
      return newStatuses;
    });
  }


  const uploadToImmocloud = async () => {
    setLoading(true)
    const fileBase64 = await fetchDocumentPreview('push_binary')
    console.log({ immocloudConfig })
    const response = await push_to_immocloud(fileBase64, `${documentName}.pdf`, immocloudConfig.site_id, immocloudConfig.handle, immocloudConfig.id)
    if(response.error) {
      let message = ''
      if(response.error.error && response.error.error.product_id) {
        message = 'Aucun bien lié à ce document. Le document doit être créé depuis le logiciel Immofacile'
      } else if(response.error.error) {
        for(let key in response.error.error) {
          if(typeof response.error.error[key] === 'object') {
            for(let i in response.error.error[key]) {
              if(message) {
                message += '\n'
              }
              message += response.error.error[key][i]
            }
          } else if(typeof response.error.error[key] === 'string') {
            if(message) {
              message += '\n'
            }
            message += response.error.error[key]
          }
        }
      }
      alert(message)
    } else {
      alert("Le document a été téléchargé avec succès")
    }
    setLoading(false)
  }

  const immocloudUploadAvailable = () => {
    if(isMlsPartner(partner)) return false
    return true
    // const availablePartners = ['squarehabitat', 'cai']
    // return availablePartners.includes(partner)
  }

  // On open add/edit notary modal
  const openAddEditNotaryModal = (m) => {
    if(linesAdded) {
      let docValues = clearLinesFromValues()
      setDocumentValues(docValues)
    }
    setShowAddEditNotaryModal(true)
    setShowNotariesModal(false)
    setNotaryMode(m)
  }

  // On close add/edit notary modal
  const closeAddEditNotaryModal = () => {
    setShowAddEditNotaryModal(false)
    setShowNotariesModal(true)
    setSelectedNotary(null)
  }

  // Remove/clear values with lines
  const clearLinesFromValues = () => {
    let docValues = {...documentValues}
    if(linesAddedTo.length > 0) { // don't save underscores to db, remove them from values before saving
      linesAddedTo.forEach(v => {
        if(v.belongs_to) {
          if(docValues[v.belongs_to]) {
            let repeatable = JSON.parse(JSON.stringify(docValues[v.belongs_to]))
            if(repeatable[v.index][v.variable]) {
              delete repeatable[v.index][v.variable]
            }
            docValues[v.belongs_to] = repeatable
          }
        }else {
          if(docValues[v.variable]) {
            delete docValues[v.variable]
          }
        }
      })
    }
    setLinesAdded(false)
    setLinesAddedTo([])
    return docValues
  }

  const addLinesAvailable = () => {
    if(isMlsPartner(partner)) return false
    return true
  }

  // Add lines to empty inputs
  const handleAddLinesToEmptyInputs = () => {
    if(linesAdded) {
      let docValues = {...documentValues}
      linesAddedTo.forEach(v => {
        if(v.belongs_to && v.index !== undefined) {
          if(docValues[v.belongs_to] && docValues[v.belongs_to][v.index]) {
            docValues[v.belongs_to][v.index][v.variable] = ''
          }
        }else {
          docValues[v.variable] = ''
        }
      })
      setDocumentValues(docValues)
      setLinesAdded(false)
      setLinesAddedTo([])
      return
    }

    const allVars = allVariables(templateObject)
    const allowed = ['string', 'textarea', 'date', 'number']
    const onlyInputVars = allVars.filter(v => allowed.indexOf(v.type) !== -1)
    // console.log(onlyInputVars.filter(v => v.belongs_to))
    let docValues = {...documentValues}
    let addedTo = []
    
    onlyInputVars.forEach((v) => {
      if(v.belongs_to && v.index !== undefined) {
        // console.log(v.belongs_to)
        addedTo.push(v)
        if(docValues[v.belongs_to]) {
          if(!docValues[v.belongs_to][v.index] || !docValues[v.belongs_to][v.index][v.variable]) {
            if(v.type === 'string') {
              docValues[v.belongs_to][v.index][v.variable] = TEXT_LINES
            }else if(v.type === 'textarea') {
              docValues[v.belongs_to][v.index][v.variable] = TEXTAREA_LINES
            }else if(v.type === 'date') {
              docValues[v.belongs_to][v.index][v.variable] = DATE_LINES
            }else if(v.type === 'number') {
              docValues[v.belongs_to][v.index][v.variable] = NUMBER_LINES
            }
          }
        }else {
          if(v.type === 'string') {
            docValues[v.belongs_to] = [{ [v.variable]: TEXT_LINES }]
          }else if(v.type === 'textarea') {
            docValues[v.belongs_to] = [{ [v.variable]: TEXTAREA_LINES 
            }]
          }else if(v.type === 'date') {
            docValues[v.belongs_to] = [{ [v.variable]: DATE_LINES }]
          }else if(v.type === 'number') {
            docValues[v.belongs_to] = [{ [v.variable]: NUMBER_LINES }]
          }
        }
      }else {
        if(!docValues[v.variable]) {
          addedTo.push(v)
          if(v.type === 'string') {
            docValues[v.variable] = TEXT_LINES
          }else if(v.type === 'textarea') {
            docValues[v.variable] = TEXTAREA_LINES
          }else if(v.type === 'date') {
            docValues[v.variable] = DATE_LINES
          }else if(v.type === 'number') {
            docValues[v.variable] = NUMBER_LINES
          }
        }
      }
    })
    
    // console.log(docValues)
    setDocumentValues(docValues)
    setLinesAdded(true)
    setLinesAddedTo(addedTo)
  }

  // On remove lines - when clicked on lines remove them from input
  const handleRemoveLines = (variable, repId, repIndex) => {
    let addedTo 
    if(repId && repIndex !== undefined && repIndex > -1) {
      addedTo = [...linesAddedTo].filter(v => !(v.variable === variable && v.belongs_to === repId && v.index === repIndex))
    }else {
      addedTo = [...linesAddedTo].filter(v => v.variable !== variable)
    }
    setLinesAddedTo(addedTo)
    let docValues = {...documentValues}
    let findVar 
    if(repId && repIndex !== undefined && repIndex > -1) {
      findVar = linesAddedTo.find(v => v.variable === variable && v.belongs_to === repId && v.index === repIndex)
    }else {
      findVar = linesAddedTo.find(v => v.variable === variable)
    }
    if(findVar) {
      if(findVar.belongs_to) {
        if(docValues[findVar.belongs_to] && docValues[findVar.belongs_to][findVar.index] && docValues[findVar.belongs_to][findVar.index][variable]) {
          docValues[findVar.belongs_to][findVar.index][variable] = ''
        }
      }else {
        docValues[variable] = ''
      }
    }
    setDocumentValues(docValues)
    if(addedTo.length === 0) {
      setLinesAdded(false)
    }
  }

  // On signature modal open
  const handleSignModalOpen = () => {
    if(linesAdded) {
      let docValues = clearLinesFromValues()
      setDocumentValues(docValues)
    }
    setShowSignDocModal(true)
  }

  const injectAvailable = () => {
    if(isMlsPartner(partner)) return false
    return true
  }
  
  // On inject modal open
  const handleOpenInjectModal = () => {
    if(linesAdded) {
      let docValues = clearLinesFromValues()
      setDocumentValues(docValues)
    }
    setShowInjectModal(true)
  }

  // On agency modal open
  const handleAgencyModalOpen = () => {
    if(linesAdded) {
      let docValues = clearLinesFromValues()
      setDocumentValues(docValues)
    }
    setShowAgencyModal(true)
  }

  // On agency modal open
  const handleOpenNotariesModal = () => {
    if(linesAdded) {
      let docValues = clearLinesFromValues()
      setDocumentValues(docValues)
    }
    setShowNotariesModal(true)
  }

  const downloadDocumentData = () => {
    let data = { ...documentValues }
    if(documentCheckboxValues) {
      data = {
        ...data,
        ...documentCheckboxValues
      }
    }
    const buffer = Buffer.from(JSON.stringify(data, null, 2), 'ascii')
    const base64Url = `data:application/json;base64,${buffer.toString('base64')}`
    const a = document.createElement("a")
    a.href = base64Url
    a.download = `${documentName || 'document'}${documentId ? `(${documentId})` : ''}_data.json`
    a.click()
  }

  const isDocumentLocked = () => {
    return partner && isMlsPartner(partner) && (!documentObject || documentObject.signature_submitted)
  }

  return(
    <div className="document-detail">
      <div className="document-detail__head">
        {(loading || loadingPreview) && <LineLoader />}
        <div className="scrolling-indicator" ref={scrollingIndicator}></div>
        <div className="container">
          <div className="document-detail__head_left">
            <div className="document-detail__head_back" onClick={() => history.push('/documents')}>
              <ArrowBackIcon />
              <span>Documents</span>
            </div>
            <div className="document-detail__head_main">
              <h1 className="title">
                <span className="transparent">{documentName}</span>
                <div className="input-wrapper">
                  <Input
                    value={documentName}
                    placeholder={"Type document name"}
                    onChange={documentNameChangeHandler}
                    disabled={isDocumentLocked()}
                    editOnHover
                  />
                </div>
              </h1>
              <div className="status-wrap">
                <div className="status-wrap__left">
                  {/* <span></span>
                  <select name="status" id="status" value={documentStatus} onChange={e => setDocumentStatus(e.target.value)}>
                    { statusOptions() }
                  </select> */}
                  {/* Status */}
                  <CustomSelect 
                    options={statusesOptions} 
                    onChange={statusChangeHandler} 
                    active={statusesOptions.find(s => s.active)} 
                    statusEl 
                    dropdownClassToCheck="status-select-dropdown"
                    disabled={isDocumentLocked()}
                  />
                </div>
                {documentObject?.meta?.updated && <div className="status-wrap__right">{ `Mis à jour il y a ${timeElapsed(documentObject?.meta?.updated)}` }</div>}
                {<div className="template-history-trigger link-wrapper">
                  <a href="/#" onClick={showTemplateHistoryModal}><InfoIcon /> Historique du modèle</a>
                </div>}
              </div>
            </div>
          </div>

          <div className="document-detail__head_actions">
            { !isDocumentLocked() &&
              <Button 
                icon={(!saving && !saved) ? <SaveIcon /> : false} 
                text={saving ? <Loader mini normalSuccess /> : saved ? <CheckIcon /> : "Sauvegarder"} 
                onButtonClick={saveDocument} 
                secondaryLight txtSmall 
                className="save-btn" 
                disabled={saving || saved}
              /> }
            <div className="btn-wrapper">
              <div className="tooltip-wrapper">
                <CustomTooltip content="Aperçu" position="bottom" spaceSm>
                  <div>
                    <IconButton icon={<RemoveRedEyeIcon />} onButtonClick={() => fetchDocumentPreview('preview')} primaryLight fixedSmall />
                  </div>
                </CustomTooltip>
              </div>
            </div>
            { addLinesAvailable() &&
            <div className="btn-wrapper">
              <div className="tooltip-wrapper">
                <CustomTooltip content={!linesAdded ? 'Ajouter des lignes dans les champs vides' : 'Enlever les lignes des champs vides'} position="bottom" spaceSm>
                  <div>
                    <IconButton 
                      icon={<Notes />} 
                      onButtonClick={handleAddLinesToEmptyInputs} 
                      primaryLight
                      className={linesAdded ? 'active' : ''} 
                      fixedSmall 
                    />
                  </div>
                </CustomTooltip>
              </div>
            </div> }
            <ClickAwayListener onClickAway={() => setShowDownloadPopover(false)}>
              <div className="btn-wrapper">
                <div className="tooltip-wrapper">
                  {showDownloadPopover 
                    ? <IconButton icon={<PublishIcon />} onButtonClick={() => setShowDownloadPopover(!showDownloadPopover)} primaryLight fixedSmall rotate180  />
                    : <CustomTooltip content="Télécharger" position="bottom" spaceSm>
                      <div>
                        <IconButton icon={<PublishIcon />} onButtonClick={() => setShowDownloadPopover(!showDownloadPopover)} primaryLight fixedSmall rotate180  />
                      </div>
                    </CustomTooltip>
                  }
                </div>
                {showDownloadPopover && 
                  <div className="dropdown-el custom-popover active">
                    <Button text="pdf" white={true} onButtonClick={() => { 
                      fetchDocumentPreview('download', 'pdf')
                      setShowDownloadPopover(false)
                    }} />
                    <Button text="docx" white={true} onButtonClick={() => setShowDownloadDocxPopup(true)} />
                  </div>
                }
                {/* <CustomPopover show={showDownloadPopover} setShow={() => { }}>
                  <Button text="pdf" white={true} onButtonClick={() => { 
                    fetchDocumentPreview('download', 'pdf')
                    setShowDownloadPopover(false)
                  }} />
                  <Button text="docx" white={true} onButtonClick={() => setShowDownloadDocxPopup(true)} />
                </CustomPopover> */}
              </div>
            </ClickAwayListener>
            { immocloudUploadAvailable() ? (
            <div className="btn-wrapper">
              <div className="tooltip-wrapper">
                <CustomTooltip content={immocloudConfig.handle === 'customer_id' ? "Envoyer vers l'Immocloud du contact" : "Envoyer vers l'Immocloud du bien"} position="bottom" spaceSm>
                  <div>
                    <IconButton icon={<CloudUploadIcon />} onButtonClick={uploadToImmocloud} primaryLight fixedSmall />
                  </div>
                </CustomTooltip>
              </div>
            </div>
            ) : null }
            <div className="btn-wrapper">
              <div className="tooltip-wrapper">
                <CustomTooltip content="Partager" position="bottom" spaceSm>
                  <div>
                    <IconButton icon={<ShareIcon />} onButtonClick={shareClickHandler} primaryLight fixedSmall />
                  </div>
                </CustomTooltip>
              </div>
            </div>
            {injectAvailable() && history.location.pathname.includes('/document-detail/') && <div className="btn-wrapper">
              <div className="tooltip-wrapper">
                <CustomTooltip content="Injecter" position="bottom" spaceSm>
                  <div>
                    <IconButton icon={<CachedIcon />} onButtonClick={handleOpenInjectModal} primaryLight fixedSmall />
                  </div>
                </CustomTooltip>
              </div>
            </div>}
            {(!isDocumentLocked() && showSignatures) && <div className="btn-wrapper">
              <div className="tooltip-wrapper">
                <CustomTooltip content="Signer" position="bottom" spaceSm>
                  <div>
                    <IconButton icon={<EditIcon />} onButtonClick={handleSignModalOpen} primaryLight fixedSmall />
                  </div>
                </CustomTooltip>
              </div>
            </div>}
            <MoreActions 
              synthesisClickHandler={synthesisClickHandler}
              historyClickHandler={historyClickHandler}
              duplicateDocumentHandler={duplicateDocumentHandler}
              onShowDeleteAlert={() => setShowDocDeleteAlert(true)}
              downloadDataClickHandler={downloadDocumentData}
              popoverChange={downloadPopoverAnchor}
            />
          </div>
        </div>
      </div>

      {history.location.pathname.includes('/template/') && !templateLoaded && <ResponseLoader text="Document en cours de création" className="response-loader--dark" />}
      { (loading || (documentObject === null || templateObject === null)) && <Fragment>
          <DocumentDetailSkeleton opened={sidePanelOpened} />
          <div className="overlay"></div>
        </Fragment> }
      { (!loading && !!documentObject && !!templateObject) ? 
        !isDocumentLocked() ? (
        <div className={sidePanelOpened ? "document-detail__body opened" : "document-detail__body"}>
          {loading && <div className="overlay"></div>}
          <div className="document-detail__body_inner">
            <div className="wrap" ref={bodyWrapEl}>
              {view === 'variables' &&
                <DocumentDetailVariables
                  templateData={templateObjectWithUniqueVarIndexes}
                  documentValues={documentValues}
                  onValuesChange={documentValuesChangeHandler}
                  documentCheckboxValues={documentCheckboxValues}
                  onCheckboxValueChange={documentCheckboxChangeHandler}
                  onCustomCoverChange={coverChangeHandler}
                  useCustomCover={useCustomCover}
                  onAgencyModalOpen={handleAgencyModalOpen}
                  onNotariesModalOpen={handleOpenNotariesModal}
                  linesAddedTo={linesAddedTo}
                  onRemoveLines={handleRemoveLines}
                  onSetLinesAddedTo={setLinesAddedTo}
                />
              }
              {view === 'appendices' && <DocumentDetailAppendices 
                documentAttachments={documentAttachments} 
                onDeleteAttachment={onDeleteAttachment} 
                onEditAttachment={editAttachmentHandler} 
                onDeleteAttachmentsByName={onDeleteAttachmentsByName} 
                docId={documentId} 
                onDeleteSelectedAttachments={onDeleteAttachmentsByName} 
                attachmentsNames={[...documentAttachments].map(a => a.name + '.' + a.format)} 
              />}
              {/* <DocumentDetailVariables type="test" templateData={testData} documentValues={documentValues} onValuesChange={documentValuesChangeHandler} /> */}
            </div>
            <DocumentDetailSidePanelSmall
              onTriggerClick={() => setSidePanelOpened(!sidePanelOpened)}
              onSetView={setView}
              view={view}
              attachments={documentAttachments}
              signatures={signatures.filter(s => s.doc_id === documentId)}
              showBackToTop={showBackToTop}
            />
          </div>
          <DocumentDetailSidePanel 
            onTriggerClick={() => setSidePanelOpened(!sidePanelOpened)} 
            onSetView={setView} 
            view={view} 
            attachments={documentAttachments}
            signatures={signatures.filter(s => s.doc_id === documentId)}
            showBackToTop={showBackToTop}
          >
            {view === 'variables' ?
              <DocumentDetailSPVariablesCopy
                documentValues={documentValues}
                variableTypes={extractVariableTypesFromSections(templateObject.sections)}
                totalProgress={totalProgress}
                onItemClick={variableItemClickHandler}
                onSynthesisClicked={synthesisClickHandler}
                activeVariable={activeVariable}
                scrollingPosition={scrollingPosition}
                onSetScrollPosition={setPrevScrollPosition}
                prevScrollPosition={prevScrollPosition}
                templateData={templateObjectWithUniqueVarIndexes}
                onSectionClick={sectionClickHandler}
                scrollingToItem={scrollingToItem}
                linesAddedTo={linesAddedTo}
              />
              : null }
            {view === 'appendices' && <DocumentDetailSPAppendices 
              onAddAttachment={onAddAttachment} 
              attachmentsNames={[...documentAttachments].map(a => a.name + '.' + a.format)} 
              docId={documentId}
            />}
          </DocumentDetailSidePanel>
        </div>
      ) : (
        <div>
          <div className="document-detail__body_inner">
          <h3 className="document-locked-title">{ `${documentStatus === 'in-progress' ? 'Cérémonie en cours. ' : documentStatus === 'closed' ? 'Cérémonie clôturée. ' : ''}Le document n'est plus modifiable` }</h3>
            <div className="wrap" ref={bodyWrapEl}>
              <DocumentDetailVariables
                templateData={templateObjectWithUniqueVarIndexes}
                documentValues={documentValues}
                onValuesChange={documentValuesChangeHandler}
                documentCheckboxValues={documentCheckboxValues}
                onCheckboxValueChange={documentCheckboxChangeHandler}
                onCustomCoverChange={coverChangeHandler}
                useCustomCover={useCustomCover}
                onAgencyModalOpen={handleAgencyModalOpen}
                onNotariesModalOpen={handleOpenNotariesModal}
                linesAddedTo={linesAddedTo}
                onRemoveLines={handleRemoveLines}
                onSetLinesAddedTo={setLinesAddedTo}
                locked={isDocumentLocked()}
              />
              </div>
            </div>
          </div>
      ) : null }

      {showModal && <SendByEmailModal documentId={documentId} documentName={documentName} fetchDocumentData={fetchDocumentData} onClose={() => setShowModal(false)} attachments={documentAttachments} onAttachmentShareUpdate={onAttachmentShareUpdate} onAttachmentsShareSave={onAttachmentsShareSave} doc={documentObject} fetchDocuments={fetchDocuments} />}
      {historyModal.isOpen && <HistoryModal loading={historyModal.isLoading} history={historyModal.history} onClose={hideHistoryModal} onRestore={restoreVersion} />}
      {/* {templateHistoryModal.isOpen && <TemplateHistoryModal 
        loading={templateHistoryModal.isLoading} 
        history={templateHistoryModal.history} 
        onClose={hideTemplateHistoryModal} 
        onSetHistory={setTemplateHistoryModal}
      />} */}
      {showSynthesisModal && <SynthesisModal 
        onClose={() => setShowSynthesisModal(false)} 
        data={templateObject}
        getVarKeysFromContent={getVarKeysFromContent} 
        documentValues={documentValues}
        onSetDocumentValues={setDocumentValues}
        onSave={() => setShowSynthesisModal(false)}
        loading={loading}
      />}
      {previewDocumentModal && <Modal onClose={() => setPreviewDocumentModal(false)} className="modal--padding-sm">
        {/* <iframe title="Pdf" src={iframeSrc} className="iframe" /> */}
        <Document file={pdfPreviewData} onLoadSuccess={onDocumentLoadSuccess} renderMode="canvas" loading="Chargement...">
          {docPages}
        </Document>
      </Modal>}
      {showSignDocModal && signatureAvailable(token) && <SignDocumentModal
        onClose={() => setShowSignDocModal(false)} 
        onFetchDocumentData={fetchDocumentData}
        documentName={documentName}
        docId={documentId}
        docAttachments={documentAttachments}
        docSignatureRecipients={documentObject && documentObject.signatureRecipients ? documentObject.signatureRecipients : []}
        documentValues={documentValues}
        documentStatus={documentStatus}
        templateId={templateId}
        progress={progress}
        onAddAttachment={onAddAttachment}
        attachmentsNames={[...documentAttachments].map(a => a.name + '.' + a.format)} 
        documentObject={documentObject}
        docContacts={documentContacts}
        authToken={token}
        templateObject={templateObject}
      />}
      {showDocDeleteAlert && <Alert 
        onClose={() => setShowDocDeleteAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer ce document ?"
        onSubmit={deleteDocumentHandler}
        loading={deleting}
        deleteAlert
      />}
      {showDownloadDocxPopup && <Alert 
        onClose={() => {
          setShowDownloadDocxPopup(false)
        }}
        onOkClose={() => {
          fetchDocumentPreview('download', 'docx')
          setShowDownloadDocxPopup(false)
        }}
        text="Note importante"
        bodyText="Immo Docs ne pourra être tenu pour responsable des modifications apportées au .docx"
        showOk
        okBtnText="Télécharger le .docx"
      />}

      {showInjectModal && <InjectModal 
        onClose={() => setShowInjectModal(false)} 
        doc={documentObject} 
        template={templateObject}
        allVariables={allVariables}
        onSetDocValues={setDocumentValues}
        refresh={true}
      />}

      {showAgencyModal && <AgencyModal onClose={() => setShowAgencyModal(false)} onSetDocValues={setDocumentValues} token={token} />}

      {showNotariesModal && <NotariesModal 
        onClose={() => setShowNotariesModal(false)} 
        onOpenAddEditNotaryModal={openAddEditNotaryModal} 
        onSetSelectedNotary={setSelectedNotary}
      />}

      {showAddEditNotaryModal && <AddEditNotary onClose={closeAddEditNotaryModal} mode={notaryMode} notary={selectedNotary} />}
    </div>
  );
}

export default DocumentDetail;