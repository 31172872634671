import React, { useContext } from 'react'

import { UserContext } from '../../context'

const Footer = () => {
    const { user, isScroll } = useContext(UserContext)

    return (
        !isScroll && (
            <footer className="footer-v2">
                <p className='hide-below-tablet mr-6'>
                    Numéro d'assistance : 018 018 15 18
                </p>
                <p className='mr-6 hide-below-tablet'>
                    Identifiant à fournir au service client : {user.id}
                </p>
                <p className='mr-6 '>
                    {user.firstname
                        ? `${user.firstname} ${user.lastname}`
                        : ''}
                </p>
                <p className='ml-auto'>
                    &copy; 2005-{new Date().getFullYear()}{' '}
                </p>
                <p className='ml-6'>
                    <a
                        href="https://www.ac3-groupe.com/mentions-legales/"
                        target="_blank"
                        rel="noreferrer"
                        className="policy"
                    >
                        Mentions légales
                    </a>
                </p>
                <p className='ml-6'>
                    <a
                        href="https://realestate.orisha.com/app/uploads/2024/01/Conditions-generales-de-vente.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="policy"
                    >
                        Conditions générales de ventes
                    </a>
                </p>
            </footer>
        )
    )
}

export default Footer
