const Sort = () => {
  return (
    <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="12.104" height="11.458" viewBox="0 0 12.104 11.458">
        <g id="Group_1104" data-name="Group 1104" transform="translate(-366.547 -139.937)">
          <path id="Path_1617" data-name="Path 1617" d="M2.309,10.864.139,9.077a.391.391,0,0,1-.054-.541.4.4,0,0,1,.541-.054L2.169,9.755V.383a.383.383,0,1,1,.766,0V9.755L4.479,8.482a.4.4,0,0,1,.541.054.391.391,0,0,1-.054.541L2.8,10.864a.4.4,0,0,1-.487,0Z" transform="translate(373.296 140.193)" fill="#1e548a" stroke="#1e548a" strokeWidth="0.5"/>
          <path id="Path_1618" data-name="Path 1618" d="M208.8.084l2.17,1.787a.391.391,0,0,1,.054.541.4.4,0,0,1-.541.054l-1.544-1.273v9.372a.383.383,0,0,1-.766,0V1.193l-1.544,1.273a.4.4,0,0,1-.541-.054.391.391,0,0,1,.054-.541l2.17-1.787a.4.4,0,0,1,.487,0Z" transform="translate(160.797 140.192)" fill="#1e548a" stroke="#1e548a" strokeWidth="0.5"/>
        </g>
      </svg>
    </span>
  )
}

export default Sort