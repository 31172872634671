import React, { useState, forwardRef, useEffect, useContext } from 'react';
import AttachFile from '@material-ui/icons/AttachFile'

import Button from '../UI/Button';
import Loader from '../UI/Loader';
import { DocumentsContext } from '../../context/documents/documentsState';
import { NotificationContext } from '../../context/notifications/notificationState';
import { Checkbox } from '../ui_new';
import { AttachmentsContext } from '../../context/attachments/attachmentsState';
import { arrayFromObjectWithOrderedIds } from '../../utils';

const AttachmentsPopup = forwardRef(({ onCancel, resource, onManageAttachments }, ref) => {

  const { defaultAttachments, defaultAttachmentsOrder } = useContext(AttachmentsContext)

  const [defaultAttachmentsArray, setDefaultAttachmentsArray] = useState([])

  const [selectedOptions, setSelectedOptions] = useState(resource.default_attachments || []);
  const { updateTemplate } = useContext(DocumentsContext);
  const { setNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedOptions(resource.default_attachments || [])
  }, [resource])

  useEffect(() => {
    setDefaultAttachmentsArray(arrayFromObjectWithOrderedIds(defaultAttachments, defaultAttachmentsOrder))
  }, [defaultAttachments, defaultAttachmentsOrder])

  const optionSelectHandler = (value) => {
    setSelectedOptions(opts => {
      if(opts.includes(value)) {
        return opts.filter(o => o !== value)
      } else {
        return [...opts, value]
      }
    
    });
  }

  const handleManage = () => {
    onManageAttachments();
  }
  
  const submitHandler = async () => {
    setLoading(true)
    try {
      await updateTemplate({ default_attachments: selectedOptions }, resource.id);
      setNotification({ msg: `Les paramètres ont été sauvegardés`, type: 'success' });
    } catch (err) {
      console.log(err);
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    }
    setLoading(false)
    onCancel();
  }

  return (
    <div className="folders-popup wide" ref={ref}>
      <div className={"folders-popup__head root"}>
        <div className="main flex">
          <span className="icon template-box">
            <AttachFile />
          </span>
          <h5>Annexes</h5>
          <button className='manage-link btn btn--transparent' onClick={handleManage}>Gérer</button>
        </div>
      </div>
      <div className="folders-popup__body">
        <ul className={"folders-popup__body_list root"}>
          {defaultAttachmentsArray.map((option, i) => (
            <li key={i} className="root folder-list-item dont-hide-dropdown">
              <div className="root dont-hide-dropdown" onClick={() => optionSelectHandler(option.id)}>
                <AttachFile className="dont-hide-dropdown" />
                <p className="dont-hide-dropdown">{option.name}</p>
                <span><Checkbox checked={selectedOptions.includes(option.id)} onChange={() => optionSelectHandler(option.id)} value={option.id} /></span>
              </div>
            </li>
          ))
          }
        </ul>
      </div>
      <div className="folders-popup__foot">
        <Button text="Annuler" onButtonClick={onCancel} outlinePrimary className="cancel-btn" />
        <Button
          text={!loading ? "Enregistrer" : <Loader mini normalWhite />}
          onButtonClick={submitHandler}
          primary
        />
      </div>
    </div>
  );
});

export default AttachmentsPopup;