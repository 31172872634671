import React, { useContext, useEffect, useState } from 'react'
import { LoaderContext, RegisteredMailContext } from '../../../context'
import { AR24_SENDER_STATUS_LABEL_FULL } from '../../../constants'
import { PlusCircle, Redo } from '../../../assets/icons'
import SenderForm from './shared/SenderForm'
import { Input } from '../../ui_new'

const SenderModalContentModify = ({ onClose, onSubmit, onPromptRenewIdentity }) => {

  const {
    ar24Sender,
    // resendSenderConfirmation,
    requestSenderVerification,
    deleteSender,
    fetchAr24Sender,
    verifyOTPKey,
  } = useContext(RegisteredMailContext)
  const { setLoading } = useContext(LoaderContext)

  const [section, setSection] = useState('')
  const [refreshingStatus, setRefreshingStatus] = useState(false)
  const [otpKey, setOtpKey] = useState('')

  const [senderData, setSenderData] = useState({
    company: '',
    // gender: '',
    firstname: '',
    lastname: '',
    email: '',
    address1: '',
    zipcode: '',
    city: ''
  })


  const [senderDataError, setSenderDataError] = useState(null)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    if (!ar24Sender) return
    setSenderData(ar24Sender)
    if (ar24Sender.status === 'api-access-pending') {
      setSection('api-access-pending')
    } else if (ar24Sender.status === 'confirmation-pending' || ar24Sender.status === 'invitation-pending') {
      setSection('confirmation-pending')
    } else if (ar24Sender.status === 'non-verified') {
      setSection('non-verified')
    } else if (ar24Sender.status === 'verified') {
      setSection('verified')
    } else if (ar24Sender.status === 'verification-pending') {
      setSection('verification-pending')
      // } else {
      //   setSection('modify')
    }
  }, [ar24Sender])


  useEffect(() => {
    setSenderDataError(null)
    setFormErrors({})
  }, [section])

  const handleChangeSenderData = (e) => {
    const { name, value } = e.target
    setSenderData({ ...senderData, [name]: value })
  }

  const validateSenderData = () => {
    const generalError = 'Des informations sont manquantes ou mal renseignées'
    let errorMessage = ''
    const errors = {}
    if (senderData.company === '') {
      errorMessage = generalError
      errors['company'] = 'Non renseigné'
    }
    // if (senderData.gender === '') {
    //   errorMessage = generalError
    //   errors['gender'] = 'Non renseigné'
    // }
    if (senderData.firstname === '') {
      errorMessage = generalError
      errors['firstname'] = 'Non renseigné'
    }
    if (senderData.lastname === '') {
      errorMessage = generalError
      errors['lastname'] = 'Non renseigné'
    }
    if (senderData.email === '') {
      errorMessage = generalError
      errors['email'] = 'Non renseigné'
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(senderData.email)) {
        errorMessage = generalError
        errors['email'] = 'Non valide'
      }
    }
    if (senderData.address1 === '') {
      errorMessage = generalError
      errors['address1'] = 'Non renseigné'
    }
    if (!senderData.zipcode) {
      errorMessage = generalError
      errors['zipcode'] = 'Non renseigné'
    }
    if (senderData.city === '') {
      errorMessage = generalError
      errors['city'] = 'Non renseigné'
    }
    setFormErrors(errors)
    if (errorMessage) {
      setSenderDataError(errorMessage)
      return false
    }
    setSenderDataError(null)
    return true
  }

  const handleSubmitUpdateSenderData = (e) => {
    e.preventDefault()
    const isValid = validateSenderData()
    if (!isValid) {
      return
    }
    onSubmit(senderData)
  }

  // const handleResendEmail = async () => {
  //   await handleResendConfirmation(ar24Sender)
  // }

  // const handleResendConfirmation = async () => {
  //   await resendSenderConfirmation(ar24Sender)
  // }

  const handleRequestIdentifier = async () => {
    if (ar24Sender.verification_requested) {
      return onPromptRenewIdentity()
    }
    await requestSenderVerification(ar24Sender)
  }

  const promptDeleteSender = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir dissocier ce compte expéditeur ?')) {
      onClose()
      setLoading(true)
      await deleteSender(ar24Sender)
      setLoading(false)
    }
  }

  const handleStatusRefreshClick = async () => {
    setRefreshingStatus(true)
    await fetchAr24Sender()
    setRefreshingStatus(false)
  }

  const handleVerifyOTP = async () => {
    // TODO validate OTP key format, example YVIK2ORYRMBX7H23DE6FBLDVQ6SPYPR3
    const cleanKey = otpKey.replace(/[ -]/g, '')
    if (cleanKey.length !== 32) {
      setSenderDataError('La clé OTP a un format incorrect')
      return
    }
    await verifyOTPKey(cleanKey)
  }

  const transformKey = (key) => {
    // transform entered key to uppercase and insert - every 4 characters
    if (!key) return ''
    const cleanKey = key.replace(/[- ]/g, '')
    const upperCaseKey = cleanKey.toUpperCase()
    const keyArray = upperCaseKey.split('')
    const separatorString = ' - '
    let transformedKey = ''
    keyArray.forEach((char, index) => {
      if (index % 4 === 0 && index !== 0) {
        transformedKey += separatorString
      }
      transformedKey += char
    })
    transformedKey = transformedKey.substring(0, 32 + 7 * separatorString.length) // limit to 32 characters + 7 separators
    return transformedKey
  }

  const handleChangeOtpKey = (e) => {
    setOtpKey(transformKey(e.target.value))
  }

  return (
    <div className=''>
      <div className='folder-modal-v2__heading'>
        <h2 className='modal-title'>Compte expéditeur AR24</h2>
        <div className='buttons-row'>
          <button
            className='btn btn--transparent btn--medium'
            onClick={onClose}
          >
            Annuler
          </button>

          <button
            className='btn btn--primary btn--medium'
            onClick={promptDeleteSender}
          >
            Dissocier
          </button>
          {(section === 'non-verified' || section === 'verified' || section === 'verification-pending') &&
            <div className='btn-separator'></div>
          }
          {(section === 'non-verified' || section === 'verified' || section === 'verification-pending') && <>
            <button className='button action-btn bg-pink' onClick={handleRequestIdentifier}>
              <span>
                <PlusCircle />
                Nouvel identifiant LRE
              </span>
            </button>
          </>}
          {/* {ar24Sender.status !== 'api-access-pending' && (section === 'confirmation-pending' || section === 'non-verified' || section === 'verified' || section === 'verification-pending' ) && <li>
          <button
            className='btn btn--primary btn--large'
            onClick={() => setSection('modify')}
          >
            Mettre à jour
          </button>
        </li>} */}
          {/* {section === 'confirmation-pending' && <li>
          <button
            className='btn btn--success btn--large'
            onClick={handleResendEmail}
          >
            {'Renvoyer l\'email de confirmation'}
          </button>
        </li>} */}
          {/* {section === 'modify' && <li>
          <button
            className='btn btn--success btn--large'
            onClick={handleSubmitUpdateSenderData}
          >
            Soumettre
          </button>
        </li>} */}
        </div>
      </div>
      <div className='folder-modal-v2__content'>
        <ul className='modal-list'>
          {section !== 'modify' && <li className='modal-list-item'>
            <div className='flex flex-row justify-start align-center'>
              <p>Statut du compte : <span className={`status-value ${ar24Sender?.status}`}>{AR24_SENDER_STATUS_LABEL_FULL[ar24Sender?.status]}</span></p>
              <button className={`ml-1 icon-btn icon-btn--transparent refresh-status ${refreshingStatus ? 'refreshing' : ''}`} onClick={handleStatusRefreshClick}><Redo /></button>
            </div>
          </li>}
          {section === 'api-access-pending' && <>
            <li className='modal-list-item'>
              <p>Vous ne pouvez pas envoyer de lettre recommandée électronique car l'expéditeur associé au compte <b>{ar24Sender.email}</b> n'a pas encore confirmé l'association du compte AR24 sur Immo Docs.</p>
            </li>
            <li className='modal-list-item'>
              <p>Pour <b>confirmer l'association du compte AR24 à Immo Docs</b>, veuillez valider celle-ci depuis l'e-mail reçu à l'adresse "<b>{ar24Sender.email}</b>".</p>
            </li>
          </>}
          {section === 'confirmation-pending' && <>
            <li className='modal-list-item'>
              <p>Vous ne pouvez pas envoyer de lettre recommandée électronique car l'expéditeur <b>{ar24Sender.firstname} {ar24Sender.lastname}</b> n'a pas encore confirmé l'association du compte AR24 sur Immo Docs.</p>
            </li>
            <li className='modal-list-item'>
              <p>Pour <b>confirmer l'association du compte AR24</b> à Immo Docs, veuillez valider celle-ci depuis l'email reçu à l'adresse "<b>{ar24Sender.email}</b>".</p>
            </li>
          </>}
          {(section === 'modify' && ar24Sender.status === 'verified') && <>
            <li className='modal-list-item'>
              <p>Attention, la <b>mise à jour</b> des informations de votre compte implique de <b>commander</b> un nouvel <b>identifiant LRE.</b>.</p>
            </li>
            <li className='modal-list-item'>
              <p>La commande d'un identifiant LRE vous sera facturé <b>7,49&euro; HT</b>. Vous recevrez sous 3 jours ouvrés la <b>clé OTP</b> inscrite sur votre <b>identifiant LRE</b> qui vous permettra de <b>vérifier</b> votre compte.</p>
            </li>
            <li className='modal-list-item'>
              <p>Dès <b>soumission</b> des nouvelles informations, vous <b>ne pourrez plus envoyer de LRE</b> tant que votre compte ne sera pas vérifié.</p>
            </li>
          </>}
          <div className='separator'></div>
          <li className='modal-list-item'>
            {section !== 'modify' && <h4 className='mb-6 font-semibold'>Informations associées à votre compte AR24:</h4>}
            <SenderForm data={senderData} onDataChange={handleChangeSenderData} errors={formErrors} emailDisabled editable={section === 'modify'} />
          </li>
          <div className='separator'></div>
          {(section === 'non-verified' || section === 'verified' || section === 'verification-pending') &&
            <li className='modal-list-item'>
              <h4 className='font-semibold'>{section === 'verification-pending' ? 'Clé OTP de l\'identifiant LRE' : 'Identifiant LRE associé au compte :'}</h4>
              <div className='flex flex-row mobile-flex-wrap justify-start align-end gap-2 mt-6'>
                <div className={`form--group w-full mb-0 ${section !== 'verification-pending' && 'flex-grow'}`}>
                  <Input label="Votre clé OTP"
                    disabled={section !== 'verification-pending'}
                    className={`field-name mb-6 ${section === 'verification-pending' && 'otp-input'}`}
                    placeholder={section === 'non-verified' ? 'Aucun identifiant LRE associé' : section === 'verified' ? transformKey(ar24Sender.otp_key) : '1234-5678-9012-3456-7890-1234-5678-9012'}
                     name='eidas-id'
                    value={section === 'verification-pending' ? otpKey : ''}
                    onChange={handleChangeOtpKey} />
                </div>
                {section === 'verification-pending' && <button className='button height-40 button--primary-light text-nowrap' onClick={handleVerifyOTP}>Vérifier la clé OTP</button>}
              </div>
            </li>
          }
          {senderDataError && <li className='modal-list-item'>
            <p className='text-error'>
              {senderDataError}
            </p>
          </li>}
        </ul>

      </div>

    </div>
  )
}

export default SenderModalContentModify