import { useContext } from 'react'
import moment from 'moment'
import { PDFDocument } from 'pdf-lib'
import { isIOS } from 'react-device-detect'
import { saveAs } from 'file-saver'

import { DocumentsContext, UserContext, NotificationContext, LoaderContext } from '../context'
import { fetch_document_data } from '../helpers/documents'
import { areSectionConditionsMet, isCoverPageVariable, EVENT_TYPES, base64toBlob } from '../utils'
import { log_event } from '../services/analytics'
import { sendEmail } from '../services/functions'
import { push_to_immocloud } from '../services/firestore'
import { CONTACT_DATA_KEYS, CONTACT_ID_VARIABLES, DOC_CONTACTS_KEYS } from '../constants'
import immoDocsLogo from '../assets/images/immodocs-logo.png'

const eventTypeForAction = (action) => {
  switch(action) {
    case 'preview':
      return EVENT_TYPES.DOCUMENT_PREVIEW
    case 'download':
      return EVENT_TYPES.DOCUMENT_DOWNLOAD
    case 'push_binary':
      return EVENT_TYPES.IMMOCLOUD_UPLOAD
    case 'share':
      return EVENT_TYPES.DOCUMENT_SHARE
    default:
      return ''
  }
}

const useSingleDocumentActions = () => {
  const { getTemplateById, updateDocument, createDocument, temporarilyDeleteMultipleDocuments } = useContext(DocumentsContext)
  const { agency, user, partner, setContacts } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)

  // Get document history
  const getDocumentHistory = (history) => {
    let daysMap = {}
    let daysArray = []
    const dayFormat = 'DD/MM/YYYY'

    for(let key in history) {
      let version = history[key]
      let dayValue = moment(version.created).format(dayFormat)
      if(!daysMap[dayValue]) {
        daysMap[dayValue] = {
          dayValue: dayValue,
          versions: []
        }
      }
      daysMap[dayValue].versions.push(version)
    }

    for(let d in daysMap) {
      daysMap[d].versions.sort((a, b) => {
        if(a.created > b.created) return 1
        else if(a.created < b.created) return -1
        return 0
      })
      daysArray.push(daysMap[d])
    }

    daysArray.sort((a, b) => {
      if(moment(a.dayValue, dayFormat).valueOf() > moment(b.dayValue, dayFormat).valueOf()) return -1
      else if(moment(a.dayValue, dayFormat).valueOf() < moment(b.dayValue, dayFormat).valueOf()) return 1
      return 0
    })

    return daysArray
  }

  const getDocumentEventsDayArray = (events) => {
    let daysMap = {}
    let daysArray = []
    const dayFormat = 'DD/MM/YYYY'

    for(let event of events) {
      let dayValue = moment(event.created).format(dayFormat)
      if(!daysMap[dayValue]) {
        daysMap[dayValue] = {
          dayValue: dayValue,
          events: []
        }
      }
      daysMap[dayValue].events.push(event)
    }

    for(let d in daysMap) {
      daysMap[d].events.sort((a, b) => {
        if(a.created > b.created) return 1
        else if(a.created < b.created) return -1
        return 0
      })
      daysArray.push(daysMap[d])
    }

    daysArray.sort((a, b) => {
      if(moment(a.dayValue, dayFormat).valueOf() > moment(b.dayValue, dayFormat).valueOf()) return -1
      else if(moment(a.dayValue, dayFormat).valueOf() < moment(b.dayValue, dayFormat).valueOf()) return 1
      return 0
    })

    return daysArray
  }


  // Get data for preview
  const getPreviewData = async (doc, template = null, addWatermark = false, options = {}) => {
    let events = [eventTypeForAction('preview')]
    log_event(events, partner)
    try {
      let tmplt
      if(template) tmplt = template
      if(!tmplt) tmplt = getTemplateById(doc.template)

      if(tmplt && Object.keys(tmplt).length > 0) {
        let documentData = await fetch_document_data(tmplt, doc, 'pdf', agency, user, options, partner)
        if(!documentData) throw new Error('No data')

        let base64Data = `data:application/pdf;base64,${documentData}`
        // if(addWatermark) {
        //   const pdfDoc = await PDFDocument.load(`data:application/pdf;base64,${documentData}`)

        //   const pages = pdfDoc.getPages()

        //   for(let i = 0; i < pages.length; i++) {
        //     const page = pages[i]
        //     const { width, height } = page.getSize()
        //     const pngUrl = immoDocsLogo

        //     const pngImage = await pdfDoc.embedPng(pngUrl)

        //     page.drawImage(pngImage, {
        //       x: width / 2 - 50,
        //       y: height / 2 - 50,
        //       width: pngImage.width,
        //       height: pngImage.height,
        //       opacity: 0.5,
        //     })
        //   }

        //   const pdfBytes = await pdfDoc.saveAsBase64()
        //   base64Data = `data:application/pdf;base64,${pdfBytes}`
        // }
        return base64Data
      }else {
        throw new Error('No template')
      }
    } catch (err) {
      console.log(err)
    }
  }

  // Download document
  const downloadDocument = async (extension, doc, options = {}) => {
    let events = [eventTypeForAction('download')]
    events.push(extension === 'docx' ? EVENT_TYPES.DOCUMENT_DOWNLOAD_DOCX : EVENT_TYPES.DOCUMENT_DOWNLOAD_PDF)
    log_event(events, partner)
    try {
      const tmplt = getTemplateById(doc.template)
      if(tmplt && Object.keys(tmplt).length > 0) {
        let documentData = await fetch_document_data(tmplt, doc, extension, agency, user, options, partner)
        if(!documentData) throw new Error('No data')

        if(isIOS && extension === 'pdf') {
          const blob = base64toBlob(documentData)
          const a = document.createElement('a')
          a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`)
        }else {
          const a = document.createElement("a")
          a.href = `data:${mimeTypeForExtension(extension)};base64,${documentData}`
          a.download = `${doc.name || 'document'}.${extension}`
          a.click()
        }
      }else {
        throw new Error('No template')
      }
    } catch (err) {
      console.log(err)
    }
  }

  // Move document to folder
  const moveDocToFolder = async (document, folder) => {
    let doc = {...document}
    if(!document.folderId) {
      doc = {...document, folderId: []}
    }
    try {
      if(folder) {
        if(!doc.folderId.includes(folder.id)) {
          setShowGlobalResponseLoader(true)
          setGlobalResponseLoaderText('Déplacement du document')
          await updateDocument({ folderId: [folder.id] }, doc)
          setNotification({ msg: `Le document "${doc.name}" a été déplacé dans le dossier "${folder.name}"!`, type: 'default' })
        }else {
          setNotification({ msg: `Le document "${doc.name}" est déjà dans le dossier "${folder.name}"!`, type: 'warning' })
        }
      }else if(doc.folderId.length > 0){
        setShowGlobalResponseLoader(true)
        setGlobalResponseLoaderText('Déplacement du document')
        await updateDocument({ folderId: [] }, doc)
        setNotification({ msg: `Le document "${doc.name}" a été déplacé dans le dossier racine!`, type: 'default' })
      }
    } catch (err) {

    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Move to root folder
  const moveDocToRootFolder = async (data) => {
    let doc = {...data}
    if(!doc.folderId) {
      doc = {...data, folderId: []}
    }

    if(doc.folderId.length === 0)
      return setNotification({ msg: `Impossible de déplacer le document "${doc.name}" vers le dossier racine`, type: 'warning' })

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Déplacement du document')
    try {
      await updateDocument({ folderId: [] }, doc)
      setNotification({ msg: `Le document "${doc.name}" a été déplacé dans le dossier racine!`, type: 'default' })
    } catch (err) {

    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Create document
  const createDocumentFromTemplate = async (tmplt, values = null, templateId) => {
    let data = {
      values: {},
      checkboxValues: {},
      name: tmplt.name,
      status: 'draft',
      template: tmplt.id,
      progress: 0,
      folderId: [],
      custom_cover: tmplt.custom_cover ?? false
    }
    if(values) {
      data.values = values
    }
    if(templateId) {
      data.template = templateId
    }
    try {
      const id = await createDocument(data)
      return id
    } catch (err) {
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    }
  }

  // Share document
  const shareDocument = async (data, doc, template, attachments) => {
    const { message, email, recipients, subject, sentEmailId, addWatermark } = data

    try {
      const documentData = await fetch_document_data(template, doc, 'pdf', agency, user, {}, partner)
      const emails = recipients.map(r => r.value)
      await sendEmail(email, emails, subject, message, doc.name, documentData, [])
      const sentEmails = doc.sentEmails ? [...doc.sentEmails] : []
      const sharedAttachments = [...attachments].filter(att => att.share)
      sentEmails.push({
        id: sentEmailId,
        sender: email,
        attachments: sharedAttachments,
        watermark: addWatermark,
      });
      await updateDocument({ sentEmails }, {...doc, attachments})
      setNotification({ msg: 'Message envoyé !', type: 'success' })
    } catch (err) {
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    }
  }

  // Move document to deleted documents
  const moveDocumentToDeleteDocuments = async (doc) => {
    try {
      await updateDocument({ deleted: true, archived: false }, doc)
      temporarilyDeleteMultipleDocuments([doc])
    } catch (err) {
      console.log(err)
    }
  }

  // Duplicate document
  const duplicateDocument = async ({doc, linesAddedTo, template}) => {
    let docCopy = {
      name: `Copie du ${doc.name}`,
      progress: progress({ documentValues: doc.values, linesAddedTo, templateObject: template }),
      status: doc.status,
      template: doc.template,
      values: doc.values
    }
    try {
      const id = await createDocument(docCopy)
      return id
    } catch (err) {
      console.log(err)
    }
  }

  // Download document data
  const downloadDocumentData = ({ documentValues, documentCheckboxValues, documentName, documentId }) => {
    let data = { ...documentValues }
    if(documentCheckboxValues) {
      data = {
        ...data,
        ...documentCheckboxValues
      }
    }
    const buffer = Buffer.from(JSON.stringify(data, null, 2), 'ascii')
    const base64Url = `data:application/json;base64,${buffer.toString('base64')}`
    const a = document.createElement("a")
    a.href = base64Url
    a.download = `${documentName || 'document'}${documentId ? `(${documentId})` : ''}_data.json`
    a.click()
  }

  // Upload to cloud
  const uploadToCloud = async ({ template, doc, immocloudConfig }) => {
    if(!immocloudConfig.handle || !immocloudConfig.id || !immocloudConfig.site_id) {
      return alert('Aucun bien n’est lié à ce document. Le document doit être créé depuis votre logiciel de transaction')
    }
    const fileBase64 = await fetch_document_data(template, doc, 'pdf', agency, user, {}, partner)
    const response = await push_to_immocloud(fileBase64, `${doc.name}.pdf`, immocloudConfig.site_id, immocloudConfig.handle, immocloudConfig.id)
    if(response.error) {
      let message = ''
      if(response.error.error && response.error.error.product_id) {
        message = 'Aucun bien n’est lié à ce document. Le document doit être créé depuis votre logiciel de transaction'
      } else if(response.error.error) {
        for(let key in response.error.error) {
          if(typeof response.error.error[key] === 'object') {
            for(let i in response.error.error[key]) {
              if(message) {
                message += '\n'
              }
              message += response.error.error[key][i]
            }
          } else if(typeof response.error.error[key] === 'string') {
            if(message) {
              message += '\n'
            }
            message += response.error.error[key]
          }
        }
      }
      if(typeof message !== 'string' || !message.trim()) {
        message = 'Aucun bien n’est lié à ce document. Le document doit être créé depuis votre logiciel de transaction'
      }
      alert(message)
    } else {
      alert("Le document a été téléchargé avec succès")
    }
  }

  /* ===== Helpers ===== */
  // Extract variable types from sections
  const extractVariableTypesFromSections = (sections, documentValues) => {
    let variablesWithTypes = extractVariablesWithTypesFromSections(sections, documentValues)
    let types = {}
    for(let i in variablesWithTypes) {
      types[variablesWithTypes[i].variable] = variablesWithTypes[i].type
    }
    return types
  }

  // Extract variebls with types from sections
  const extractVariablesWithTypesFromSections = (sections, documentValues) => {
    let variablesWithTypes = []
    for(let i in sections) {
      let section = sections[i]
      if(!areSectionConditionsMet(section, documentValues)) {
        continue
      }
      if(section.sections) {
        let vars = extractVariablesWithTypesFromSections(section.sections)
        for(let i in vars) {
          variablesWithTypes.push(vars[i])
        }
      }
      if(section.type === 'question') {
        if(isCoverPageVariable(section.variable)) {
          continue
        }
        variablesWithTypes.push({variable: section.variable, type: section.data_type})
      } if(section.type === 'text' || section.type === 'heading1' || section.type === 'heading2' || section.type === 'heading3') { // todo make more general
        for(let vi in section.variables) {
          if(isCoverPageVariable(section.variables[vi].variable)) {
            continue
          }
          variablesWithTypes.push({variable: section.variables[vi].variable, type: section.variables[vi].type})
        }
      }
    }
    let uniqueVariables = [...new Set(variablesWithTypes)];
    return uniqueVariables
  }

  // Process query variables
  const processedQueryVariables = (tempData, values) => {
    // let variables = allVariables(tempData)
    let processedValues = {}
    for(let key in values) {
      if(key === 'token') {
        continue
      }
      processedValues[key] = values[key]
      // let processed = false;
      // for(let i in variables) {
      //   if(variables[i].variable === key && variables[i].type === 'date') {
      //     console.log('process date', key, values[key])
      //     processedValues[key] = moment(values[key], 'DD/MM/YYYY').format('DD/MM/YYYY')
      //     processed = true;
      //     break
      //   }
      // }
      // if(!processed) {
      // }
    }
    return processedValues
  }

  // Join values
  const joinValues = (custom, prefill) => {
    let values = {...custom}
    for(let key in prefill) {
      if(!values[key]) {
        values[key] = prefill[key]
      }
    }
    return values
  }

  // Remove/clear values with lines
  const clearLinesFromValues = ({ documentValues, linesAddedTo, setLinesAdded, setLinesAddedTo }) => {
    let docValues = {...documentValues}
    if(linesAddedTo.length > 0) { // don't save underscores to db, remove them from values before saving
      linesAddedTo.forEach(v => {
        if(v.belongs_to) {
          if(docValues[v.belongs_to]) {
            let repeatable = JSON.parse(JSON.stringify(docValues[v.belongs_to]))
            if(repeatable[v.index][v.variable]) {
              delete repeatable[v.index][v.variable]
            }
            docValues[v.belongs_to] = repeatable
          }
        }else {
          if(docValues[v.variable]) {
            delete docValues[v.variable]
          }
        }
      })
    }
    setLinesAdded(false)
    setLinesAddedTo([])
    return docValues
  }

  // Get progress
  const progress = ({ templateObject, documentValues, linesAddedTo }) => {
    let p = 0;
    if(!templateObject.sections) {
      return p
    }
    let variableKeys = activeVariableKeys(templateObject, documentValues)
    if(variableKeys) {
      let totalVariables = variableKeys.length

      for(let i in variableKeys) {
        if(typeof variableKeys[i] === 'string') {
          if(!!documentValues[variableKeys[i]] && !linesAddedTo.find(v => v.variable === variableKeys[i])) {
            p += 1 / variableKeys.length
          }
        }else {
          if(documentValues[variableKeys[i].belongs_to] && !!documentValues[variableKeys[i].belongs_to][variableKeys[i].index][variableKeys[i].variable] && !linesAddedTo.find(v => v.variable === variableKeys[i].variable)) {
            p += 1 / variableKeys.length
          }
        }
      }
    }
    return p
  }

  // Active variable keys
  const activeVariableKeys = (templateObject, documentValues) => {
    let templateCopy = {...templateObject}
    let sections = [...templateCopy.sections]
    if(templateCopy.footer) {
      sections.push({...templateCopy.footer, type: templateCopy.footer.type || 'text'})
    }
    let vars = extractVariablesFromSections(documentValues, sections)
    const excludedProgressVars = [...CONTACT_ID_VARIABLES]
    vars = vars.filter(v => !excludedProgressVars.includes(v))
    return vars
  }

  // Extract variables from sections
  const extractVariablesFromSections = (documentValues, sections, active = true, full = false) => {
    let variables = []
    for(let i in sections) {
      let section = sections[i]
      if(active && (section.condition || section.conditions) && !areSectionConditionsMet(section, documentValues)) {
        continue
      }
      if(section.sections) {
        let vars = extractVariablesFromSections(section.sections)
        for(let i in vars) {
          variables.push(vars[i])
        }
      }
      if(section.type === 'question') {
        if(isCoverPageVariable(section.variable)) {
          continue
        }
        if(full) {
          variables.push({ variable: section.variable, type: section.data_type, options: section.options })
        } else {
          variables.push(section.variable)
        }
      } if(section.type === 'text' || section.type === 'heading1' || section.type === 'heading2' || section.type === 'heading3') { // todo make more general
        for(let vi in section.variables) {
          if(isCoverPageVariable(section.variables[vi].variable)) {
            continue
          }
          if(full) {
            // variables.push(section.variables[vi])
            if(section.repeatable_section_id && documentValues[section.repeatable_section_id]) {
              documentValues[section.repeatable_section_id].forEach((item, index) => {
                let splittedVar = section.variables[vi].variable.split('_')
                let lastEl = splittedVar[splittedVar.length - 1]
                if(lastEl !== 'id') {
                  variables.push({...section.variables[vi], index, belongs_to: section.repeatable_section_id})
                }
              })
            }else {
              if(section.repeatable_section_id) {
                variables.push({...section.variables[vi], belongs_to: section.repeatable_section_id, index: 0 })
              }else {
                variables.push(section.variables[vi])
              }
            }
          } else {
            if(section.repeatable_section_id && documentValues[section.repeatable_section_id]) {
              documentValues[section.repeatable_section_id].forEach((item, index) => {
                let splittedVar = section.variables[vi].variable.split('_')
                let lastEl = splittedVar[splittedVar.length - 1]
                if(lastEl !== 'id') {
                  variables.push({variable: section.variables[vi].variable, index, belongs_to: section.repeatable_section_id})
                }
              })
            }else {
              if(section.repeatable_section_id) {
                variables.push({...section.variables[vi].variable, belongs_to: section.repeatable_section_id, index: 0})
              }else {
                variables.push(section.variables[vi].variable)
              }
            }
          }
        }
      } else if(section.type === 'bullet_list') {
        for(let bi in section.items) {
          if(section.repeatable_section_id && documentValues[section.repeatable_section_id]) {
            documentValues[section.repeatable_section_id].forEach((item, index) => {
              for(let vi in section.items[bi].variables) {
                if(isCoverPageVariable(section.items[bi].variables[vi].variable)) {
                  continue
                }
                if(full) {
                  variables.push({...section.items[bi].variables[vi], index, belongs_to: section.repeatable_section_id})
                } else {
                  variables.push({ variable: section.items[bi].variables[vi].variable, index, belongs_to: section.repeatable_section_id })
                }
              }
            })
          } else {
            for(let vi in section.items[bi].variables) {
              if(isCoverPageVariable(section.items[bi].variables[vi].variable)) {
                continue
              }
              if(full) {
                variables.push(section.items[bi].variables[vi])
              } else {
                variables.push(section.items[bi].variables[vi].variable)
              }
            }
          }
        }
      } else if(section.type === 'table') {
        const tableRepeatableId = section.repeatableRowId
        const tableValues = documentValues[tableRepeatableId] || []
        for(let ti in tableValues) {
          for(let bi in section.row) {
            for(let vi in section.row[bi].variables) {
              if(isCoverPageVariable(section.row[bi].variables[vi].variable)) {
                continue
              }
              if(full) {
                variables.push({...section.row[bi].variables[vi], index: ti, belongs_to: tableRepeatableId})
              } else {
                variables.push({ variable: section.row[bi].variables[vi].variable, index: ti, belongs_to: tableRepeatableId })
              }
            }
          }
        }
      }
    }
    let uniqueVariables = [...new Set(variables)];
    return uniqueVariables
  }

  // Mime type for extension
  const mimeTypeForExtension = (extension) => {
    switch(extension) {
      case 'pdf':
        return 'application/pdf'
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      default:
        return ''
    }
  }

  // Get all variables
  const allVariables = (documentValues, tempData, full = true, includeFooter = false) => {
    if(!tempData) {
      return null
    }
    let sections = tempData.sections
    if(includeFooter && tempData.footer) {
      sections.push({...tempData.footer, type: tempData.footer.type || 'text'})
    }
    return extractVariablesFromSections(documentValues, sections, false, full)
  }

  // Get key name
  const getKeyName = (key) => {
    let names = ['firstname', 'lastname', 'telephone', 'email_address']
    let keyName = ''
    for(let i = 0; i < names.length; i++) {
      if(key.includes(names[i])) {
        keyName = names[i]
        break
      }
    }

    return keyName
  }

  // Get key without prop name
  const getKeyWithoutPropName = (arr) => {
    let names = ['firstname', 'lastname', 'telephone', 'email']
    let filtered = []
    for(let i = 0; i < names.length; i++) {
      if(arr.includes(names[i])) {
        if(names[i] === 'email') {
          filtered = arr.filter(item => item !== 'email' && item !== 'address')
        }else {
          filtered = arr.filter(item => item !== names[i])
        }
        break
      }
    }

    return filtered.length > 1 ? filtered.join('_') : filtered.join('')
  }

  const extractCustomersFromData = (data) => {
    if(!data) return
    let contacts_keys = []
    let dataClone = {...data}
    for(let key in dataClone) {
      const keyArr = key.split('_')
      let keyClone
      if(isNaN(keyArr[keyArr.length - 1])){
        keyClone = key
      }else {
        keyArr.pop()
        keyClone = keyArr.join('_')
      }
      if(DOC_CONTACTS_KEYS.includes(keyClone)) {
        contacts_keys.push(key)
      }
    }
    let contacts = []
    contacts_keys.forEach((key, idx) => {
      const keyArr = key.split('_')
      let keyWithoutPropName = getKeyWithoutPropName(keyArr)
      let keyName = getKeyName(key)
      if(idx === 0) {
        contacts[0] = {
          [keyName]: dataClone[key],
          belongs_to: keyWithoutPropName
        }
      }else {
        let objFound = contacts.find(c => c.belongs_to === keyWithoutPropName)
        if(contacts.find(c => c.belongs_to === keyWithoutPropName)) {
          objFound[keyName] = dataClone[key]
        }else {
          contacts.push({ [keyName]: dataClone[key], belongs_to: keyWithoutPropName })
        }
      }
    })
    return contacts
  }

  // Get customers from data
  const getCustomersFromData = (data, setDocumentContacts) => {
    if(!data) return []
    let contacts_keys = []
    let dataClone = {...data}
    const arrayContacts = []
    for(let key in dataClone) {
      const keyArr = key.split('_')
      let keyClone
      if(Array.isArray(dataClone[key])) {
        for(let i in dataClone[key]) {
          arrayContacts.push(...extractCustomersFromData(dataClone[key][i]))
        }
        continue
      }
      if(isNaN(keyArr[keyArr.length - 1])){
        keyClone = key
      }else {
        keyArr.pop()
        keyClone = keyArr.join('_')
      }
      if(DOC_CONTACTS_KEYS.includes(keyClone)) {
        contacts_keys.push(key)
      }
    }
    let contacts = []
    contacts_keys.forEach((key, idx) => {
      const keyArr = key.split('_')
      let keyWithoutPropName = getKeyWithoutPropName(keyArr)
      let keyName = getKeyName(key)
      if(idx === 0) {
        contacts[0] = {
          [keyName]: dataClone[key],
          belongs_to: keyWithoutPropName
        }
      }else {
        let objFound = contacts.find(c => c.belongs_to === keyWithoutPropName)
        if(contacts.find(c => c.belongs_to === keyWithoutPropName)) {
          objFound[keyName] = dataClone[key]
        }else {
          contacts.push({ [keyName]: dataClone[key], belongs_to: keyWithoutPropName })
        }
      }
    })
    const uniqueContacts = [...new Set([...contacts, ...arrayContacts])]
    setDocumentContacts(uniqueContacts)
    return uniqueContacts
  }

  const dataKeyArrayForType = (type) => {
    return CONTACT_DATA_KEYS[type]
  }

  const extractCustomerData = (data) => {
    let types = Object.keys(CONTACT_DATA_KEYS)
    let contacts = {}
    for(let t in types) {
      let type = types[t]
      contacts[type] = []
      let sourceKeys = dataKeyArrayForType(type)
      for(let key in data) {
        let isCustomerKey = false
        let keyWithoutIndex
        let components = key.split('_')
        let customerIndex = components[components.length - 1]
        if(isNaN(customerIndex)) {
          keyWithoutIndex = key
        } else {
          components.pop()
          keyWithoutIndex = components.join('_')
          customerIndex--
        }

        for(let i in sourceKeys) {
          if(keyWithoutIndex === sourceKeys[i]) {
            isCustomerKey = true
            break
          }
        }
        if(isCustomerKey) {
          if(!contacts[type][customerIndex]) {
            contacts[type][customerIndex] = {}
          }
          contacts[type][customerIndex][keyWithoutIndex] = data[key]
        }
      }
    }
    setContacts(contacts)
  }

  // Check if cloud upload is available
  const immocloudUploadAvailable = () => {
    return true
    // const availablePartners = ['squarehabitat', 'cai']
    // return availablePartners.includes(partner)
  }
  /* ===== END Helpers ===== */

  return {
    getDocumentHistory,
    getDocumentEventsDayArray,
    extractVariableTypesFromSections,
    processedQueryVariables,
    joinValues,
    getPreviewData,
    clearLinesFromValues,
    progress,
    mimeTypeForExtension,
    downloadDocument,
    allVariables,
    moveDocToFolder,
    moveDocToRootFolder,
    createDocumentFromTemplate,
    shareDocument,
    getCustomersFromData,
    moveDocumentToDeleteDocuments,
    duplicateDocument,
    downloadDocumentData,
    immocloudUploadAvailable,
    uploadToCloud,
    extractCustomerData,
  }
}

export default useSingleDocumentActions