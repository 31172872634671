import { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { DocumentsContext, FolderContext, UserContext } from '../../context'
import { timeDisplayFormat, dateOnlyDisplayFormat, isFeatureAuthorized } from '../../utils'
import CustomTooltip from './CustomTooltip'
import { useSingleDocumentActions, useTemplatesActions } from '../../hooks'
import { Folder, Eye, Plus, Signature, InfoCircle, CalendarAlt, History, Pen, MoveToFolder } from '../../assets/icons';
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper'
import ActionsDropdown from '../UI/ActionsDropdown'
import { canUseTemplateCategories, urlSuffixForEnvironment } from '../../utils'
import { Add, InfoOutlined } from '@material-ui/icons'
import config from '../../config.json'
import SectionsPopup from '../sections/SectionsPopup'
import SignaturesPopup from '../sections/SignaturesPopup'
import { MoveSelectedItemsToFolderModal } from '../misc'
import { Delete, MoreVert, OpenInNew } from '@mui/icons-material';


const StandardTemplatesTableRow = ({ template, onPreview, onCreateDocument, onTemplateDelete, onTemplateEdit, standard, onOpenStandardTemplate, onInfo, onHistory }) => {
  const { partner, user, userClaims } = useContext(UserContext)
  const { templates } = useContext(DocumentsContext)
  const { standardTemplatesFolders } = useContext(FolderContext)
  const { moveTemplateToFolder } = useTemplatesActions()

  const [createdAt, setCreatedAt] = useState('')
  const [createdAtTime, setCreatedAtTime] = useState('')
  const [modifiedAt, setModifiedAt] = useState('')
  const [modifiedAtTime, setModifiedAtTime] = useState('')
  const [showDropdown, setShowDropdown] = useState(false);
  const [hideDropdown, setHideDropdown] = useState(false);
  const [showSectionsPopup, setShowSectionsPopup] = useState(false);
  const [showSignaturesPopup, setShowSignaturesPopup] = useState(false);
  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false)

  const sectionsPopupEl = useRef();
  const signaturesPopupEl = useRef();


  // Set metadata
  useEffect(() => {
    if (template?.meta?.created) {
      setCreatedAt(moment(template.meta.created).format(dateOnlyDisplayFormat))
      setCreatedAtTime(moment(template.meta.created).format(timeDisplayFormat))
    } else if (template.createdAt) {
      setCreatedAt(moment(template.createdAt).format(dateOnlyDisplayFormat))
      setCreatedAtTime(moment(template.createdAt).format(timeDisplayFormat))
    }
    if (template?.meta?.updated) {
      setModifiedAt(moment(template.meta.updated).format(dateOnlyDisplayFormat))
      setModifiedAtTime(moment(template.meta.updated).format(timeDisplayFormat))
    } else if (template.updatedAt) {
      setModifiedAt(moment(template.updatedAt).format(dateOnlyDisplayFormat))
      setModifiedAtTime(moment(template.updatedAt).format(timeDisplayFormat))
    }
  }, [template])


  // On preview modal open
  const handleOpenPreviewModal = () => {
    onPreview(template)
  }
  // On create document
  const handleCreateDocument = () => {
    onCreateDocument(template)
  }

  // On info modal open
  const handleOpenInfoModal = () => {
    // Workaround for hiding dropdown after opening information modal
    setHideDropdown(true);
    setTimeout(() => {
      setHideDropdown(false);
    }, 500)
    onInfo(template)
  }

  const handleOpenHistoryModal = () => {
    onHistory(template)
  }

  const shareTemplateClickHandler = (tmpl) => {
    window.open(`https://jurisur-document${urlSuffixForEnvironment(config.environment).replace('_', '-')}.web.app/document/${tmpl.id}?token=${user.clientAccessToken}`, '_blank');
  }

  const singleTemplateDownload = (tmpl) => {
    const a = document.createElement("a");
    a.setAttribute('download', tmpl.name);
    a.setAttribute('href', `${tmpl.templateUrl}`);
    document.body.appendChild(a);
    a.click();
  }

  const baseTemplate = templates?.[template?.template]

  // drag and drop template to folder
  const handleDragStart = (e) => {
    e.dataTransfer.setData('template', JSON.stringify(template));
    e.dataTransfer.setData('view', 'standard-templates');
  };

  // On open move to folder
  const handleOpenMoveToFolderModal = (e, template) => {
    e.preventDefault()
    setShowMoveToFolderModal(true)
  }

  // On close move to folder
  const handleCloseMoveToFolderModal = () => {
    setShowMoveToFolderModal(false)
  }

  const handleTemplateMove = async (folder) => {
    await moveTemplateToFolder({ folder, template, view: "standard-templates" })
    handleCloseMoveToFolderModal()
  }

  return (
    <div className={`documents-table-row !pl-0 no-cursor`}
      draggable
      onDragStart={handleDragStart}
      style={{ cursor: 'move' }}
    >
      <div className="documents-table-column !pl-0">
        <div className="flex gap-2 px-3">
          <OpenInNew className='link-icon' onClick={handleCreateDocument} />
          <ActionsDropdown
            trigger={<button className="icon-btn icon-btn--transparent icon-btn--svg-xl more-vert-icon" onClick={() => setShowDropdown(!showDropdown)}><span className="svg-icon"><MoreVert fontSize='medium' /></span></button>}
            hideHeader
            width={120}
            dropdownClass="actions-dropdown--no-padding"
            hide={hideDropdown}
          >
            <ul className="dashboard-column__dropdown">
              <li onClick={handleCreateDocument} className="edit"><span className="icon add"><Add /></span>Créer un document</li>
              <li onClick={handleOpenInfoModal} className="edit"><span className="icon"><InfoOutlined /></span>Informations</li>
              <li onClick={handleOpenHistoryModal}><span className="icon rotate-180"><History /></span>Historique</li>
              {isFeatureAuthorized({ userClaims, rule: "current_full" }) && <li onClick={handleOpenPreviewModal} className="edit"><span className="icon"><Eye /></span>Voir le modèle</li>}
              {isFeatureAuthorized({ userClaims, rule: "current_full" }) && <li onClick={() => onTemplateEdit(template)} className="edit"><span className="icon"><Pen /></span>Renommer</li>}
              {isFeatureAuthorized({ userClaims, rule: "current_full" }) && <li onClick={(e) => handleOpenMoveToFolderModal(e, template)} className="edit"><span className="icon"><MoveToFolder /></span>Déplacer</li>}
              {isFeatureAuthorized({ userClaims, rule: "current_full" }) && <li className='dashboard-column__dropdown--trash' onClick={() => onTemplateDelete(template)}><span className="icon"><Delete /></span>Supprimer</li>}
            </ul>
          </ActionsDropdown>
        </div>
        <CustomTooltip content={template.name} position='top'>
          <p className='doc_name cursor-pointer line-clamp-2' onClick={handleCreateDocument}> &nbsp;{template.name}</p>
        </CustomTooltip>
      </div>

      <div className="documents-table-column documents-table-column__date">
        <div className="">
          <p>{createdAt}</p>
          <p className='time'>{createdAtTime}</p>
        </div>
      </div>
      <div className="documents-table-column documents-table-column__date">
        <div className="">
          <p>{modifiedAt}</p>
          <p className='time'>{modifiedAtTime}</p>
        </div>
      </div>
      {/* <div className="documents-table-column documents-table-column__history">
        {(baseTemplate?.versionNotes && baseTemplate?.versionNotes.length > 0) && <CustomTooltip content="Historique"><button className="icon-btn icon-btn--transparent icon-btn--svg-md" onClick={handleOpenHistoryModal}><CalendarAlt /></button></CustomTooltip>}
      </div> */}

      {showMoveToFolderModal && (
        <MoveSelectedItemsToFolderModal
          onClose={handleCloseMoveToFolderModal}
          folders={standardTemplatesFolders}
          items={[template]}
          currentFolder={null}
          newFolderCreated={false} // remove this, improve
          onNewFolderCreated={() => { }} // remove this, improve
          onMove={handleTemplateMove}
        />
      )}
    </div>
  )
}

export default StandardTemplatesTableRow