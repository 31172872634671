import { useState, useEffect, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Check, Sort, Eye, Pen, Delete, Weight } from '../../assets/icons'
import { CustomTooltip, Alert } from '../ui_new'
import { EditAttachmentModal, AttachmentsActions } from './'
import { arrayWithOrderedIds, defaultAttachmentsForDocument, FEATURE, isFeatureEnabled, sortArrayOfObjects } from '../../utils'
import { useAttachmentActions } from '../../hooks'
import { NotificationContext, LoaderContext } from '../../context'
import byteConverter from '../../helpers/byteConverter'
import { AttachmentsContext } from '../../context/attachments/attachmentsState'
import { compress_attachment } from '../../services/lawstudioApi'


const SingleDocumentAttachments = ({ attachments, docId, singleDoc, template, onSetDocumentAttachments, onSetView, onSetTab, isSidePanelOpen, onToggleSidePanel }) => {
  
  const { defaultAttachments, defaultAttachmentsOrder } = useContext(AttachmentsContext)

  const [allChecked, setAllChecked] = useState(false)
  const [selectedAttachments, setSelectedAttachments] = useState([])
  const [filteredAttachments, setFilteredAttachments] = useState([])
  const [activeSort, setActiveSort] = useState('name')
  const [activeOrder, setActiveOrder] = useState('asc')
  const [filteredDefaultAttachments, setFilteredDefaultAttachments] = useState([])

  // Set filtered attachments on attachments change
  useEffect(() => {
    if(attachments) {
      let atts = [...attachments]
      for(let i = 0; i < atts.length; i++) {
        let att = atts[i]
        if(!att.id) {
          att.id = uuidv4()
        }
      }
      setFilteredAttachments(sortArrayOfObjects(atts, 'name', 'asc'))
    }
  }, [attachments])

  // Set filtered default attachments on defaultAttachments change
  useEffect(() => {
    if(defaultAttachments) {
      let atts = Object.keys(defaultAttachments).map(key => ({
        ...defaultAttachments[key],
        id: key
      }))
      atts = defaultAttachmentsForDocument(defaultAttachments, template, singleDoc.values)
      setFilteredDefaultAttachments(arrayWithOrderedIds(atts, defaultAttachmentsOrder))
    }
  }, [defaultAttachments, defaultAttachmentsOrder, template, singleDoc])

  // On all checkbox click
  const handleAllChecked = () => {
    setAllChecked(!allChecked)
    if(allChecked) {
      setSelectedAttachments([])
    }else {
      setSelectedAttachments(filteredAttachments)
    }
  }

  // On sort
  const handleSort = (sort_by) => {
    const atts = sortArrayOfObjects(filteredAttachments, sort_by, activeOrder === 'desc' ? 'asc' : 'desc')
    setActiveSort(sort_by)
    setActiveOrder(activeOrder === 'desc' ? 'asc' : 'desc')
    setFilteredAttachments(atts)
  }

  // On upload btn click - switch to upload view
  const handleUploadClick = (e) => {
    onSetView('attachments')
    if(!isSidePanelOpen) onToggleSidePanel(true)
  }

  return (
    <div className="single-document-attachments">

      {isFeatureEnabled(FEATURE.DEFAULT_ATTACHMENTS) && (
        <>
          <div className="single-document-attachments__head">
            {/* <h2>Annexes</h2><br /> */}
            <h2>Annexes Immo Docs</h2>
          </div>
          <div className="single-document-attachments__body">
            <div className="attachments-table">
              <div className="attachments-table__head">
                <div className="attachments-table-row">
                  <div className="attachments-table-column">
                  </div>
                  <div className="attachments-table-column">
                    <p className={`${activeSort === 'name' ? 'active' : ''}`}>Nom</p>
                  </div>
                </div>
              </div>
              <div className="attachments-table__body">
                {filteredDefaultAttachments.map((attachment, idx) => {
                  return (
                    <AttachmentRow
                      key={idx}
                      attachment={attachment}
                      attachments={attachments}
                      allChecked={allChecked}
                      docId={docId}
                      onSetDocumentAttachments={onSetDocumentAttachments}
                      selectDisabled={true}
                      deleteDisabled={true}
                      editDisabled={true}
                      attachmentType={'default'}
                    />
                  )
                })}
              </div>
            </div>
          </div>

          <br/><br/>
        </>
      )}

      <div className="single-document-attachments__head">
        <h2>Vos Annexes<span className='note'>{" (Attention : seules les pièces jointes au format PDF sont autorisées lors de l’envoi en signature)"}</span></h2>
      </div>
      {selectedAttachments.length > 0 && (
        <div className="single-document-attachments__actions">
          <AttachmentsActions 
            selectedAttachments={selectedAttachments} 
            onSetSelectedAttachments={setSelectedAttachments} 
            attachments={attachments}
            onSetDocumentAttachments={onSetDocumentAttachments}
            onSetAllChecked={setAllChecked}
            docId={docId}
          />
        </div>
      )}
      <div className="single-document-attachments__body">
        <div className="attachments-table">
          <div className="attachments-table__head">
            <div className="attachments-table-row">
              <div className="attachments-table-column">
                <div className={`attachments-table-column__check ${allChecked ? 'checked' : ''}`} onClick={handleAllChecked}><Check /></div>
              </div>
              <div className="attachments-table-column" onClick={() => handleSort('name')}>
                <p className={`${activeSort === 'name' ? 'active' : ''}`}>Nom</p>
                <Sort />
              </div>
            </div>
          </div>
          <div className="attachments-table__body">
            {filteredAttachments.map((attachment, idx) => {
              return (
                <AttachmentRow 
                  key={idx} 
                  attachment={attachment} 
                  attachments={attachments}
                  onSetSelectedAttachments={setSelectedAttachments} 
                  selectedAttachments={selectedAttachments}
                  allChecked={allChecked}
                  docId={docId}
                  onSetDocumentAttachments={onSetDocumentAttachments}
                  attachmentType={'custom'}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className="single-document-attachments__foot">
        <button className="btn" onClick={handleUploadClick}>Télécharger de nouvelles pièces jointes</button>
        <br/>
      </div>

      {/* Default attachments not editable */}
      {/* <div className="single-document-attachments__foot">
        <button className="btn" onClick={handleUploadClick}>Télécharger un ou plusieurs fichiers pdf</button>
      </div> */}
    </div>
  )
}

const AttachmentRow = ({ attachment, attachments, onSetSelectedAttachments, selectedAttachments, allChecked, docId, onSetDocumentAttachments, selectDisabled, deleteDisabled, editDisabled, attachmentType }) => {
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { previewAttachment, editAttachment, deleteAttachment } = useAttachmentActions(attachment, attachments, docId)
  const [checked, setChecked] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showResizeModal, setShowResizeModal] = useState(false)
  const [resizeLoading, setResizeLoading] = useState(false)

  // Set checked to false if selectedAttachments length is zero
  useEffect(() => {
    if(checked && selectedAttachments.length === 0) {
      setChecked(false)
    }
  }, [selectedAttachments, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if(allChecked) {
      setChecked(true)
    }
  }, [allChecked])

  // On attachment select
  const handleSelectAttachment = () => {
    setChecked(!checked)
    if(checked) {
      onSetSelectedAttachments(prev => prev.filter(a => a.id !== attachment.id))
    }else {
      onSetSelectedAttachments(prev => [...prev, attachment])
    }
  }

  // On preview
  const handlePreview = () => {
    previewAttachment()
  }

  // On edit modal open
  const handleOpenEditModal = () => {
    setShowEditModal(true)
  }

  // On edit modal close
  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }

  // On edit 
  const handleEdit = async (name) => {
    const attachmentsNames = attachments.filter(a => a.id === attachment.id).map(a => a.name)
    if(attachmentsNames.includes(name)) {
      return setNotification({ msg: 'La pièce jointe portant ce nom existe déjà', type: 'danger' })
    }

    setShowGlobalResponseLoader(true)
    try {
      const updatedAttachments = await editAttachment(name)
      onSetDocumentAttachments(updatedAttachments)
      setShowEditModal(false)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On delete alert open
  const handleOpenDeleteAlert = () => {
    setShowDeleteAlert(true)
  }

  // On delete alert close
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete
  const handleDelete = async () => {
    setShowGlobalResponseLoader(true)
    setShowDeleteAlert(false)
    try {
      const updatedAttachments = await deleteAttachment()
      onSetDocumentAttachments(updatedAttachments)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  const handlePromptResize = () => {
    setShowResizeModal(true)
  }

  const handleResize = async () => {
    const resizeRequestData = {}
    if(attachment.data) {
      const base64 = attachment.data.split(',')[1]
      resizeRequestData.base64 = base64
    } else if(attachment.url) {
      resizeRequestData.url = attachment.url
    } else {
      setNotification({ msg: 'Pièce jointe non valide', type: 'danger' })
      return
    }
    setResizeLoading(true)
    const response = await compress_attachment(resizeRequestData)
    if(!response || response.error) {
      setNotification({ msg: `Erreur lors de la réduction de la pièce jointe. ${response.error?.message || ''}`, type: 'danger' })
      setResizeLoading(false)
      return
    }
    if(attachmentType === 'default') {
      // create new custom attachment with the resized data
      const newAttachment = {
        name: attachment.name,
        size: Buffer.from(response.base64, 'base64').length,
        data: `data:application/pdf;base64,${response.base64}`,
        type: 'application/pdf',
        size_reduced: true
      }
      onSetDocumentAttachments(prev => [...prev, newAttachment])
    } else if(attachmentType === 'custom') {
      // update the attachment with the resized data
      const updatedAttachments = attachments.map(a => {
        if(a.id === attachment.id) {
          return {
            ...a,
            size: Buffer.from(response.base64, 'base64').length,
            data: `data:application/pdf;base64,${response.base64}`,
            size_reduced: true
          }
        }
        return a
      })
      onSetDocumentAttachments(updatedAttachments)
    }

    setResizeLoading(false)
    setShowResizeModal(false)
  }


  return (
    <div className={`attachments-table-row ${checked ? 'selected' : ''}`}>
      <div className="attachments-table-column">
        {!selectDisabled && <div className={`attachments-table-column__check ${checked ? 'checked' : ''}`} onClick={handleSelectAttachment}>
          <Check />
        </div>}
      </div>
      <div className="attachments-table-column">
        <p>{attachment.name} ({byteConverter(attachment.size)})</p>
      </div>
      <div className="attachments-table-column actions-column">
        <CustomTooltip content="Aperçu">
          <button className="icon-btn icon-btn--transparent" type="button" onClick={handlePreview}><Eye /></button>
        </CustomTooltip>
        <CustomTooltip content="Réduire le poids">
          <button className="icon-btn icon-btn--transparent" type="button" onClick={handlePromptResize}><Weight /></button>
        </CustomTooltip>
        {!editDisabled && <CustomTooltip content="Renommer">
          <button className="icon-btn icon-btn--transparent" type="button" onClick={handleOpenEditModal}><Pen /></button>
        </CustomTooltip>}
        {!deleteDisabled && <CustomTooltip content="Supprimer">
          <button className="icon-btn icon-btn--transparent" type="button" onClick={handleOpenDeleteAlert}><Delete /></button>
        </CustomTooltip>}
      </div>

      {showEditModal && (
        <EditAttachmentModal 
          onClose={handleCloseEditModal}
          attachment={attachment}
          onEdit={handleEdit}
        />
      )}

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert}
          text={`Êtes-vous sûr de vouloir supprimer cette pièce jointe - ${attachment.name} ?`}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}
      {showResizeModal && (
        <Alert 
          onClose={() => setShowResizeModal(false)}
          text={`${attachment.name} (${byteConverter(attachment.size)})`}
          bodyText={attachment.size_reduced ? 'Cette pièce jointe a déjà été réduite.' : 'Reduire le poids du fichier pdf?'}
          showOk={attachment.size_reduced}
          onSubmit={handleResize}
          loading={resizeLoading}
          loadingText='Réduction en cours...'
        />
      )}
    </div>
  )
}


export default SingleDocumentAttachments