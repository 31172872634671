import { useContext } from 'react'
import { isIOS } from 'react-device-detect'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import { LoaderContext, DocumentsContext, UserContext, DocumentsFoldersContext, NotificationContext } from '../context'
import { fetch_document_data } from '../helpers/documents'
import { base64toBlob } from '../utils'
import { update_document, create_document, delete_documents_folder, get_signature_document, delete_document } from '../services/firestore'

const useDocumentsActions = (selectedDocuments, setSelectedDocuments) => {
  const { getTemplateById, documents, updateMultipleDocuments, updateDocument, archiveMultipleDocuments, restoreMultipleDocuments, temporarilyDeleteMultipleDocuments, permanentlyDeleteMultipleDocuments } = useContext(DocumentsContext)
  const { agency, user, partner } = useContext(UserContext)
  const { docFolders, deleteMultipleDocFolders } = useContext(DocumentsFoldersContext)
  const { setLoading, setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)

  // Download
  const downloadDocuments = async (documentsToDownload) => {
    setShowGlobalResponseLoader(true)
    if(!documentsToDownload) {
      documentsToDownload = selectedDocuments
    }
    setGlobalResponseLoaderText('Préparation des documents à télécharger')
    if(documentsToDownload.length === 1) {
      const doc = documentsToDownload[0] 

      if(!doc.folderRow && !doc.package_id) { // if document
        const tmplt = getTemplateById(documentsToDownload[0].template)
        if(tmplt && Object.keys(tmplt).length > 0) {
          let documentData = await fetch_document_data(tmplt, documentsToDownload[0], 'pdf', agency, user, {}, partner)
          if(isIOS) {
            const blob = base64toBlob(documentData)
            const a = document.createElement('a')
            a.onclick = saveAs(blob, `${documentsToDownload[0].name || 'document'}.pdf`)
          }else {
            var a = document.createElement("a") 
            a.href = `data:application/pdf;base64,${documentData}`
            a.download = `${documentsToDownload[0].name || 'document'}.pdf`
            a.click()
          }
        }
      }else if(doc.package_id) { // if signature
        const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id })
        if(res.response.status) {
          if(res.response.data) {
            if(isIOS) {
              const blob = base64toBlob(res.response.data)
              const a = document.createElement('a')
              a.onclick = saveAs(blob, `${doc.title || 'document'}.pdf`)
            }else {
              const a = document.createElement("a")
              a.href = `data:application/pdf;base64,${res.response.data}`
              a.download = `${doc.title}.pdf`
              a.click()
            }
          } else if(res.response.url) {
            const a = document.createElement("a")
            a.href = res.response.url
            a.download = `${doc.title}.pdf`
            a.click()

          }
        }
      }else { // if folder
        const folder = documentsToDownload[0]
        const zip = new JSZip()
        let docsInFolder = {}
        for(let id in documents) {
          if(documents[id].folderId?.includes(folder.id)) {
            docsInFolder[id] = documents[id]
          }
        }
        if(Object.keys(docsInFolder).length > 0) {
          let shouldCreateFolder = true
          for(let docId in docsInFolder) {
            const tmplt = getTemplateById(docsInFolder[docId].template)
            if(tmplt && Object.keys(tmplt).length > 0) {
              let documentData = await fetch_document_data(tmplt, docsInFolder[docId], 'pdf', agency, user, {}, partner)
              const name = docsInFolder[docId].name
              const folderName = getFolderPath(folder, folder.name)
              zip.file(`${folderName}/${name}_${docId}.pdf`, documentData, {base64: true})
              shouldCreateFolder = false
            }
          }
          if(shouldCreateFolder) {
            zip.folder(`${folder.name}`)
          }
        }else {
          zip.folder(`${folder.name}`)
        }
        let foldersInFolder = {}
        docFolders.forEach(f => {
          if(f.parentFolder === folder.id) {
            foldersInFolder[f.id] = f
          }
        })
        if(Object.keys(foldersInFolder).length > 0) {
          await checkForFoldersInFolderHelper(foldersInFolder, zip)
        }

        if(Object.keys(docsInFolder).length === 0 && Object.keys(foldersInFolder).length === 0) {
          return setNotification({ msg: 'Ce dossier est vide', type: 'info' })
        }

        zip.generateAsync({type:"blob"}).then(function(content) {
          saveAs(content, `${folder.name}.zip`)
        })
      }
    }else { // if multiple documents are selected create zip file and download the zip file
      const zip = new JSZip()

      for(let i in documentsToDownload) {
        const doc = documentsToDownload[i]
        
        if(!doc.folderRow && !doc.package_id) { // if document
          const tmplt = getTemplateById(doc.template)
          if(tmplt && Object.keys(tmplt).length > 0) {
            let documentData = await fetch_document_data(tmplt, doc, 'pdf', agency, user, {}, partner)
            zip.file(`${doc.name}_${doc.id}.pdf`, documentData, {base64: true})
          }
        }else if(doc.package_id) { // if signature
          const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id })
          if(res.response.status) {
            let name = `${doc.title}_${doc.id}.pdf`
            zip.file(name, res.response.data, {base64: true})
          }
        }else { // if folder
          let docsInFolder = {}
          let folder = doc
          for(let docId in documents) {
            if(documents[docId].folderId?.includes(folder.id)) {
              docsInFolder[docId] = documents[docId]
            }
          }
          if(Object.keys(docsInFolder).length > 0) {
            let shouldCreateFolder = true
            for(let docId in docsInFolder) {
              const tmplt = getTemplateById(docsInFolder[docId].template)
              if(tmplt && Object.keys(tmplt).length > 0) {
                let documentData = await fetch_document_data(tmplt, docsInFolder[docId], 'pdf', agency, user, {}, partner)
                const name = docsInFolder[docId].name
                const folderName = getFolderPath(folder, folder.name)
                zip.file(`${folderName}/${name}_${docId}.pdf`, documentData, {base64: true})
                shouldCreateFolder = false
              }
            }
            if(shouldCreateFolder) { // if there are no templates for documents in folder create empty folder
              zip.folder(`${folder.name}`)
            }
          }else {
            zip.folder(`${folder.name}`)
          }
          let foldersInFolder = {}
          docFolders.forEach(f => {
            if(f.parentFolder === folder.id) {
              foldersInFolder[f.id] = f
            }
          })
          if(Object.keys(foldersInFolder).length > 0) {
            await checkForFoldersInFolderHelper(foldersInFolder, zip)
          }
        }
      }

      if(Object.keys(zip.files).length === 0) {
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
        // setSelectedDocuments([])
        return
      }
      zip.generateAsync({type:"blob"}).then(function(content) {
        saveAs(content, "documents.zip")
      })

    }
    
    // setSelectedDocuments([])
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
  }

  // Archive
  const archiveDocuments = async (documentsToArchive) => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Archivage des documents')
    if(!documentsToArchive) {
      documentsToArchive = selectedDocuments
    }
    try {
      let docsToArchive = []
      for(let i in documentsToArchive) {
        if(!documentsToArchive[i].docFolder && !documentsToArchive[i].package_id) { // check if no folder or signature
          await update_document(documentsToArchive[i].id, { archived: true }, [])
          docsToArchive.push(documentsToArchive[i])
        }
      }
      if(docsToArchive.length > 0) {
        archiveMultipleDocuments(docsToArchive)
      }
      setSelectedDocuments([])
    } catch (err) {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Move to deleted
  const moveDocumentsToDeletedFolder = async (from = 'active') => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Suppression de documents')
    try {
      let docsToDelete = []
      for(let i in selectedDocuments) {
        if(!selectedDocuments[i].docFolder && !selectedDocuments[i].package_id) { // check if no folder or signature
          await update_document(selectedDocuments[i].id, { deleted: true, archived: false }, [])
          docsToDelete.push(selectedDocuments[i])
        }
      }
      if(docsToDelete.length > 0) {
        temporarilyDeleteMultipleDocuments(docsToDelete, from)
      }
      setSelectedDocuments([])
    } catch (err) {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Duplicate
  const duplicateDocuments = async () => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Duplication de documents')
    try {
      let promises = []
      let docs = []
      let now = Date.now()
      for(let i in selectedDocuments) {
        let doc = selectedDocuments[i]
        if(!doc.docFolder && !doc.package_id) { // check if no folder or signature
          let docCopy = {
            name: `Copie du ${doc.name}`,
            progress: doc.progress,
            status: doc.status,
            template: doc.template,
            values: doc.values
          }
          docs.push(docCopy)
          promises.push(create_document(docCopy))
        }
      }
      if(promises.length > 0) {
        const data = await Promise.all(promises)
        data.forEach((doc, i) => {
          if(doc.success) {
            docs[i].id = doc.documentId
            docs[i].meta = {
              created: now,
              updated: now
            }
          }
        })
      }
      setSelectedDocuments([])
      if(docs.length > 0) {
        updateMultipleDocuments(docs.map(doc => ({ id: doc.id, data: doc })))
      }
    } catch (err) {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Delete
  // const deleteDocuments = async () => {
  //   setLoading(true)
  //   let docsPromises = []
  //   let foldersPromises = []
  //   let docsToUpdate = []
  //   let foldersToDelete = []
  //   try {
  //     for(let i in selectedDocuments) {
  //       let doc = selectedDocuments[i]
  //       if(doc.docFolder) { // check if folder
  //         foldersToDelete.push(doc.id)
  //         foldersPromises.push(delete_documents_folder(doc.id))
  //       }else if(!doc.package_id){ // check if no signature
  //         docsToUpdate.push({ id: doc.id, data: { deleted: true, archived: false} })
  //         docsPromises.push(update_document(doc.id, { deleted: true, archived: false }, []))
  //       }
  //     }
  //     if(foldersPromises.length > 0) {
  //       await Promise.all(foldersPromises)
  //       deleteMultipleDocFolders(foldersToDelete)
  //     }
  //     if(docsPromises.length > 0) {
  //       await Promise.all(docsPromises)
  //       updateMultipleDocuments(docsToUpdate)
  //     }
  //     setSelectedDocuments([])
  //   } catch (err) {
  //     console.log(err)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  // Move documents to folder
  const moveDocumentsToFolder = async (moveTo) => {
    const promises = []
    const docsArr = []

    for(let i = 0; i < selectedDocuments.length; i++) {
      const document = selectedDocuments[i]
      let doc = document
      if(!document.folderId) {
        doc = {...document, folderId: []}
      }
      
      if(moveTo) {
        if(!doc.folderId.includes(moveTo.id)) {
          promises.push(updateDocument({ folderId: [moveTo.id] }, doc, false))
          docsArr.push({ data: { folderId: [moveTo.id] }, id: doc.id })
        }
      }else {
        if(doc.folderId.length > 0) {
          promises.push(updateDocument({ folderId: [] }, doc, false))
          docsArr.push({ data: { folderId: [] }, id: doc.id })
        }
      }
    }
    
    if(promises.length === 0) return

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Déplacer des documents vers un dossier')
    Promise.all(promises)
      .then(async () => {
        updateMultipleDocuments(docsArr)
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
        setSelectedDocuments([])
        setNotification({ msg: `${promises.length} élément(s) déplacé(s) vers le dossier ${moveTo ? moveTo.name : 'racine'} !`, type: 'default' })
      })
      .catch(err => {
        console.log(err)
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
        setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
      })
  }

  // Restore documents
  const restoreDocuments = async (from = 'archived') => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Restauration de documents')
    try {
      let docsToRestore = []
      for(let i in selectedDocuments) {
        if(!selectedDocuments[i].docFolder && !selectedDocuments[i].package_id) { // check if no folder or signature
          await update_document(selectedDocuments[i].id, { archived: false }, [])
          docsToRestore.push(selectedDocuments[i])
        }
      }
      if(docsToRestore.length > 0) {
        restoreMultipleDocuments(docsToRestore, from)
      }
      setSelectedDocuments([])
    } catch (err) {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Permanently delete documents
  const permanentlyDeleteDocuments = async () => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Suppression définitive de documents')
    try {
      let docsToDelete = []
      for(let i in selectedDocuments) {
        if(!selectedDocuments[i].docFolder && !selectedDocuments[i].package_id) { // check if no folder or signature
          await delete_document(selectedDocuments[i].id)
          docsToDelete.push(selectedDocuments[i])
        }
      }
      if(docsToDelete.length > 0) {
        permanentlyDeleteMultipleDocuments(docsToDelete)
      }
      setSelectedDocuments([])
    } catch (err) {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  /* ===== Helpers ===== */
  // Check if there are folders in folder - helper
  const checkForFoldersInFolderHelper = async (fldrs, zip) => {
    for(let id in fldrs) {
      const folder = docFolders.find(f => f.id === id)
      if(folder) {
        let docsInFolder = {}
        for(let docId in documents) {
          if(documents[docId].folderId?.includes(id)) {
            docsInFolder[docId] = documents[docId]
          }
        }
        if(Object.keys(docsInFolder).length > 0) {
          let shouldCreateFolder = true
          for(let docId in docsInFolder) {
            const tmplt = getTemplateById(docsInFolder[docId].template)
            if(tmplt && Object.keys(tmplt).length > 0) {
              let documentData = await fetch_document_data(tmplt, docsInFolder[docId], 'pdf', agency, user, {}, partner)
              const name = docsInFolder[docId].name
              const folderName = getFolderPath(folder, folder.name)
              zip.file(`${folderName}/${name}_${docId}.pdf`, documentData, {base64: true})
              shouldCreateFolder = false
            }
          }
          if(shouldCreateFolder) { // if there are no templates for documents in folder create empty folder
            const folderName = getFolderPath(folder, folder.name)
            zip.folder(`${folderName}`)
          }
        }else {
          const folderName = getFolderPath(folder, folder.name)
          zip.folder(`${folderName}`)
        }
        let foldersInFolder = {}
        docFolders.forEach(f => {
          if(f.parentFolder === folder.id) {
            foldersInFolder[f.id] = f
          }
        })
        if(Object.keys(foldersInFolder).length > 0) {
          await checkForFoldersInFolderHelper(foldersInFolder, zip)
        }
      }
    }
  }

  // Get folder path
  const getFolderPath = (folder, path) => {
    if(!folder.parentFolder) {
      return folder.name
    }else {
      const fldr = docFolders.find(f => f.id === folder.parentFolder)
      if(fldr) {
        const folderPath = `${fldr.name}/${path}`
        if(!fldr.parentFolder) {
          return folderPath
        }else {
          return getFolderPath(fldr, folderPath)
        }
      }
    }
  }
  /* ===== END Helpers ===== */

  return {
    downloadDocuments,
    archiveDocuments,
    moveDocumentsToDeletedFolder,
    duplicateDocuments,
    // deleteDocuments,
    moveDocumentsToFolder,
    restoreDocuments,
    permanentlyDeleteDocuments,
  }
}

export default useDocumentsActions