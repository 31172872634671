import MailModalMenu from "./MailModalMenu";
import { useContext, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { RegisteredMailContext } from "../../../context";
import React from 'react'
import { useHistory } from "react-router-dom";
import { Checkbox, CustomTooltip, Switch, Select, Option, Input } from "../../ui_new";
import { statusLabelForAr24User } from "../../../utils";
import { InfoCircle } from "../../../assets/icons";
import { PersonAdd, Warning } from "@material-ui/icons";
import { Delete, Plus } from "../../../assets/icons";
import { Add } from "@mui/icons-material";

const MailModalContentRecipients = forwardRef(({ onClose, recipients, setRecipients, onContinue, onReturn }, ref) => {


  const [showValidations, setShowValidations] = useState(false)
  const [formErrors, setFormErrors] = useState({})


  const updateRecipient = (e, index) => {
    const { name, value } = e.target
    const newRecipients = [...recipients]
    newRecipients[index][name] = value
    setRecipients(newRecipients)
  }

  const updateRecipientSelect = (e, index) => {
    const value = e
    const newRecipients = [...recipients]
    newRecipients[index]['type'] = value
    setRecipients(newRecipients)
  }

  useEffect(() => {
    if (showValidations) {
      validateForm()
    }
  }, [showValidations, recipients])

  const handleAddRecipient = () => {
    setShowValidations(false)
    setRecipients([...recipients, { firstName: '', lastName: '', email: '', type: 'particulier', consent: false }])
  }

  const validateForm = () => {
    const newFormErrors = {}
    let valid = true
    recipients.forEach((item, index) => {
      const newValid = {}
      if (!item.firstName) {
        newValid.firstName = true
        valid = false
      }
      if (!item.lastName) {
        newValid.lastName = true
        valid = false
      }
      if (!item.email || !item.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        newValid.email = true
        valid = false
      }
      if (!item.type) {
        newValid.type = true
        valid = false
      }
      if (item.type === 'particulier' && !item.consent) {
        newValid.consent = true
        valid = false
      }
      newFormErrors[index] = newValid
    })
    setFormErrors(newFormErrors)
    setShowValidations(true)
    return valid
  }

  const handleContinue = () => {
    const valid = validateForm()
    if (valid) {
      onContinue()
    }
  }
  useImperativeHandle(ref, () => ({
    handleContinue
  }));
  return (
    <div className='registered-mail-send-modal-container'>
      {Object.values(formErrors).find(e => Object.keys(e).length > 0) && (
        <div className="mb-20">
          <div className='sent-error'>
            <Warning />
            Des informations sont manquantes ou mal renseignées.
          </div>
        </div>
      )}
      <div className='registered-mail-send-modal-container__header overflow-auto'>
        <ul className='modal-list'>
          <li className='modal-list-item'>
            <p>Complétez les informations de chaque destinaire. Les <b>prénoms</b> et <b>noms</b> doivent être renseignés <b>selon les informations visibles sur la carte d'identité</b> afin que chaque destinaire puisse <b>valider la réception de la lettre recommandée électronique</b>.</p>
          </li>
          <li className='modal-list-item'>
            <p><br />
              Chaque destinataire renseigné fera l'objet d'un envoi de LRE, avec des certifications et preuves individuelles.
            </p>
          </li>
        </ul>
        <div className="separator"></div>
        <div className='flex flex-row modal-section-title'>
          Complétez les destinataires
        </div>
        <div className="table-responsive">
          <table className="mb-6">
            <thead>
              <tr>
                <th style={{ width: '42px' }}></th>
                <th style={{ width: '141px' }}>
                  Prénom
                </th>
                <th style={{ width: '141px' }}>
                  Nom
                </th>
                <th style={{ width: '218px' }}>
                  E-mail
                </th>
                <th style={{ width: '195px' }}>
                  Type
                </th>
                <th style={{ width: '129px' }}>
                  <div>
                    Consentement reçu*
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {recipients.map((item, index) => (
                <tr>
                  <td class="text-center">
                    <button
                      className='button button--transparent !p-2 btn-delete'
                      onClick={() => setRecipients(recipients.filter((_, i) => i !== index))}
                    >
                      <Delete color="error" />
                    </button>
                  </td>
                  <td>
                    <Input value={item.firstName} onChange={(e) => updateRecipient(e, index)} name="firstName"
                      displayPencilIcon={true} className={'mb-0'}
                      error={showValidations && formErrors[index]?.firstName ? 'Non renseigné' : ''} />
                  </td>
                  <td>
                    <Input value={item.lastName} onChange={(e) => updateRecipient(e, index)} name="lastName"
                      displayPencilIcon={true} className={'mb-0'}
                      error={showValidations && formErrors[index]?.lastName ? 'Non renseigné' : ''} />
                  </td>
                  <td>
                    <Input value={item.email} onChange={(e) => updateRecipient(e, index)} name="email"
                      displayPencilIcon={true} className={'mb-0'}
                      error={showValidations && formErrors[index]?.email ? (!item.email ? 'Non renseigné' : 'Format invalide') : ''} />
                  </td>
                  <td>
                    <Select onChange={(e) => updateRecipientSelect(e, index)} placeholder="Sélectionner" name="type"
                      className={`${showValidations && formErrors[index]?.type ? 'invalid' : ''}`} selected={recipients[index]['type']}>
                      <Option value="particulier">Particulier</Option>
                      <Option value="professionnel">Professionnel</Option>
                    </Select>
                    {showValidations && formErrors[index]?.type && <span className='not-valid'>Non renseigné</span>}
                  </td>
                  <td className="">
                    <div className="text-center">
                      {item.type === 'particulier' && <Switch checked={item.consent} onChange={(e) => {
                        updateRecipient({ target: { name: 'consent', value: e.target.checked } }, index)
                      }}
                        disabled={item.type !== 'particulier'}
                      />
                      }
                      {item.type !== 'particulier' && 'Non requis'}

                    </div>
                    {showValidations && item.type === 'particulier' && !item.consent && <span className='not-valid'>Non renseigné</span>}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <button
            className='button button--with-icon button--outline-primary height-40'
            onClick={handleAddRecipient}
          >
            <Add /> Ajouter un destinataire
          </button>
        </div>
        <div className="mt-20 mb-20">
          <p className="bottom-desc">*Pour envoyer une Lettre Recommandée Electronique à un <b>particulier</b>, vous devez avoir <b>requis au préalable le consentement</b> de celui-ci à <b>recevoir les LRE à l'adresse e-mail renseignée ici</b> (Article L100 du Code des postes et des communications électroniques).</p>
        </div>
      </div>

    </div>
  )
});

export default MailModalContentRecipients