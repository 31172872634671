import React, { useRef, useContext, useState, useEffect } from 'react';
import { isIOS } from 'react-device-detect'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import DocumentsSidebar from '../sections/documents/DocumentsSidebar';
import DocumentsView from '../sections/documents/DocumentsView';
import Alert from '../UI/Alert';
import CreateEditFolderModal from '../sections/CreateEditFolderModal';
import MoveToFolderModal from '../sections/MoveToFolderModal';
import MoveMultipleDocsToFolderModal from '../sections/MoveMultipleDocsToFolderModal';
import InjectModal from '../sections/document-detail/InjectModal';

import { filter_documents, update_document, create_document, delete_document, delete_documents_folder, get_file } from '../../services/firestore';
import { LoaderContext } from '../../context/loader/loaderState';
import { DocumentsContext } from '../../context/documents/documentsState';
import { DocumentsFoldersContext } from '../../context/documentsFolders/documentsFoldersState';
import { NotificationContext } from '../../context/notifications/notificationState';
import { getCreatedAtFromDocuments, getAllParentFolders, base64toBlob, areSectionConditionsMet, isCoverPageVariable, coverPageConfig} from '../../utils';
import { generate_document } from '../../services/lawstudioApi';
import { DOCUMENT_LIFESPAN_MILLISECONDS, STATUS_LABELS } from '../../constants';
import { UserContext } from '../../context/user/userState';
import SingleDocumentSignVialink from '../misc/SingleDocumentSignVialink';
import { useSingleDocumentActions } from '../../hooks';

const Documents = () => {
  const { getCustomersFromData } = useSingleDocumentActions()
  const { setLoading, loading } = useContext(LoaderContext);
  const { setFilteredDocuments, documents, setShowFilteredDocs, setCurrentFilter, currentFilter, setArchivedDocuments, setDeletedDocuments, archivedDocuments, deletedDocuments, setActiveFilterColumn, setDateFilters, fetchDocuments, getTemplateById, updateDocument, updateMultipleDocuments, getSingleTemplate } = useContext(DocumentsContext);
  const { agency, user, partner } = useContext(UserContext);
  const dashboardSidebar = useRef();
  const [title, setTitle] = useState('Tous');
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [monthsArr, setMonthsArr] = useState([]);
  const [search, setSearch] = useState('');
  const [restored, setRestored] = useState(false);
  const [selected, setSelected] = useState({ label: 'Tous', value: 'all' });
  const [removeSearchClose, setRemoveSearchClose] = useState(false);
  const [showDeleteDocAlert, setShowDeleteDocAlert] = useState(false);
  const [showDeleteSingleDocAlert, setShowDeleteSingleDocAlert] = useState(false);
  const [singleDocToDelete, setSingleDocToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const { fetchDocFolders, docFolders, setDocParentFolder, createDocFolder, docFoldersLoading, updateDocFolder, docFolderBreadcrumbs, setDocFolderBreadcrumbs, deleteDocFolder, setDocFoldersSelectedFilter, docFolderToMove, docFoldersSelectedFilter, updateMultipleDocFolders, setCurrentlyActiveDocFolder, currentlyActiveDocFolder } = useContext(DocumentsFoldersContext);
  const [mode, setMode] = useState('create');
  const [currentFolder, setCurrentFolder] = useState(null);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [folderCreated, setFolderCreated] = useState(false);
  const [resetFolders, setResetFolders] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const [showDeleteFolderAlert, setShowDeleteFolderAlert] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('Tous');
  const [documentFolderToMove, setDocumentFolderToMove] = useState(null);
  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false);
  const [showMoveMultipleDocsToFolderModal, setShowMoveMultipleDocsToFolderModal] = useState(false);
  const [searching, setSearching] = useState(false);
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [showInjectModal, setShowInjectModal] = useState(false)
  const [singleDocInject, setSingleDocInject] = useState(null)
  const [singleDocTemplateInject, setSingleDocTemplateInject] = useState(null)

  useEffect(() => {

    return () => {
      setFilteredDocuments({});
      setShowFilteredDocs(false);
      setActiveFilterColumn('');
      setCurrentFilter('all');
      setCurrentlyActiveDocFolder(null);
      // setDocFolderBreadcrumbs([]);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredDocuments(documents);
    setMonthsArr(getCreatedAtFromDocuments(documents));
    // eslint-disable-next-line
  }, [documents]);

  const sidebarOpenHandler = () => {
    dashboardSidebar.current.classList.add('opened');
  }

  const sidebarCloseHandler = () => {
    dashboardSidebar.current.classList.remove('opened');
  }

  // Filter all, archived and deleted
  const filterHandler = async (data, title) => {
    setTitle(title);
    setLoading(true);
    if(data) {
      const documents = await filter_documents(data);
      if(data.filterBy === 'archived') {
        setArchivedDocuments(documents.data);
      }else if(data.filterBy === 'deleted') {
        setDeletedDocuments(documents.data);
      }
      setCurrentFilter(data.filterBy);
      setFilteredDocuments(documents.data);
      setMonthsArr(getCreatedAtFromDocuments(documents.data));
    }else {
      await fetchDocuments();
    }
    setLoading(false);
    setActiveFilterColumn('');
    setSelectedDocuments([]);
    setDateFilters({ after: '', before: '' });
  }
  
  // Get all
  const getAllHandler = async (title) => {
    setTitle(title);
    setFilteredDocuments(documents);
    setShowFilteredDocs(false);
    setCurrentFilter('all');
    setActiveFilterColumn('');
    setSelectedDocuments([]);
    setMonthsArr(getCreatedAtFromDocuments(documents));
    setDateFilters({ after: '', before: '' });
  }

  // Filter by status
  const filterByStatusHandler = (status, active) => {
    let docs = {};
    if(currentFilter === 'all') { docs = {...documents} }
    else if( currentFilter === 'archived' ) { docs = {...archivedDocuments} }
    else if( currentFilter === 'deleted' ) { docs = {...deletedDocuments} }
    if(active) {
      setTitle('Tous');
      return setFilteredDocuments(docs);
    }
    let copiedDocuments = {...docs};
    const filtered = {};
    for(let key in copiedDocuments) {
      if(copiedDocuments[key].status === status) {
        filtered[key] = {...copiedDocuments[key]};
      }
    }
    setFilteredDocuments(filtered);
    setActiveFilterColumn('');
    setSelectedDocuments([]);
    setDateFilters({ after: '', before: '' });
    setTitle(STATUS_LABELS[status]);
  }

  // Filter by date
  const filterByDateHandler = (value, time) => {
    let docs = {};
    if(currentFilter === 'all') { docs = {...documents} }
    else if( currentFilter === 'archived' ) { docs = {...archivedDocuments} }
    else if( currentFilter === 'deleted' ) { docs = {...deletedDocuments} }

    let copiedDocuments = {...docs};
    let filtered = {};
    for(let key in copiedDocuments) {
      if(value !== '') {
        if(time === 'after') {
          if(copiedDocuments[key].meta.created > value) {
            filtered[key] = {...copiedDocuments[key]};
            setDateFilters({ after: value, before: ''} );
          }
        }else {
          if(copiedDocuments[key].meta.created < value) {
            filtered[key] = {...copiedDocuments[key]};
            setDateFilters({ after: '', before: value} );
          }
        }
      }else {
        filtered = {...docs};
      }
    }
    // setTitle(text ? time === 'after' ? `${text}` : `${text}` : 'Tous');
    setFilteredDocuments(filtered);
    setActiveFilterColumn('');
    setSelectedDocuments([]);
  }

  // Delete documents
  const documentsDeleteHandler = async (e) => {
    e.preventDefault();
    setDeleting(true);
    setLoading(true);
    for(let i in selectedDocuments) {
      if(selectedDocuments[i].docFolder) {
        await delete_documents_folder(selectedDocuments[i].id);
      }else {
        await update_document(selectedDocuments[i].id, { 
          deleted: true, 
          archived: false
        }, []);
      }
    }
    if(currentFilter !== 'archived') {
      await fetchDocuments();
      await fetchDocFolders();
    }else {
      const documents = await filter_documents({ filterBy: 'archived', filterComparison: '==', filterValue: true });
      setFilteredDocuments(documents.data);
    }
    setShowDeleteDocAlert(false);
    setDeleting(false);
    setLoading(false);
    setSelectedDocuments([]);
  }

  // Search documents
  const searchHandler = (e) => {
    e.preventDefault();
    
    let docs = {};
    if(currentFilter === 'all') { docs = {...documents} }
    else if( currentFilter === 'archived' ) { docs = {...archivedDocuments} }
    else if( currentFilter === 'deleted' ) { docs = {...deletedDocuments} }

    const filteredF = [...docFolders].filter(folder => {
      if(docFoldersSelectedFilter === 'all') {
        return folder.parentFolder === undefined || folder.parentFolder === null;
      }else {
        return folder.parentFolder === docFoldersSelectedFilter;
      }
    });

    if(search.trim() === '') {
      setFilteredDocuments(docs);
      setFilteredFolders(filteredF);
      return;
    }

    let copiedDocuments = {...docs};
    let filtered = {};
    let filteredFolArr = [];

    for(let key in copiedDocuments) {
      const name = copiedDocuments[key].name.toLowerCase();
      if(name.includes(search.trim().toLowerCase())) {
        filtered[key] = {...copiedDocuments[key]};
      }
    }
    [...filteredF].forEach(fol => {
      if(fol.name.toLowerCase().includes(search.trim().toLowerCase())) {
        filteredFolArr.push(fol);
      }
    })

    setSearching(true);
    setFilteredDocuments(filtered);
    setFilteredFolders(filteredFolArr);
    setSelectedDocuments([]);
    // setSearch('');
    // setRemoveSearchClose(true);
  }

  // Clear form
  const searchFormClearHandler = () => {
    let docs = {};
    if(currentFilter === 'all') { docs = {...documents} }
    else if( currentFilter === 'archived' ) { docs = {...archivedDocuments} }
    else if( currentFilter === 'deleted' ) { docs = {...deletedDocuments} }

    setFilteredDocuments(docs);
  }

  // Restore selected documents
  const documentsRestoreHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    for(let i in selectedDocuments) {
      await update_document(selectedDocuments[i].id, { deleted: false, archived: false }, []);
    }
    setRestored(true);
    if(currentFilter === 'deleted') {
      await filterHandler({ filterBy: 'deleted', filterComparison: '==', filterValue: true }, 'Supprimes');
    }else {
      await filterHandler({ filterBy: 'archived', filterComparison: '==', filterValue: true }, 'Archives');
    }
    setLoading(false);
    setSelectedDocuments([]);
  }

  // Archive selected documents
  const archiveSelectedDocuments = async (e) => {
    e.preventDefault();
    setLoading(true);
    let fetchDocs = false;
    for(let i in selectedDocuments) {
      if(!selectedDocuments[i].docFolder) {
        await update_document(selectedDocuments[i].id, { archived: true }, []);
        fetchDocs = true;
      }
    }
    if(fetchDocs) {
      await fetchDocuments();
    }
    setLoading(false);
    setSelectedDocuments([]);
  }

  const fetchDocumentData = async (templateObject, document, extension) => {
    let cpConfig = coverPageConfig(document.custom_cover, agency, user, document.name, templateObject?.hasCoverPage, partner)
    let doc = await generate_document(templateObject, {...document.values, ...cpConfig.coverPageVariables}, extension, {...cpConfig.coverPageImages, ...(document.images || {})}, cpConfig.coverPageOptions)
    if(doc.error) {
      return null
    }
    return doc.data
  }

  // Download documents 
  const downloadSelectedDocuments = async (e) => {
    e.preventDefault();
    
    setLoading(true)
    if(selectedDocuments.length === 1) {
      const doc = selectedDocuments[0] 
      if(!doc.folderRow) { // if document
        const tmplt = getTemplateById(selectedDocuments[0].template)
        if(tmplt && Object.keys(tmplt).length > 0) {
          let documentData = await fetchDocumentData(tmplt, selectedDocuments[0], 'pdf');
          if(isIOS) {
            const blob = base64toBlob(documentData)
            const a = document.createElement('a')
            a.onclick = saveAs(blob, `${selectedDocuments[0].name || 'document'}.pdf`)
          }else {
            var a = document.createElement("a"); //Create <a>
            a.href = `data:application/pdf;base64,${documentData}`; //Image Base64 Goes here
            a.download = `${selectedDocuments[0].name || 'document'}.pdf`; //File name Here
            a.click(); //Downloaded file
          }
        }
      }else { // if folder
        const folder = selectedDocuments[0]
        const zip = new JSZip()
        let docsInFolder = {}
        for(let id in documents) {
          if(documents[id].folderId?.includes(folder.id)) {
            docsInFolder[id] = documents[id]
          }
        }
        if(Object.keys(docsInFolder).length > 0) {
          let shouldCreateFolder = true
          for(let docId in docsInFolder) {
            const tmplt = getTemplateById(docsInFolder[docId].template)
            if(tmplt && Object.keys(tmplt).length > 0) {
              let documentData = await fetchDocumentData(tmplt, docsInFolder[docId], 'pdf')
              const name = docsInFolder[docId].name
              const folderName = getFolderPath(folder, folder.name)
              zip.file(`${folderName}/${name}_${docId}.pdf`, documentData, {base64: true})
              shouldCreateFolder = false
            }
          }
          if(shouldCreateFolder) {
            zip.folder(`${folder.name}`)
          }
        }else {
          zip.folder(`${folder.name}`)
        }
        let foldersInFolder = {}
        docFolders.forEach(f => {
          if(f.parentFolder === folder.id) {
            foldersInFolder[f.id] = f
          }
        })
        if(Object.keys(foldersInFolder).length > 0) {
          await checkForFoldersInFolderHelper(foldersInFolder, zip)
        }

        if(Object.keys(docsInFolder).length === 0 && Object.keys(foldersInFolder).length === 0) {
          return setNotification({ msg: 'Ce dossier est vide', type: 'info' })
        }

        zip.generateAsync({type:"blob"}).then(function(content) {
          saveAs(content, `${folder.name}.zip`)
        })
      }
    }else { // if multiple documents are selected create zip file and download the zip file
      const zip = new JSZip()

      for(let i in selectedDocuments) {
        const doc = selectedDocuments[i]
        
        if(!doc.folderRow) { // if document
          const tmplt = getTemplateById(doc.template)
          if(tmplt && Object.keys(tmplt).length > 0) {
            let documentData = await fetchDocumentData(tmplt, doc, 'pdf')
            zip.file(`${doc.name}_${doc.id}.pdf`, documentData, {base64: true})
          }
        }else { // if folder
          let docsInFolder = {}
          let folder = doc
          for(let docId in documents) {
            if(documents[docId].folderId?.includes(folder.id)) {
              docsInFolder[docId] = documents[docId]
            }
          }
          if(Object.keys(docsInFolder).length > 0) {
            let shouldCreateFolder = true
            for(let docId in docsInFolder) {
              const tmplt = getTemplateById(docsInFolder[docId].template)
              if(tmplt && Object.keys(tmplt).length > 0) {
                let documentData = await fetchDocumentData(tmplt, docsInFolder[docId], 'pdf')
                const name = docsInFolder[docId].name
                const folderName = getFolderPath(folder, folder.name)
                zip.file(`${folderName}/${name}_${docId}.pdf`, documentData, {base64: true})
                shouldCreateFolder = false
              }
            }
            if(shouldCreateFolder) { // if there are no templates for documents in folder create empty folder
              zip.folder(`${folder.name}`)
            }
          }else {
            zip.folder(`${folder.name}`)
          }
          let foldersInFolder = {}
          docFolders.forEach(f => {
            if(f.parentFolder === folder.id) {
              foldersInFolder[f.id] = f
            }
          })
          if(Object.keys(foldersInFolder).length > 0) {
            await checkForFoldersInFolderHelper(foldersInFolder, zip)
          }
        }
      }

      if(Object.keys(zip.files).length === 0) {
        setLoading(false)
        setSelectedDocuments([])
        return
      }
      zip.generateAsync({type:"blob"}).then(function(content) {
        saveAs(content, "documents.zip")
      })

    }
    
    setSelectedDocuments([])
    setLoading(false)
  }

  // Check if there are folders in folder - helper
  const checkForFoldersInFolderHelper = async (fldrs, zip) => {
    for(let id in fldrs) {
      const folder = docFolders.find(f => f.id === id)
      if(folder) {
        let docsInFolder = {}
        for(let docId in documents) {
          if(documents[docId].folderId?.includes(id)) {
            docsInFolder[docId] = documents[docId]
          }
        }
        if(Object.keys(docsInFolder).length > 0) {
          let shouldCreateFolder = true
          for(let docId in docsInFolder) {
            const tmplt = getTemplateById(docsInFolder[docId].template)
            if(tmplt && Object.keys(tmplt).length > 0) {
              let documentData = await fetchDocumentData(tmplt, docsInFolder[docId], 'pdf')
              const name = docsInFolder[docId].name
              const folderName = getFolderPath(folder, folder.name)
              zip.file(`${folderName}/${name}_${docId}.pdf`, documentData, {base64: true})
              shouldCreateFolder = false
            }
          }
          if(shouldCreateFolder) { // if there are no templates for documents in folder create empty folder
            const folderName = getFolderPath(folder, folder.name)
            zip.folder(`${folderName}`)
          }
        }else {
          const folderName = getFolderPath(folder, folder.name)
          zip.folder(`${folderName}`)
        }
        let foldersInFolder = {}
        docFolders.forEach(f => {
          if(f.parentFolder === folder.id) {
            foldersInFolder[f.id] = f
          }
        })
        if(Object.keys(foldersInFolder).length > 0) {
          await checkForFoldersInFolderHelper(foldersInFolder, zip)
        }
      }
    }
  }

  // Get folder path
  const getFolderPath = (folder, path) => {
    if(!folder.parentFolder) {
      return folder.name
    }else {
      const fldr = docFolders.find(f => f.id === folder.parentFolder)
      if(fldr) {
        const folderPath = `${fldr.name}/${path}`
        if(!fldr.parentFolder) {
          return folderPath
        }else {
          return getFolderPath(fldr, folderPath)
        }
      }
    }
  }

  // Duplicate single document - dropdown on smaller screens
  const singleDocumentDuplicate = async (e, doc) => {
    e.preventDefault();
    setLoading(true)
    console.log('duplicate document', doc)
    let docCopy = {
      name: `Copie du ${doc.name}`,
      progress: doc.progress,
      status: doc.status,
      template: doc.template,
      values: doc.values
    }
    await create_document(docCopy)
    await fetchDocuments()
    setLoading(false)
  }

  // Archive single document - dropdown on smaller screens
  const singleDocumentArchive = async (e, doc) => {
    e.preventDefault();
    setLoading(true);
    await update_document(doc.id, { archived: true }, []);
    await fetchDocuments();
    setLoading(false);
  }

  // Download single document - dropdown on smaller screens
  const singleDocumentDownload = async (e, doc) => {
    e.preventDefault();
    const tmplt = getTemplateById(doc.template);
    if(tmplt && Object.keys(tmplt).length > 0) {
      let documentData = await fetchDocumentData(tmplt, doc, 'pdf');
      if(isIOS) {
        const blob = base64toBlob(documentData)
        const a = document.createElement('a')
        a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`)
      }else {
        const a = document.createElement("a")
        a.href = `data:application/pdf;base64,${documentData}` 
        a.download = `${doc.name || 'document'}.pdf`
        a.click()
      }
    }else {
      setNotification({ msg: `Impossible de télécharger le document, le document n'a pas de modèle.`, type: 'danger' })
    }
  }

  const singleDocumentDownloadData = async (e, doc) => {
    e.preventDefault()
    let data = { ...doc.values }
    if(doc.checkboxValues) {
      data = {
        ...data,
        ...doc.checkboxValues
      }
    }
    const buffer = Buffer.from(JSON.stringify(data, null, 2), 'ascii')
    const base64Url = `data:application/json;base64,${buffer.toString('base64')}`
    const a = document.createElement("a")
    a.href = base64Url
    a.download = `${doc.name || 'document'}(${doc.id})_data.json`
    a.click()
  }

  // Show alert to delete single document
  const singleDocumentDeleteClickHandler = (e, doc) => {
    e.preventDefault();
    setShowDeleteSingleDocAlert(true);
    setSingleDocToDelete(doc);
  }

  // Delete single document - dropdown on smaller screens
  const singleDocumentDelete = async () => {
    setDeleting(true);
    setLoading(true);
    await update_document(singleDocToDelete.id, { 
      deleted: true, 
      archived: false
    }, []);
    if(currentFilter !== 'archived') {
      await fetchDocuments();
    }else {
      const documents = await filter_documents({ filterBy: 'archived', filterComparison: '==', filterValue: true });
      setFilteredDocuments(documents.data);
    }
    setShowDeleteSingleDocAlert(false);
    setDeleting(false);
    setLoading(false);
    setSingleDocToDelete(null);
  }

  // Restore single document - dropdown on smaller screens
  const singleDocumentRestore = async (e, doc) => {
    e.preventDefault();
    setLoading(true);
    await update_document(doc.id, { deleted: false, archived: false }, []);
    setRestored(true);
    await filterHandler({ filterBy: 'deleted', filterComparison: '==', filterValue: true }, 'Supprimes');
    setLoading(false);
  }

  // Open folder create modal and set parent folder
  const folderCreateClickHandler = (e, folder = null) => {
    setMode('create');
    if(folder) {
      setDocParentFolder(folder);
    }else {
      setCurrentFolder(null);
    }
    setShowFolderModal(true);
  }

  // Create folder
  const createFolderHandler = async (data) => {
    await createDocFolder(data);
    setShowFolderModal(false);
    setFolderCreated(true);
  }

  // Update folder
  const folderEditClickHandler = (folder) => {
    setCurrentFolder(folder);
    setMode('edit');
    setShowFolderModal(true);
  }

  const updateFolderHandler = async (data, id) => {
    await updateDocFolder(data, id);
    setShowFolderModal(false);
    setCurrentFolder(null);
    if(docFolderBreadcrumbs.length > 0) {
      const breadcrumbs = [...docFolderBreadcrumbs];
      const findBreadcrumb = breadcrumbs.find((b) => b.id === id);
      if(findBreadcrumb) {
        findBreadcrumb.name = data.name;
        setDocFolderBreadcrumbs(prev => [...prev].map(b => b.id === findBreadcrumb.id ? findBreadcrumb : b));
      }
    }
  }

  // Delete folder
  const folderDeleteClickHandler = (folder, reset = false) => {
    setCurrentFolder(folder);
    setShowDeleteFolderAlert(true);
    if(reset) {
      setResetFolders(true);
    }
  }

  const deleteFolderHandler = async () => {
    await deleteDocFolder(currentFolder.id);
    setNotification({ msg: `Dossier "${currentFolder.name}" supprimé !`, type: 'default' });
    setShowDeleteFolderAlert(false);
    setCurrentFolder(null);
    if(resetFolders) {
      setDocFoldersSelectedFilter('all');
      setSelectedTitle('Tous');
      setDocFolderBreadcrumbs([]);
      setResetFolders(false);
      const docs = {...documents};
      let filtered = {};
      for(let key in docs) {
        if(!docs[key].folderId || (docs[key].folderId && docs[key].folderId.length < 2)) {
          filtered[key] = docs[key];
        }
      }
      setFilteredDocuments(filtered);
      // const nonDeletedTemplates = filterNonDeletedTemplates({...templates});
      // setFilteredTemplates(nonDeletedTemplates);
    }
  }

  // Move folder - folders popup
  const moveFolderHandler = async (selectedFolder) => {
    const parentFolders = getAllParentFolders(docFolders, selectedFolder);
    if(selectedFolder) {
      if(docFolderToMove.id === selectedFolder.id) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${docFolderToMove.name}" vers le dossier "${selectedFolder.name}"`, type: 'warning' });
      if(docFolderToMove.parentFolder === selectedFolder.id) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${docFolderToMove.name}" vers le dossier "${selectedFolder.name}"`, type: 'warning' });  
      if(parentFolders.find(f => f.id === docFolderToMove.id)) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${docFolderToMove.name}" vers le dossier "${selectedFolder.name}"`, type: 'warning' }); 
      
      // console.log(`Move ${docFolderToMove.name} to ${selectedFolder.name}`);
      await updateDocFolder({ parentFolder: selectedFolder.id }, docFolderToMove.id);
      setNotification({ msg: `Dossier "${docFolderToMove.name}" déplacé dans le dossier "${selectedFolder.name}"`, type: 'default' }); 
      
    }else {
      if(docFolderToMove.parentFolder) {
        // console.log(`Move ${docFolderToMove.name} to root folder`);
        await updateDocFolder({ parentFolder: null }, docFolderToMove.id);
        setNotification({ msg: `Le dossier "${docFolderToMove.name}" a été déplacé vers le dossier racine`, type: 'default' });
      }else {
        setNotification({ msg: `Impossible de déplacer le dossier "${docFolderToMove.name}" vers le dossier racine`, type: 'warning' });
      }
    }
  }

  // Filter documents by folders
  const filterByFoldersHandler = (folder) => {
    setCurrentlyActiveDocFolder(folder);
    const parentFolders = getAllParentFolders(docFolders, folder);
    const reverseParentFolders = parentFolders.reverse();
    if(parentFolders.length > 0) {
      setDocFolderBreadcrumbs([...reverseParentFolders, folder]);
    }else {
      if(folder) {
        setDocFolderBreadcrumbs([folder]);
      }else {
        setDocFolderBreadcrumbs([]);
      }
    }
    setSearch('');
    setRemoveSearchClose(true);
    const filtered = {};
    const docs = {...documents};
    if(folder) {
      for(let key in docs) {
        if(docs[key].folderId && docs[key].folderId.includes(folder.id)) {
          filtered[key] = docs[key];
        }
      }
      setFilteredDocuments(filtered);
      setDocFoldersSelectedFilter(folder.id);
      setSelectedTitle(folder.name);
    }else {
      console.log("no folder selected")
      setFilteredDocuments(docs);
      setDocFoldersSelectedFilter('all');
      setSelectedTitle('Tous');
    }
    setSelectedDocuments([]);
  }

  // Move folder click handler
  const folderMoveClickHandler = (folder) => {
    setDocumentFolderToMove(folder);
    setShowMoveToFolderModal(true);
  }

  // Move folder
  const moveFolder = async (current, active) => {
    if(!current.parentFolder && !active) {
      return setNotification({ msg: `Le dossier "${current.name}" est déjà dans le dossier racine !`, type: 'warning' });
    }
    if(active && current.parentFolder === active.id) {
      return setNotification({ msg: `Le dossier "${current.name}" se trouve déjà dans le dossier "${active.name}"!`, type: 'warning' });
    }
    setLoading(true);
    if(active) {
      await updateDocFolder({ parentFolder: active.id }, current.id);
      setNotification({ msg: `Le dossier "${current.name}" déplacé dans le dossier "${active.name}"`, type: 'default' });
      setDocumentFolderToMove(prev => ({...prev, parentFolder: active.id}));
    }else {
      await updateDocFolder({ parentFolder: null }, current.id);
      setNotification({ msg: `Le dossier "${current.name}" a été déplacé dans le dossier racine`, type: 'default' });
      setDocumentFolderToMove(prev => ({...prev, parentFolder: null}));
    }

    const parentFolders = getAllParentFolders(docFolders, {...current, parentFolder: active ? active.id : null});
    const reverseParentFolders = parentFolders.reverse();
    if(parentFolders.length > 0) {
      setDocFolderBreadcrumbs([...reverseParentFolders, {...current, parentFolder: active ? active.id : null}]);
    }else {
      if(current) {
        setDocFolderBreadcrumbs([{...current, parentFolder: active ? active.id : null}]);
      }else {
        setDocFolderBreadcrumbs([]);
      }
    }

    setShowMoveToFolderModal(false);
    setFolderCreated(true);
    setLoading(false);
  }

  // Move selected documents
  const moveSelectedDocumentsClickHandler = (e) => {
    e.preventDefault();
    setShowMoveMultipleDocsToFolderModal(true);
  }

  const moveSelectedDocumentsHandler = async (moveTo, current) => {
    // console.log(selectedDocuments, moveTo);
    const promises = [];
    const docsArr = [];
    let foldersArr = [];
    for(let i = 0; i < selectedDocuments.length; i++) {
      const document = selectedDocuments[i];
      let doc = document;
      if(!document.folderId) {
        doc = {...document, folderId: []};
      }
      if(doc.folderRow) {
        if(moveTo) {
          const parentFolders = getAllParentFolders(docFolders, moveTo);

          if(moveTo.id === doc.parentFolder) continue;
          if(moveTo.id === doc.id) continue;
          if(moveTo.parentFolder === doc.id) continue;
          if(parentFolders.find(f => f.id === doc.id)) continue;

          promises.push(updateDocFolder({ parentFolder: moveTo.id }, doc.id, false));
          foldersArr.push({ data: { parentFolder: moveTo.id }, id: doc.id });
          // promises.push(doc);
        }else {
          if(doc.parentFolder === null || doc.parentFolder === undefined) continue;

          promises.push(updateDocFolder({ parentFolder: null }, doc.id, false));
          foldersArr.push({ data: { parentFolder: null }, id: doc.id });
          // promises.push(doc);
        }

      }else {
        if(moveTo) {
          // if(doc.folderId.length < 2 && !doc.folderId.includes(moveTo.id)) {
          if(!doc.folderId.includes(moveTo.id)) {
            let fArr = [...doc.folderId];
            fArr = fArr.filter(fId => current ? fId !== current.id : fId);
            fArr.push(moveTo.id);
            // foldersArr.push(moveTo.id);
            promises.push(updateDocument({ folderId: fArr }, doc, false));
            docsArr.push({ data: { folderId: fArr }, id: doc.id });
            // promises.push(doc);
          }
          // else if(doc.folderId.length === 2 && !doc.folderId.includes(moveTo.id)) {
          //   let fArr = [...doc.folderId];
          //   fArr = fArr.filter(fId => current ? fId !== current.id : fId);
          //   fArr.push(moveTo.id);
          //   foldersArr.push(moveTo.id);
          //   promises.push(updateDocument({ folderId: fArr }, doc, false));
          //   docsArr.push({ data: { folderId: fArr }, id: doc.id });
          //   // promises.push(doc);
          // }
        }else {
          // root folder
          // if(doc.folderId.length === 2) {
            let fArr = [...doc.folderId];
            fArr = fArr.filter(fId => current ? fId !== current.id : fId);
            // foldersArr = [...fArr];
            promises.push(updateDocument({ folderId: fArr }, doc, false));
            docsArr.push({ data: { folderId: fArr }, id: doc.id });
            // promises.push(doc);
          // }
        }
      }
    }

    if(promises.length === 0) return;

    setLoading(true);
    Promise.all(promises)
      .then(async () => {
        updateMultipleDocFolders(foldersArr);
        updateMultipleDocuments(docsArr);
        setLoading(false);
        setSelectedDocuments([]);
        setShowMoveMultipleDocsToFolderModal(false);
        setNotification({ msg: `${promises.length} élément(s) déplacé(s) vers le dossier ${moveTo ? moveTo.name : 'racine'} !`, type: 'default' });
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  // Move document to folder
  const moveDocumentToFolderHandler = async (document, folder) => {
    let doc = {...document};
    if(!document.folderId) {
      doc = {...document, folderId: []};
    }
    if(folder) {
      // if(doc.folderId.length < 2 && !doc.folderId.includes(folder.id)) {
      if(!doc.folderId.includes(folder.id)) {
        // const foldersArr = [...doc.folderId];
        const foldersArr = [];
        foldersArr.push(folder.id);
        setLoading(true);
        await updateDocument({ folderId: foldersArr }, doc);
        setLoading(false);
        setNotification({ msg: `Le document "${doc.name}" a été déplacé dans le dossier "${folder.name}"!`, type: 'default' });
      }
      // else if(doc.folderId.length === 2 && !doc.folderId.includes(folder.id)) {
      //   const foldersArr = [...doc.folderId];
      //   const updatedFoldersArr = foldersArr.filter(fId => fId !== currentlyActiveDocFolder.id);
      //   updatedFoldersArr.push(folder.id);
      //   setLoading(true);
      //   await updateDocument({ folderId: foldersArr }, doc);
      //   setLoading(false);
      //   setNotification({ msg: `Le document "${doc.name}" a été déplacé dans le dossier "${folder.name}"!`, type: 'default' });
      // }
      else {
        setNotification({ msg: `Le document "${doc.name}" est déjà dans le dossier "${folder.name}"!`, type: 'warning' });
      }
    }else if(doc.folderId.length > 0){
      await updateDocument({ folderId: [] }, doc);
      setNotification({ msg: `Le document "${doc.name}" a été déplacé dans le dossier racine!`, type: 'default' });
    }
  }

  // Delete permanently
  const deletePermanentlyHandler = (e) => {
    e.preventDefault();
    const promises = [];
    const docs = [];
    selectedDocuments.forEach(doc => {
      promises.push(delete_document(doc.id));
      docs.push(doc.id);
    });

    setLoading(true);
    Promise.all(promises)
      .then(async (data) => {
        await filterHandler({ filterBy: 'deleted', filterComparison: '==', filterValue: true }, 'Supprimes');
        setNotification({ msg: `${data.length} élément(s) supprimés !`, type: 'danger' });
        setSelectedDocuments([]);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }

  // All variables
  const allVariables = (tempData, full = true, includeFooter = false) => {
    if(!tempData) {
      return null
    }
    let sections = tempData.sections
    if(includeFooter && tempData.footer) {
      sections.push({...tempData.footer, type: tempData.footer.type || 'text'})
    }
    return extractVariablesFromSections(sections, false, full)
  }

  const extractVariablesFromSections = (sections, active = true, full = false) => {
    let variables = []
    let documentValues = selectedDocuments[0]?.values || {}
    for(let i in sections) {
      let section = sections[i]
      if(active && (section.condition || section.conditions) && !areSectionConditionsMet(section, documentValues)) {
        continue
      }
      if(section.sections) {
        let vars = extractVariablesFromSections(section.sections)
        for(let i in vars) {
          variables.push(vars[i])
        }
      }
      if(section.type === 'question') {
        if(isCoverPageVariable(section.variable)) {
          continue
        }
        if(full) {
          variables.push({ variable: section.variable, type: section.data_type, options: section.options })
        } else {
          variables.push(section.variable)
        }
      } if(section.type === 'text' || section.type === 'heading1' || section.type === 'heading2' || section.type === 'heading3') { // todo make more general
        for(let vi in section.variables) {  
          if(isCoverPageVariable(section.variables[vi].variable)) {
            continue
          }
          if(full) {
            variables.push(section.variables[vi])
          } else {
            if(section.repeatable_section_id && documentValues[section.repeatable_section_id]) {
              documentValues[section.repeatable_section_id].forEach((item, index) => {
                let splittedVar = section.variables[vi].variable.split('_')
                let lastEl = splittedVar[splittedVar.length - 1] 
                if(lastEl !== 'id') {
                  variables.push({variable: section.variables[vi].variable, index, belongs_to: section.repeatable_section_id})
                }
              })
            }else {
              variables.push(section.variables[vi].variable)
            }
          }
        }
      } else if(section.type === 'bullet_list') {
        for(let bi in section.items) {
          for(let vi in section.items[bi].variables) {
            if(isCoverPageVariable(section.items[bi].variables[vi].variable)) {
              continue
            }
            if(full) {
              variables.push(section.items[bi].variables[vi])
            } else {
              variables.push(section.items[bi].variables[vi].variable)
            }
          }
        }
      }
    }
    let uniqueVariables = [...new Set(variables)];
    return uniqueVariables
  }

  // On single document inject - on smaller screens
  const singleDocumentInjectClickHandler = (e, doc) => {
    e && e.preventDefault()
    if(doc) {
      setSingleDocInject(doc)
      setSingleDocTemplateInject(doc.template)
      setShowInjectModal(true)
    }
  }

  // On close inject modal
  const closeInjectModal = () => {
    setShowInjectModal(false)
    setSingleDocInject(null)
    setSingleDocTemplateInject(null)
  }

  return(
    <div className="dashboard-inner">
      <div className="dashboard-inner__left" ref={dashboardSidebar}>
        <div className="dashboard-inner__left_overlay" onClick={sidebarCloseHandler}></div>
        <div className="dashboard-inner__left_sidebar">
          <DocumentsSidebar 
            onFilter={filterHandler} 
            onGetAll={getAllHandler} 
            onFilterByStatus={filterByStatusHandler} 
            months={monthsArr} 
            onFilterByDate={filterByDateHandler} 
            restored={restored}
            onSetRestored={setRestored}
            selected={selected}
            onSetSelected={setSelected}
            onSetCurrentFilter={setCurrentFilter}
            documents={documents}
            folders={docFolders}
            onFolderCreate={folderCreateClickHandler}
            onFolderEdit={folderEditClickHandler}
            onFolderDelete={folderDeleteClickHandler}
            onMoveToFolder={moveFolderHandler}
            foldersLoading={docFoldersLoading}
            onFolderFilter={filterByFoldersHandler}
            selectedFilter={docFoldersSelectedFilter}
            onMoveDocumentToFolder={moveDocumentToFolderHandler}
            onOpenFolderModal={() => setShowFolderModal(true)}
          />
        </div>
      </div>
      <div className="dashboard-inner__right">
        <DocumentsView 
          onSidebarOpen={sidebarOpenHandler} 
          viewTitle={title} 
          selected={selectedDocuments} 
          onSetSelected={setSelectedDocuments} 
          onDelete={() => setShowDeleteDocAlert(true)}
          onSearch={searchHandler}
          searchValue={search}
          onSetSearch={setSearch}
          onRestore={documentsRestoreHandler}
          onArchive={archiveSelectedDocuments}
          removeSearchClose={removeSearchClose}
          onSetRemoveSearchClose={setRemoveSearchClose}
          onSearchFormClear={searchFormClearHandler}
          onDownload={downloadSelectedDocuments}
          onSingleDocumentDuplicate={singleDocumentDuplicate}
          onSingleDocumentDelete={singleDocumentDeleteClickHandler}
          onSingleDocumentArchive={singleDocumentArchive}
          onSingleDocumentDownload={singleDocumentDownload}
          onSingleDocumentDownloadData={singleDocumentDownloadData}
          onSingleDocumentRestore={singleDocumentRestore}
          setViewTitle={setTitle}
          onFolderCreate={folderCreateClickHandler}
          onFolderEdit={folderEditClickHandler}
          onFolderDelete={folderDeleteClickHandler}
          onFolderMove={folderMoveClickHandler}
          onFilterByFolders={filterByFoldersHandler}
          onMoveSelectedDocuments={moveSelectedDocumentsClickHandler}
          folders={docFolders}
          onFolderClick={filterByFoldersHandler}
          onMoveDocumentToFolder={moveDocumentToFolderHandler}
          filteredFolders={filteredFolders}
          searching={searching}
          onSetSearching={setSearching}
          onDeletePermanently={deletePermanentlyHandler}
          onInjectModalOpen={() => setShowInjectModal(true)}
          onSingleDocumentInject={singleDocumentInjectClickHandler}
        />
      </div>

      {showDeleteDocAlert && <Alert 
        onClose={() => setShowDeleteDocAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer le(s) document(s) sélectionné(s) ?"
        onSubmit={documentsDeleteHandler}
        loading={deleting}
        deleteAlert
      />}
      {showDeleteSingleDocAlert && <Alert 
        onClose={() => setShowDeleteSingleDocAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer ce document ?"
        onSubmit={singleDocumentDelete}
        loading={deleting}
        deleteAlert
      />}
      {showFolderModal && <CreateEditFolderModal 
        onClose={() => setShowFolderModal(false)} 
        onCreate={createFolderHandler}
        onEdit={updateFolderHandler}
        foldersLoading={docFoldersLoading}
        currentFolder={currentFolder}
        mode={mode}
        view="documents"
      />}
      {showDeleteFolderAlert && <Alert 
        onClose={() => setShowDeleteFolderAlert(false)}
        text="Etes-vous certain de vouloir supprimer ce dossier?"
        onSubmit={deleteFolderHandler}
        loading={docFoldersLoading}
        deleteAlert
      />}
      {showMoveToFolderModal && <MoveToFolderModal 
        onClose={() => setShowMoveToFolderModal(false)} 
        folders={docFolders}
        currentFolder={documentFolderToMove}
        onMove={moveFolder}
        onCreateNewFolder={folderCreateClickHandler}
        newFolderCreated={folderCreated}
        onNewFolderCreated={setFolderCreated}
        loading={loading}
      />}
      {showMoveMultipleDocsToFolderModal && <MoveMultipleDocsToFolderModal 
        onClose={() => setShowMoveMultipleDocsToFolderModal(false)} 
        folders={docFolders}
        currentFolder={docFoldersSelectedFilter === 'all' ? null : [...docFolders].find(f => f.id === docFoldersSelectedFilter)}
        onMove={moveSelectedDocumentsHandler}
        onCreateNewFolder={folderCreateClickHandler}
        newFolderCreated={folderCreated}
        onNewFolderCreated={setFolderCreated}
        selectedDocuments={selectedDocuments}
        loading={loading}
      />}
      {showInjectModal && <InjectModal 
        onClose={closeInjectModal}
        doc={singleDocInject ? singleDocInject : selectedDocuments[0]}
        template={singleDocTemplateInject ? singleDocTemplateInject : getTemplateById(selectedDocuments[0]?.template)}
        refresh={false}
        allVariables={allVariables}
      />}
    </div>
  );
}

export default Documents;