import { useState, Fragment, useEffect } from 'react'
import moment from 'moment'

import { Modal, Input, DateInput, Textarea } from '../ui_new'
import { dateValueFormat, areSectionConditionsMet } from '../../utils'

const SingleDocumentSynthesis = ({ onClose, data, documentValues, onSetDocumentValues }) => {
  const [activeFilter, setActiveFilter] = useState('all')
  const [variableSections, setVariableSections] = useState([])
  const [filteredSections, setFilteredSections] = useState([])
  const [sectionsCreated, setSectionsCreated] = useState(false)

  useEffect(() => {
    if (!data) {
      return
    }

    if (!sectionsCreated) {
      let docValues = { ...documentValues }
      const sections = [...data.sections]
      const sectionsHeadings = sections.filter(s => s.type.startsWith('heading'))
      const sectionsUpdated = []
      if (sectionsHeadings.length === 0) {
        sectionsUpdated.push(sections)
      } else if (sections.indexOf(sectionsHeadings[0]) > 0) {
        sectionsUpdated.push(sections.slice(0, sections.indexOf(sectionsHeadings[0])))
      }
      sectionsHeadings.forEach((s, i, allArr) => {
        const sectionIndex = sections.indexOf(s)
        const arr = sections.indexOf(allArr[i + 1]) !== -1 ? sections.slice(sectionIndex, sections.indexOf(allArr[i + 1])) : sections.slice(sectionIndex)
        arr[0].sectionIndex = `section-${sectionIndex}`
        sectionsUpdated.push(arr)
      });
      const sectionsArr = []

      sectionsUpdated.forEach((s) => {
        const vars = []
        let obj = { title: '', vars: [], content: s.content }
        if (s.repeatable_section_id) {
          obj.repeatable_section_id = s.repeatable_section_id
        }

        let idx = 0
        for (let secField in s) {
          const f = s[secField]
          if (idx === 0 && f.type.startsWith('heading')) {
            const fields = f.content.match(/\{d\.([^}]*)\}/g)
            const title = fields ? fields.length > 0 ? f.content.replace(fields[0], '') : f.content : f.content
            obj.title = title
            obj.index = f.sectionIndex
          }
          if (f.variables || f.variable) {
            if (f.variable) {
              if (!f.variable.includes('cover_page')) {
                if (f.repeatable_section_id) {
                  if (documentValues[f.repeatable_section_id]) {
                    documentValues[f.repeatable_section_id].forEach((_, index) => {
                      let splittedVar = f.variable.split('_')
                      let lastEl = splittedVar[splittedVar.length - 1]
                      if (lastEl !== 'id') {
                        if (f.conditions) {
                          obj.vars.push({ ...f, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: index })
                        } else if (f.condition) {
                          obj.vars.push({ ...f, conditions: [f.condition], belongs_to: f.condition.variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: index })
                        } else {
                          obj.vars.push({ ...f, repeatable_section_id: f.repeatable_section_id, repeatable_index: index })
                        }
                        vars.push(f.variable)
                      }
                    })
                  } else {
                    docValues[f.repeatable_section_id] = [{}]
                    let splittedVar = f.variable.split('_')
                    let lastEl = splittedVar[splittedVar.length - 1]
                    if (lastEl !== 'id') {
                      if (f.conditions) {
                        obj.vars.push({ ...f, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0 })
                      } else if (f.condition) {
                        obj.vars.push({ ...f, conditions: [f.condition], belongs_to: f.condition.variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0 })
                      } else {
                        obj.vars.push({ ...f, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0 });
                      }
                      vars.push(f.variable)
                    }
                  }
                } else {
                  if (f.conditions) {
                    obj.vars.push({ ...f, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable })
                  } else if (f.condition) {
                    obj.vars.push({ ...f, conditions: [f.condition], belongs_to: f.condition.variable })
                  } else {
                    obj.vars.push(f)
                  }
                  if (!vars.includes(f.variable)) {
                    vars.push(f.variable)
                  }
                }
              }
            } else {
              if (f.repeatable_section_id) {
                if (documentValues[f.repeatable_section_id]) {
                  documentValues[f.repeatable_section_id].forEach((_, index) => {
                    f.variables.forEach((v) => {
                      let isCoverPageVar = false
                      if (v.variable && v.variable.includes('cover_page')) isCoverPageVar = true
                      if (!isCoverPageVar) {
                        let splittedVar = v.variable.split('_')
                        let lastEl = splittedVar[splittedVar.length - 1]
                        if (lastEl !== 'id') {
                          vars.push(v.variable)
                          if (obj.vars.indexOf(f) === -1) {
                            if (f.conditions) {
                              obj.vars.push({ ...v, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: index })
                            } else if (f.condition) {
                              obj.vars.push({ ...v, conditions: [f.condition], belongs_to: f.condition.variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: index })
                            } else {
                              obj.vars.push({ ...v, repeatable_section_id: f.repeatable_section_id, repeatable_index: index })
                            }
                          }
                        }
                      }
                    });
                  })
                } else {
                  docValues[f.repeatable_section_id] = [{}]
                  f.variables.forEach((v) => {
                    let isCoverPageVar = false
                    if (v.variable && v.variable.includes('cover_page')) isCoverPageVar = true
                    if (!isCoverPageVar) {
                      let splittedVar = v.variable.split('_')
                      let lastEl = splittedVar[splittedVar.length - 1]
                      if (lastEl !== 'id') {
                        vars.push(v.variable)
                        docValues[f.repeatable_section_id][0] = { ...docValues[f.repeatable_section_id][0], [v.variable]: '' }
                        if (obj.vars.indexOf(f) === -1) {
                          if (f.conditions) {
                            obj.vars.push({ ...v, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0 })
                          } else if (f.condition) {
                            obj.vars.push({ ...v, conditions: [f.condition], belongs_to: f.condition.variable, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0 })
                          } else {
                            obj.vars.push({ ...v, repeatable_section_id: f.repeatable_section_id, repeatable_index: 0 })
                          }
                        }
                      }
                    }
                  });
                }
              } else {
                f.variables.forEach((v) => {
                  let isCoverPageVar = false
                  if (v.variable && v.variable.includes('cover_page')) isCoverPageVar = true
                  if (!isCoverPageVar) {
                    if (!vars.includes(v.variable)) {
                      vars.push(v.variable)
                    }
                    if (obj.vars.indexOf(f) === -1) {
                      if (f.conditions) {
                        obj.vars.push({ ...v, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable })
                      } else if (f.condition) {
                        obj.vars.push({ ...v, conditions: [f.condition], belongs_to: f.condition.variable })
                      } else {
                        obj.vars.push(v)
                      }
                    }
                  }
                });
              }
            }
          }
          idx++
        }

        if (obj.vars.length > 0) {
          sectionsArr.push(obj)
        }
      })
      setVariableSections(sectionsArr)
      setFilteredSections(sectionsArr)
      setSectionsCreated(true)
    }
    // eslint-disable-next-line
  }, [data, documentValues])

  // Handle submit
  const handleSubmit = (e) => {
    e.preventDefault()
    onClose()
  }

  // On value change
  const handleValueChange = (handle, value, repeatableSectionId, repeatableIndex) => {
    let dv = { ...documentValues }
    if (repeatableSectionId) {
      dv[repeatableSectionId][repeatableIndex][handle] = value
    } else {
      dv[handle] = value
    }
    onSetDocumentValues(dv)
  }

  // Render text input
  const renderTextInput = (variable, repeatableSectionId, repeatableIndex, type = "text", label, locked) => {
    let disabled = locked
    if (repeatableSectionId) {
      const splittedVar = variable.split('_')
      if (splittedVar && splittedVar[splittedVar.length - 1] === 'id') {
        disabled = true
      }
    }
    let value = repeatableSectionId ? documentValues[repeatableSectionId][repeatableIndex][variable] : documentValues[variable]
    return (
      <div className="input-wrapper">
        <Input small value={value || ''} type={type} editMode onChange={(e) => handleValueChange(variable, e.target.value, repeatableSectionId, repeatableIndex)} className={value ? 'not-empty' : ''} label={label} disabled={disabled} />
      </div>
    )
  }

  // Render textarea
  const renderTextArea = (variable, label, repeatableSectionId, repeatableIndex, locked) => {
    let value = repeatableSectionId ? documentValues[repeatableSectionId][repeatableIndex][variable] : documentValues[variable]
    return (
      <div className="textarea-wrapper">
        <Textarea
          label={label}
          disabled={locked}
          value={value || ''}
          onChange={(e) => handleValueChange(variable, e.target.value, repeatableSectionId, repeatableIndex)}
          className={!!value ? 'not-empty' : ''}
        />
      </div>
    )
  }

  // Render select
  const renderSelectInput = (variable, options, label, repeatableSectionId, repeatableIndex, locked) => {
    let value = repeatableSectionId ? documentValues[repeatableSectionId][repeatableIndex][variable] : documentValues[variable]
    return (
      <div className="input-wrapper">
        <div className={value ? "select_input_wrapper not-empty" : "select_input_wrapper"}>
          <p className="label">{label}</p>
          <select disabled={locked} name="status" id="status" value={value} onChange={e => handleValueChange(variable, e.target.value, repeatableSectionId, repeatableIndex)}>
            <option disabled selected value> Sélectionnez une option </option>
            {options.map((option, optionIndex) => {
              return (
                <option disabled={locked} key={`option_${optionIndex}`} value={option.value}>{option.label}</option>
              )
            })}
          </select>
        </div>
      </div>
    )
  }

  // Render date input
  const renderDateInput = (variable, label, repeatableSectionId, repeatableIndex, locked) => {
    let value = repeatableSectionId ? documentValues[repeatableSectionId][repeatableIndex][variable] : documentValues[variable]
    return (
      <div className="input-wrapper">
        <DateInput
          label={label}
          disabled={locked}
          selected={!value ? '' : new Date(moment(value, dateValueFormat).valueOf())}
          onChange={date => date ? handleValueChange(variable, moment(date.valueOf()).format(dateValueFormat), repeatableSectionId, repeatableIndex) : ''}
          dateFormat="EEE dd/MM/yyyy"
          showMonthDropdown={true}
          showYearDropdown={true}
          dropdownMode="select"
          isClearable={value}
          onClearableClick={() => handleValueChange(variable, '', repeatableSectionId, repeatableIndex)}
          onChangeRaw={e => e.preventDefault()}
          className={value ? 'not-empty' : ''}
        />
      </div>
    )
  }

  // On filters click
  const handleFilterItems = (e, value) => {
    e.preventDefault()
    setActiveFilter(value)
    let filteredSectionArr = []
    let varSectionsCopy = [...variableSections]
    if (value === 'all') {
      filteredSectionArr = varSectionsCopy
    } else if (value === 'completed') {
      varSectionsCopy.forEach((s) => {
        let vars = []
        let section = { ...s }
        section.vars.forEach((v) => {
          if (v.repeatable_section_id && documentValues[v.repeatable_section_id]) {
            if (documentValues[v.repeatable_section_id][v.repeatable_index][v.variable]) {
              vars.push(v)
            }
          } else {
            if (documentValues[v.variable]) {
              vars.push(v)
            }
          }
        });
        if (vars.length > 0) {
          section.vars = vars
          filteredSectionArr.push(section)
        }
      });
    } else if (value === 'empty') {
      varSectionsCopy.forEach((s) => {
        let vars = []
        let section = { ...s }
        section.vars.forEach((v) => {
          if (v.type !== 'question') {
            if (v.repeatable_section_id && documentValues[v.repeatable_section_id]) {
              if (!documentValues[v.repeatable_section_id][v.repeatable_index][v.variable]) {
                vars.push(v)
              }
            } else {
              if (!documentValues[v.variable]) {
                vars.push(v)
              }
            }
          } else if (v.type === 'question') {
            if (documentValues[v.variable]) {
              vars.push({ ...v, hide: true })
            } else {
              vars.push(v)
            }
          }
        })
        if (vars.length > 0) {
          section.vars = vars
          filteredSectionArr.push(section)
        }
      })
    }
    setFilteredSections(filteredSectionArr)
  }

  // Render elements
  const renderInput = (v, arr, repeatableSectionId, repeatableIndex) => {
    let input
    if (v.type === 'string' && areSectionConditionsMet(v, documentValues)) {
      input = renderTextInput(v.variable, v.repeatable_section_id, v.repeatable_index, 'text', v.tooltip, v.locked)
    } else if (v.type === 'number' && areSectionConditionsMet(v, documentValues)) {
      input = renderTextInput(v.variable, v.repeatable_section_id, v.repeatable_index, 'number', v.tooltip, v.locked) // change to number
    } else if (v.type === 'textarea' && areSectionConditionsMet(v, documentValues)) {
      input = renderTextArea(v.variable, v.tooltip, v.repeatable_section_id, v.repeatable_index, v.locked)
    } else if (v.type === 'select' && areSectionConditionsMet(v, documentValues)) {
      input = renderSelectInput(v.variable, v.options, v.tooltip, v.repeatable_section_id, v.repeatable_index, v.locked)
    } else if (v.type === 'date' && areSectionConditionsMet(v, documentValues)) {
      input = renderDateInput(v.variable, v.tooltip, v.repeatable_section_id, v.repeatable_index, v.locked)
    } else if (v.type === 'question' && documentValues[v.variable] && v.options && areSectionConditionsMet(v, documentValues)) {
      const answer = v.options.find(a => a.value === documentValues[v.variable])
      const children = arr.filter(item => item.belongs_to === v.variable)

      let childInput = []
      children.forEach(child => {
        if (child) {
          childInput.push(renderInput(child, arr, v.repeatable_section_id, v.repeatable_index))
        }
      });
      input = <div className="question-container">
        <div className={v.hide ? "question-wrapper hide" : "question-wrapper"}>
          <p>{v.question} <span>{answer && answer.label}</span></p>
        </div>
        {childInput.length > 0 &&
          <div className="inputs-wrapper">
            {childInput.map((ch, index) => ch && <Fragment key={index}>{ch}</Fragment>)}
          </div>
        }
      </div>
    }
    return input
  }

  return (
    <Modal onClose={onClose} className="modal-v2--no-padding">
      <div className="synthesis-modal-v2">
        <div className='folder-modal-v2__heading'>
          <div className='signature-heading'>
            <div>
              <h1>Synthèse </h1>
              <h2>{data?.name || ''}</h2>
            </div>
          </div>
          <div className="synthesis-form__actions buttons-row">
            <button className="btn btn--transparent" onClick={onClose}>Annuler</button>
            <button
              className='btn-separator'
            >
            </button>
            <button className="btn" onClick={handleSubmit}>Sauvegarder</button>
          </div>
        </div>
        <div className='folder-modal-v2__content'>
          <div className="synthesis-modal-v2__body">
            <div className="synthesis-v2">
              <div className="synthesis-v2__filters flex w-full">
                <div className={`w-1/3 ${activeFilter === 'all' ? 'active' : ''}`} onClick={(e) => handleFilterItems(e, 'all')}>
                  Tous les champs
                </div>
                <div className={`w-1/3 ${activeFilter === 'empty' ? 'active' : ''}`} onClick={(e) => handleFilterItems(e, 'empty')}>
                  Champs vides
                </div>
                <div className={`w-1/3 ${activeFilter === 'completed' ? 'active' : ''}`} onClick={(e) => handleFilterItems(e, 'completed')}>
                  Champs saisis
                </div>
              </div>
              <form className="synthesis-form" onSubmit={handleSubmit}>
                {filteredSections.map((section, idx) => {
                  return (
                    <div className="synthesis-v2__section" key={idx}>
                      {section.title && <div className="heading"><span className="text">{section.title}</span></div>}
                      <div className="synthesis-v2__section_fields">
                        <div className="inputs-wrapper">
                          {section.vars.map((v, idx, arr) => {
                            if (v.belongs_to) return null
                            if (section.repeatable_section_id) {
                              return documentValues[section.repeatable_section_id] && documentValues[section.repeatable_section_id].map((repetition, rep_i) => {
                                return renderInput(v, arr, section.repeatable_section_id, rep_i)
                              })
                            } else {
                              return renderInput(v, arr)
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SingleDocumentSynthesis